import { BASE_URL, HIS_KEY, BASE_NEW_URL, WEBSOCKET_URL } from './config'
import axios, { AxiosError, AxiosInstance, CancelTokenSource } from 'axios'
import { IPageParams } from './stores/root'

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api/`,
  timeout: 30000,
})

const axiosInstanceForNewHisApi = axios.create({
  baseURL: `${BASE_NEW_URL}/api/`,
  timeout: 30000,
})


const axiosInstanceForWs = axios.create({
  baseURL: WEBSOCKET_URL,
  timeout: 30000,
})

const axiosInstanceForWechaty = axios.create({
  baseURL: `http://192.168.1.144:4000/`,
  timeout: 30000,
})


const axiosInstanceDict = {
  hotel: axiosInstance,
  hotelNewHisApi: axiosInstanceForNewHisApi,
  ws: axiosInstanceForWs,
  wechaty: axiosInstanceForWechaty,
}

export type AxiosKey = keyof typeof axiosInstanceDict

export const getAxios = (ak: AxiosKey = 'hotelNewHisApi') => {
  return axiosInstanceDict[ak]
}

export const page = <T>(
  { url, body }: IPageParams,
  cancelTokenSource: CancelTokenSource,
  ak: AxiosKey = 'hotelNewHisApi',
): Promise<{ data: T[]; total: number }> => {
  const ax = getAxios(ak)
  return new Promise<{ data: T[]; total: number }>((resolve, reject) =>
    ax
      .post<{
        code: string
        message: string
        data: T[]
      }>(`${url}?his_key=${HIS_KEY}`, body, {
        cancelToken: cancelTokenSource.token,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth') || '{}').token || ''}`,
        },
      })
      .then(res => {
        const { code, data, message } = res.data
        if (code == '1') {
          resolve({ data, total: JSON.parse(res.headers['x-pagination'])?.TotalCount || 0 })
        } else {
          reject(message)
        }
      })
      .catch(err => {
        reject(err.message || JSON.stringify(err))
      }),
  )
}

export const getToken = () => `Bearer ${JSON.parse(localStorage.getItem('auth') || '{}').token || ''}`

export const post = <T>(url: string, body: Record<string, any>, ak: AxiosKey = 'hotelNewHisApi'): Promise<T | null> => {
  const ax = getAxios(ak)
  return new Promise((resolve, reject) => {
    ax.post<{
      code: string
      message?: string
      data: T | null
      msg?: string
    }>(`${url}?his_key=${HIS_KEY}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
    })
      .then(res => {
        const { code, data, message, msg } = res.data
        if (code == '1') {
          resolve(data)
        } else {
          reject(message || msg)
        }
      })
      .catch(err => {
        reject(err.message || JSON.stringify(err))
      })
  })
}
export const get = <T>(url: string, params?: Record<string, any>): Promise<T | null> => {
  const ax = getAxios('hotelNewHisApi')
  return new Promise((resolve, reject) => {
    ax.get<{
      code: string
      message?: string
      data: T | null
      msg?: string
    }>(`${url}?his_key=${HIS_KEY}`, {
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
    })
      .then(res => {
        const { code, data, message } = res.data
        if (code == '1') {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(err => {
        reject(err.message || JSON.stringify(err))
      })
  })
}
export const addInterceptors = (ai: AxiosInstance) => {
  ai.interceptors.response.use(
    function r(response) {
      return response
    },
    function e(error: AxiosError) {
      const err: AxiosError = error
      if (err && err.response) {
        switch (err.response.status) {
          case 400:
            err.message = '请求错误(400)'
            break
          case 401:
            err.message = '未授权，请重新登录(401)'
            break
          case 403:
            err.message = '拒绝访问(403)'
            break
          case 404:
            err.message = '请求出错(404)'
            break
          case 408:
            err.message = '请求超时(408)'
            break
          case 500:
            err.message = '服务器错误(500)'
            break
          case 501:
            err.message = '服务未实现(501)'
            break
          case 502:
            err.message = '网络错误(502)'
            break
          case 503:
            err.message = '服务不可用(503)'
            break
          case 504:
            err.message = '网络超时(504)'
            break
          case 505:
            err.message = 'HTTP版本不受支持(505)'
            break
          default:
            err.message = `连接出错(${err.response.status})!`
        }
      } else {
        err.message = '连接服务器失败!'
      }
      return Promise.reject(err)
    },
  )
}

Object.entries(axiosInstanceDict).forEach(([_, ai]) => addInterceptors(ai))
