import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Header, Divider, Form, Radio, Button, Input, Message, Icon } from 'semantic-ui-react'

import MyTabs from '../../../components/MyTabs'
import MultiSteps from '../../../components/MultiSteps'
import MenuList from '../../../components/staff/MenuList'
import { IRawMenu } from '../../../utils/menu'
import { post } from '../../../axios'
import { observer } from 'mobx-react-lite'
import { RootStoreContext, rootStore } from '../../../stores/root'
import ModalTrigger from '../../../components/ModalTrigger'
import RoleDelModal from '../../../components/staff/RoleDelModal'
import { IPermission } from '../../../constant/interface'
import { colors } from '../../../constant/CSS'

export interface IRole {
  id: number
  name: string
  createTime: string
  updateTime: string
}

interface IProps {
  groupId: string
}

const MerchantRoles: React.FC<IProps> = observer(({ groupId }) => {
  const [showUsersByRole, setShowUsersByRole] = useState<boolean>(false)
  const [currentRole, setCurrentRole] = useState<number>(-1)
  const [currentMenu, setCurrentMenu] = useState<number[]>([])
  const [currentPerm, setCurrentPerm] = useState<number[]>([])
  const [updating, setUpdating] = useState<boolean>(false)
  const [roles, setRoles] = useState<IRole[]>([])
  const [menu, setMenu] = useState<IRawMenu[]>([])
  const [menuLoading, setMenuLoading] = useState<boolean>(false)
  const [rolesLoading, setRolesLoading] = useState<boolean>(false)
  const [menuError, setMenuError] = useState<string>('')
  const [rolesError, setRolesError] = useState<string>('')

  const [allRoles, updateAllRoles] = useState<
    {
      id: number
      name: string
    }[]
  >([])
  const [newRoleName, setNewRoleName] = useState<string>('')
  const rootStore = useContext(RootStoreContext)

  useEffect(() => {
    setRolesLoading(true)
    post<IRole[]>(`his/Role/querySupplierList`, { groupId })
      .then(res => setRoles(res || []))
      .catch(err => setRolesError(err))
      .finally(() => setRolesLoading(false))

    setMenuLoading(true)
    post<IRawMenu[]>(`his/Menu/query`, { groupId })
      .then(res => setMenu(res || []))
      .catch(err => setMenuError(err))
      .finally(() => setMenuLoading(false))
  }, [groupId])

  const selectRole = useCallback(
    async (id: number) => {
      try {
        if (id !== 0) {
          const cm = await post<IRawMenu[]>('his/Menu/queryRoleId', { id, groupId })
          const cp = await post<IPermission[]>('his/Permissions/queryRoleId', { id })

          if (cm) setCurrentMenu(cm.map(m => m.menuId))
          if (cp) setCurrentPerm(cp.map(p => p.id))
        } else {
          setCurrentMenu([])
          setCurrentPerm([])
        }
        setCurrentRole(id)
      } catch (err) {
        rootStore.notificationStore.push({
          id: 'fetch-menu-permissions-fail',
          color: 'red',
          header: '读取该角色的菜单和权限失败',
          content: err.message || JSON.stringify(err),
          expire: 30,
        })
      }
    },
    [setCurrentRole, setCurrentMenu, setCurrentPerm],
  )

  const updateRole = useCallback((roleId: number, permissionList: number[], menuList: number[]) => {
    setUpdating(true)
    post<{ status: string }>('his/Role/updateRoleInfo', {
      roleId,
      permissionList,
      menuList,
      groupId,
    })
      .then(() => {
        rootStore.notificationStore.push({
          id: `update-role-success-${roleId}`,
          color: 'green',
          header: '修改角色成功',
          content: '修改角色成功',
          expire: 30,
        })
      })
      .catch(err => {
        rootStore.notificationStore.push({
          id: `update-role-fail-${roleId}`,
          color: 'red',
          header: '修改角色失败',
          content: err.messsage || err,
          expire: 30,
        })
      })
      .finally(() => setUpdating(false))
  }, [])

  const createRole = useCallback(
    (roleName: string, permissionList: number[], menuList: number[]) => {
      setUpdating(true)
      post<{ roleId: number }>('his/Role/insertRoleInfo', {
        roleName,
        permissionList,
        menuList,
        groupId,
      })
        .then(res => {
          post<IRole[]>(`his/Role/querySupplierList`, { groupId })
            .then(res => setRoles(res || []))
            .catch(err => setRolesError(err))
            .finally(() => setRolesLoading(false))
          if (roles) updateAllRoles([...roles, { id: 0, name: '新建角色' }])
          rootStore.notificationStore.push({
            id: `create-role-success`,
            color: 'green',
            header: '新建角色成功',
            content: `[${roleName}]创建成功`,
            expire: 30,
          })
        })
        .catch(err => {
          rootStore.notificationStore.push({
            id: `create-role-fail`,
            color: 'red',
            header: '新建角色失败',
            content: err.messsage || err,
            expire: 30,
          })
        })
        .finally(() => setUpdating(false))
    },
    [updateAllRoles],
  )

  useEffect(() => {
    if (roles) updateAllRoles([...roles, { id: 0, name: '新建角色' }])
  }, [roles])

  if (rolesLoading || menuLoading) return <span style={{ color: colors.grey }}>加载中...</span>
  if (rolesError || menuError)
    return (
      <Message negative>
        <Message.Header>获取角色、菜单、权限列表失败</Message.Header>

        {rolesError && <p>{rolesError}</p>}
        {menuError && <p>{menuError}</p>}
      </Message>
    )

  return (
    <>
      <Form>
        <Form.Group>
          {allRoles.map(role => (
            <Form.Field key={role.id}>
              <Radio
                label={
                  role.id === 0 ? { children: <strong style={{ color: colors.red }}>新建角色</strong> } : role.name
                }
                name="roleName"
                value={role.id}
                onChange={(_e, d) => {
                  selectRole(d.value as number)
                }}
                checked={role.id === currentRole}
              />
            </Form.Field>
          ))}
        </Form.Group>
      </Form>

      {currentRole === 0 ? (
        <MultiSteps
          onDone={() => createRole(newRoleName, currentPerm, currentMenu)}
          canBack={true}
          config={[
            {
              icon: 'wpforms',
              title: '信息录入',
              component: (
                <Input
                  label="新建角色名称"
                  placeholder="请输入角色名称"
                  value={newRoleName}
                  onChange={(_, d) => setNewRoleName(d.value)}
                  fluid
                />
              ),
              canNext: !!newRoleName,
            },
            {
              icon: 'list alternate outline',
              title: '菜单选择',
              component: (
                <MenuList menu={menu || []} currentMenu={currentMenu} updateMenu={newMenu => setCurrentMenu(newMenu)} />
              ),
              canNext: !!newRoleName && currentMenu.length > 0,
            },
          ]}
          doneBtnText="新建角色"
        />
      ) : (
        <MyTabs tabs={['页面菜单']}>
          <MenuList menu={menu || []} currentMenu={currentMenu} updateMenu={newMenu => setCurrentMenu(newMenu)} />
        </MyTabs>
      )}

      {currentRole !== 0 && (
        <>
          <Divider section />

          <Button
            color="blue"
            floated="right"
            onClick={() => updateRole(currentRole, currentPerm, currentMenu)}
            disabled={currentMenu.length === 0 || updating}
            loading={updating}
          >
            修改角色
          </Button>
          <Button
            secondary
            floated="right"
            onClick={() => {
              setCurrentMenu([])
              setCurrentPerm([])
            }}
            disabled={currentMenu.length === 0 || updating}
          >
            重 设
          </Button>
          <Button
            color="red"
            floated="right"
            disabled={currentRole === -1 || updating}
            onClick={() => setShowUsersByRole(true)}
          >
            删除角色
          </Button>
        </>
      )}

      <ModalTrigger
        header={`拥有该角色的用户列表`}
        size="small"
        content={<RoleDelModal roleId={currentRole} />}
        open={showUsersByRole}
        actions={
          <>
            <Button onClick={() => setShowUsersByRole(false)}>取消</Button>
            <Button color="red">
              <Icon name="remove" /> 删除角色
            </Button>
          </>
        }
      />
    </>
  )
})

const MerchantRolesWrapper: React.FC<{}> = observer(() => {
  const [groupId, setGroupId] = useState<string | null>(null)
  const rootStore = useContext(RootStoreContext)

  return (
    <>
      <Header size="medium">权限分配</Header>
      <Divider />
      {rootStore.authStore.user?.groupId ? (
        <MerchantRoles groupId={rootStore.authStore.user?.groupId} />
      ) : (
        <>
        </>
      )}
    </>
  )
})

export default MerchantRolesWrapper
