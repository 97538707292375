import React, { useContext, useState } from 'react'
import { Menu, Button, Image, Dropdown, Label } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../stores/root'
import { useHistory, useLocation } from 'react-router-dom'
import { getRootPathname, goto } from '../../utils/menu'
import ModalTrigger from '../ModalTrigger'
import PasswordForm from '../forms/PasswordForm'
import { AVATAR_BASE_URL, HIS_KEY } from '../../config'
import styled from 'styled-components'
import { getShopColor, getShopName, ShopAll, ShopType } from '../../utils/shop'


const Point = styled.div<{ color?: string }>`
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background-color: ${props => props.color || '#db2828'};
  position: absolute;
  bottom: -0.2em;
  right: -0.2em;
  cursor: pointer;
`

interface IProps { }

const AdminHeader: React.FC<IProps> = observer(() => {
  const [pwd, setPwd] = useState<boolean>(false)
  const rootStore = useContext(RootStoreContext)
  const history = useHistory()
  const location = useLocation()


  return (
    <>
      <Menu size="small" style={{ marginTop: 0, zIndex: 100 }} fixed="top">
        {rootStore.authStore.menu
          .filter(m => m.id !== 30 && m.name) // TODO 过滤门票页面
          .map(m => (
            <Menu.Item
              as={'a'}
              active={getRootPathname(location.pathname) === m.url}
              onClick={() => {
                rootStore.authStore.activeMenu(m.id)
                history.push(goto(m))
              }}
              key={m.id}
            >
              {m.name}
            </Menu.Item>
          ))}
        <Menu.Item position="right">
          {ShopAll.filter((shop: ShopType) => rootStore.reminderOrderStore.count[shop]).map((shop: ShopType) => (
            <Label
              as="a"
              color={getShopColor(shop)}
              style={{ marginRight: '1em' }}
              key={shop}
              onClick={() => {
                history.push(
                  shop === 'qunar'
                    ? '/order/qunar/waiting'
                    : shop === 'swy'
                      ? '/order/swy/waiting'
                      : shop === 'wechat'
                        ? '/order/wechat/waiting'
                        : '/order/todo/orders2',
                )
                rootStore.reminderOrderStore.reset(shop)
              }}
            >
              <b>{getShopName(shop)}: </b>
              <Label.Detail>有催单</Label.Detail>
            </Label>
          ))}

          {ShopAll.filter((shop: ShopType) => rootStore.reminderOrderStore.count[shop]).map((shop: ShopType) => (
            <Label
              as="a"
              basic
              color={getShopColor(shop)}
              style={{ marginRight: '1em' }}
              key={shop}
              onClick={() => {
                history.push(
                  shop === 'qunar'
                    ? '/order/qunar/waiting'
                    : shop === 'swy'
                      ? '/order/swy/waiting'
                      : shop === 'wechat'
                        ? '/order/wechat/waiting'
                        : '/order/todo/orders2',
                )
              }}
            >
              <b>{getShopName(shop)}: </b>
              <Label.Detail>
                <b>{rootStore.newOrderStore.count[shop]}</b>
              </Label.Detail>
            </Label>
          ))}

          <div style={{ position: 'relative' }}>
            <Image
              src={
                rootStore.authStore.user?.headSculpture
                  ? `${AVATAR_BASE_URL}/${rootStore.authStore.user.headSculpture}?his_key=${HIS_KEY}`
                  : 'https://react.semantic-ui.com/images/wireframe/square-image.png'
              }
              size="mini"
              circular
              style={{ objectFit: 'cover', width: '2.5em', height: '2.5em' }}
            />
            <Point color={rootStore.wsStore.status ? '#00BA4E' : '#db2828'} />
          </div>
          <Dropdown
            text={`${rootStore.authStore.user?.lastName}${rootStore.authStore.user?.firstName}(${rootStore.authStore.user?.userName})`}
            style={{ marginLeft: '0.5em', marginRight: '0.5em', fontSize: '.9em', fontWeight: 'bold' }}
          >
            <Dropdown.Menu>
              <Dropdown.Item text="重置密码" onClick={() => setPwd(true)} />
            </Dropdown.Menu>
          </Dropdown>
          <Button
            style={{ marginLeft: '0.5em' }}
            color="red"
            onClick={() => {
              rootStore.authStore.logout()
              history.push('/merchant/login')
            }}
            compact
          >
            退出
          </Button>
        </Menu.Item>
      </Menu>
      <ModalTrigger
        header={`密码重置`}
        size="small"
        content={
          <PasswordForm
            onClose={() => setPwd(false)}
            onSuccess={message => {
              setTimeout(() => setPwd(false), 500)
              rootStore.notificationStore.push({
                id: 'update-pwd-success',
                color: 'green',
                header: '密码重置',
                content: message,
                expire: 30,
              })
            }}
          />
        }
        open={pwd}
      />
    </>
  )
})

export default AdminHeader
