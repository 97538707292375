import React, { useContext } from 'react'
import PushPortal from './PushPortal'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../stores/root'

interface IProps { }

const PortalWrapper: React.FC<IProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)

  const len = rootStore.pushStore.pushes.length
  const push = rootStore.pushStore.pushes.length > 0 ? rootStore.pushStore.pushes[len - 1] : undefined

  return push ? (
    <PushPortal
      title={`${push.title}(${push.metadata.store})`}
      content={
        null
      }
      onNegative={() => rootStore.pushStore.ignore()}
      hasMore={len > 1}
    />
  ) : null
})

export default PortalWrapper
