import React, { ReactElement, useState } from 'react'
import { Table, Icon, Pagination, Loader, Dimmer, Popup, Input, Dropdown, Checkbox, Message } from 'semantic-ui-react'
import { AlignType, CellWidth, RowType, TableSizeType, UIColor, VerticalAlignType } from '../constant/ALL'
import { PAGE_SIZE_OPTIONS } from '../constant/PAGE_SIZE'

export type SortSchemaType = { [key: string]: 'asc' | 'desc' }[]


interface ColType {
  name: string
  align?: AlignType
  sortable?: boolean
  width?: CellWidth
  maxLength?: number
  collapsing?: boolean
  singleLine?: boolean
  render?: (row: any) => ReactElement | null
}

interface IProps {
  headers: RowType[]
  columns: ColType[]
  data: any[]
  paged?: boolean
  page?: number
  pageSize?: number
  count?: number
  onPage?: (page: number, pageSize: number) => void
  onSort?: (schema: SortSchemaType) => void
  rowStatus?: (arg: any, index?: number) => object | undefined
  cellStatus?: (row: any, cell: any, index?: number) => object | undefined
  indexed?: boolean
  indexColName?: string
  rightBtnGroup?: (arg: any, index?: number) => ReactElement
  rightBtnGroupWidth?: CellWidth
  rightBottomComp?: ReactElement
  rightBottomCompSpan?: CellWidth
  innerInfo?: ReactElement
  loading?: boolean
  celled?: boolean
  collapsing?: boolean
  basic?: boolean | 'very'
  striped?: boolean
  verticalAlign?: VerticalAlignType
  color?: UIColor
  inverted?: boolean
  padded?: boolean
  compact?: boolean
  size?: TableSizeType
  checkbox?: boolean
  onCheckbox?: (args: number[]) => void
  onDefaultCheckbox?: (args: any) => boolean
  singleLine?: boolean
  selectable?: boolean
  info?: string | ReactElement
  infoColor?: 'info' | 'positive' | 'negative' | 'warning'
  onRowDoubleClick?: (event: MouseEvent, row: any) => void
}

const MyTable: React.FC<IProps> = ({
  headers,
  data,
  columns,
  paged = false,
  onPage,
  onSort,
  rowStatus,
  cellStatus,
  page = 0,
  pageSize = 10,
  count = 0,
  indexed = false,
  indexColName = '#',
  rightBtnGroup,
  rightBottomComp,
  loading = false,
  celled = false,
  collapsing = false,
  basic = true,
  striped = false,
  verticalAlign,
  color,
  inverted = false,
  padded = false,
  compact = false,
  size,
  rightBtnGroupWidth,
  rightBottomCompSpan,
  checkbox,
  onCheckbox,
  onDefaultCheckbox,
  singleLine = false,
  selectable = false,
  info,
  infoColor = 'info',
  onRowDoubleClick,
  innerInfo,
}) => {
  const [sortSchema, setSortSchema] = useState<SortSchemaType>([])
  const [goto, setGoto] = useState<number>(page)
  const [checkedList, setCheckedList] = useState<number[]>([])

  let footerSpan = headers.length
  let rightBottomSpan = 1
  if (indexed) footerSpan += 1
  if (!!rightBtnGroup) footerSpan += 1
  if (!!rightBottomComp) footerSpan -= 1
  if (rightBottomCompSpan) {
    footerSpan -= rightBottomCompSpan - 1
    rightBottomSpan = rightBottomCompSpan
  }

  const sortable = columns.filter(c => c.sortable).length > 0

  const handleSort = (colName: string) => {
    if (onSort) {
      const newSchema = [...sortSchema]
      const idx = newSchema.findIndex(s => Object.keys(s).includes(colName))
      if (idx > -1) {
        const curOrder = newSchema[idx][colName]
        if (curOrder === 'asc') {
          newSchema.splice(idx, 1, { [colName]: 'desc' })
        } else {
          newSchema.splice(idx, 1)
        }
      } else {
        newSchema.push({ [colName]: 'asc' })
      }
      setSortSchema(newSchema)
      onSort(newSchema)
    }
  }

  return (
    <Dimmer.Dimmable
      dimmed={loading}
    >
      <Dimmer active={loading} inverted style={{ zIndex: 99, position: 'fixed', bottom: '50vh' }}>
        <Loader size="medium">数据加载中...</Loader>
      </Dimmer>

      {info && (
        <Message
          attached="top"
          info={infoColor === 'info'}
          negative={infoColor === 'negative'}
          positive={infoColor === 'positive'}
          warning={infoColor === 'warning'}
        >
          <Icon name="warning circle" />
          {info}
        </Message>
      )}
      <Table
        attached={!!info}
        celled={celled}
        collapsing={collapsing}
        basic={basic}
        striped={striped}
        color={color}
        inverted={inverted}
        padded={padded}
        compact={compact}
        size={size}
        sortable={sortable}
        selectable={selectable}
      >
        <Table.Header>
          <Table.Row>
            {indexed ? (
              <Table.HeaderCell style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {checkbox && (
                  <>
                    <Checkbox
                      onChange={async (_, d) => {
                        let newBoxes: number[] = []
                        if (d.checked) {
                          newBoxes = data.map((_, i) => i)
                        }
                        setCheckedList(newBoxes)
                        if (onCheckbox) {
                          onCheckbox(newBoxes)
                        }
                      }}
                    />
                    &nbsp;&nbsp;
                  </>
                )}{' '}
                <span>{indexColName}</span>
              </Table.HeaderCell>
            ) : null}
            {headers.map((h, i) => {
              const ts = sortSchema.filter(s => !!s[columns[i].name])
              return (
                <Table.HeaderCell
                  key={i}
                  textAlign={h.align}
                  sorted={ts.length === 0 ? undefined : ts[0][columns[i].name] === 'asc' ? 'ascending' : 'descending'}
                  width={h.width}
                  onClick={() => {
                    if (columns[i].sortable) {
                      handleSort(columns[i].name)
                    }
                  }}
                >
                  {columns[i].sortable && <Icon name="sidebar" size="small" color="blue" />} {h.cmp || h.name}
                </Table.HeaderCell>
              )
            })}
            {!!rightBtnGroup ? (
              <Table.HeaderCell width={rightBtnGroupWidth} textAlign="right">
                #
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {innerInfo ? (
            <Table.Row>
              <Table.Cell colSpan={columns.length + (rightBtnGroup ? 1 : 0) + (indexed ? 1 : 0)} textAlign="center">
                {innerInfo}
              </Table.Cell>
            </Table.Row>
          ) : null}

          {data.map((row, i) => (
            <Table.Row
              key={i}
              verticalAlign={verticalAlign}
              {...((rowStatus && rowStatus(row, i)) || {})}
              onDoubleClick={(e: MouseEvent) => {
                if (onRowDoubleClick) onRowDoubleClick(e, row)
              }}
            >
              {indexed ? (
                <Table.Cell
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  singleLine={singleLine}
                >
                  {checkbox && (
                    <>
                      <Checkbox
                        checked={onDefaultCheckbox ? onDefaultCheckbox(row) : checkedList.includes(i)}
                        onChange={() => {
                          let newBoxes: number[] = [...checkedList, i]
                          if (checkedList.includes(i)) {
                            newBoxes = checkedList.filter(c => c !== i)
                          }
                          setCheckedList(newBoxes)
                          if (onCheckbox) {
                            onCheckbox(newBoxes)
                          }
                        }}
                      />
                      &nbsp;&nbsp;
                    </>
                  )}{' '}
                  <span>{i + 1}</span>
                </Table.Cell>
              ) : null}
              {columns.map((col, j) => {
                const cellContent = row[col.name] ?? ''
                const maxLen = headers[j].maxLength
                const moreFlag = maxLen && cellContent.length > maxLen
                return (
                  <Table.Cell
                    key={j}
                    textAlign={col.align}
                    {...((cellStatus && cellStatus(row, col, i)) || {})}
                    style={{
                      cursor: !!moreFlag ? 'pointer' : 'auto',
                    }}
                    collapsing={col.collapsing}
                    singleLine={singleLine || col.singleLine}
                    width={col.width}
                  >
                    {col.render ? (
                      col.render(row)
                    ) : moreFlag ? (
                      <Popup
                        on="click"
                        trigger={
                          <span>
                            {cellContent.substr(0, maxLen)}
                            {' ...   '}
                          </span>
                        }
                      >
                        {cellContent}
                      </Popup>
                    ) : (
                      cellContent
                    )}
                  </Table.Cell>
                )
              })}
              {rightBtnGroup ? <Table.Cell textAlign="right">{rightBtnGroup(row, i)}</Table.Cell> : null}
            </Table.Row>
          ))}
        </Table.Body>

        {paged && onPage ? (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={footerSpan}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                  <Pagination
                    activePage={page}
                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    totalPages={Math.ceil(count / pageSize)}
                    onPageChange={(_, d) => {
                      if (d && d.activePage) {
                        onPage(+d.activePage, pageSize)
                      }
                    }}
                    siblingRange={2}
                    size="small"
                  />

                  <Dropdown
                    selection
                    options={PAGE_SIZE_OPTIONS}
                    compact
                    size="mini"
                    defaultValue={pageSize}
                    style={{ marginLeft: '1em', width: 'auto', minWidth: '12em' }}
                    onChange={(_e, d) => onPage(1, d.value as number)}
                  />

                  <Input
                    label={{ basic: true, content: '页码' }}
                    labelPosition="left"
                    placeholder=""
                    action={{
                      color: 'green',
                      icon: 'arrow right',
                      onClick: () => onPage(goto, pageSize),
                    }}
                    size="small"
                    style={{ marginLeft: '1em', width: '5em' }}
                    type="number"
                    value={goto}
                    onChange={e => {
                      const r = parseInt(e.target.value)
                      if (Number.isNaN(r) || r < 1) {
                        setGoto(1)
                      } else {
                        setGoto(r)
                      }
                    }}
                  />
                </div>
              </Table.HeaderCell>

              {!!rightBottomComp ? (
                <Table.HeaderCell colSpan={rightBottomSpan}>{rightBottomComp}</Table.HeaderCell>
              ) : null}
            </Table.Row>
          </Table.Footer>
        ) : null}
      </Table>
    </Dimmer.Dimmable>
  )
}

export default MyTable
