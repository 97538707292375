import React, { useState } from 'react'
import { Form, Input, Button, Icon, Message } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { passwordSchema } from '../../validationSchemas/passwordSchema'
import { post } from '../../axios'

interface IProps {
  onClose: () => void
  onSuccess: (message: string) => void
}

const PasswordForm: React.FC<IProps> = ({ onClose, onSuccess }) => {
  const [showPwd1, setShowPwd1] = useState<boolean>(false)
  const [showPwd2, setShowPwd2] = useState<boolean>(false)
  const [showPwd3, setShowPwd3] = useState<boolean>(false)
  const [samePwd1, setSamePwd1] = useState<string | null>(null)
  const [samePwd2, setSamePwd2] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: values => {
      setError(null)
      post<any>('users/updatePassword', values)
        .then(() => {
          onSuccess('密码修改成功')
        })
        .catch(err => setError(err.message || err.toString()))
        .finally(() => formik.setSubmitting(false))
    },
    validationSchema: passwordSchema,
  })
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="原始密码"
          icon={<Icon name={showPwd1 ? 'eye slash' : 'eye'} onClick={() => setShowPwd1(prev => !prev)} link />}
          type={showPwd1 ? 'text' : 'password'}
          placeholder="请输入原始密码"
          required
          name="oldPassword"
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.oldPassword && formik.errors.oldPassword}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="重置密码"
          icon={<Icon name={showPwd2 ? 'eye slash' : 'eye'} onClick={() => setShowPwd2(prev => !prev)} link />}
          type={showPwd2 ? 'text' : 'password'}
          placeholder="请输入新密码"
          required
          name="newPassword"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={(e: any) => {
            formik.handleBlur(e)
            if (formik.values.newPassword === formik.values.oldPassword) {
              setSamePwd1('新密码不能和原始密码相同')
            } else {
              setSamePwd1(null)
            }
          }}
          error={(formik.touched.newPassword && formik.errors.newPassword) || samePwd1}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="确认密码"
          icon={<Icon name={showPwd3 ? 'eye slash' : 'eye'} onClick={() => setShowPwd3(prev => !prev)} link />}
          type={showPwd3 ? 'text' : 'password'}
          placeholder="请确认新密码"
          required
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={(e: any) => {
            formik.handleBlur(e)
            if (formik.values.newPassword !== formik.values.confirmPassword) {
              setSamePwd2('确认密码输入不一致')
            } else {
              setSamePwd2(null)
            }
          }}
          error={(formik.touched.confirmPassword && formik.errors.confirmPassword) || samePwd2}
        />
      </Form.Group>

      {error && (
        <Message negative>
          <strong>{error}</strong>
        </Message>
      )}

      <Button size="small" onClick={onClose} disabled={formik.isSubmitting}>
        取消
      </Button>
      <Button
        size="small"
        primary
        labelPosition="right"
        icon="checkmark"
        content="重置密码"
        type="submit"
        loading={formik.isSubmitting && !error}
        disabled={!formik.isValid || !formik.dirty}
      />
    </Form>
  )
}

export default PasswordForm
