import React from 'react'
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom'
export const getAuth = () => JSON.parse(localStorage.getItem('auth') || '{}')

interface IProps extends RouteProps { }

const PrivateRoute: React.FC<IProps> = ({ component, path, ...options }) => {
  const location = useLocation()
  return getAuth().token ? (
    getAuth().menuUrls.includes((path as string) || location.pathname || '') ? (
      <Route {...options} path={path} component={component} />
    ) : (
      <Redirect to="/401" />
    )
  ) : (
    <Redirect to="/login" />
  )
}

export default PrivateRoute
