import React from 'react'
import { Input, Button, Icon, Select } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import _ from 'lodash'
import { RootStoreContext } from '../../stores/root'
import { useContext } from 'react'
import { usePostCancel } from '../../utils/hooks'
//组件类
type MyInput = {
  type: string
  name: string
  label: string
  value?: any
}
type MySelectGroup = {
  type: string
  name: string
  label: string
  value?: any
}
type MySearch = {
  type: string
  label: string
}
type MyButton = {
  type: string
  label: string
  click: Function
}
type MyDateRange = {
  type: string
  startName?: string
  endName?: string
  label: string
  range: number
  value?: any
}
type MySelect = {
  type: string
  name: string
  label: string
  value?: any
  options: { name: string, value: any }[]
}

interface IProps {
  config: any[]                                           //配置列表
  onSearch: (args: Record<string, string>) => void        //查询
  onSelectGroup?: (args: string) => void  //组织组件选择组件时返回选中的组织
}

const SearchBarNew: React.FC<IProps> = ({ config: config, onSearch, onSelectGroup }) => {
  const rootStore = useContext(RootStoreContext)
  //只有在selectGroup组件是有用
  const mgroups = usePostCancel<{ id: string; name: string }[]>(`zq/Group/getGroup`, {})
  //初始化值
  let initialValues: any = {}
  config.map((item) => {
    if (item.type == "input" || item.type == "select" || item.type == "selectGroup") {
      initialValues[item.name] = item.value ? item.value : ''
    } else if (item.type == "dateRange") {
      if (Array.isArray(item?.value)) {
        const fd = item.value.map((d: string) => dayjs(d).format('YYYY-MM-DD'))
        if (fd.length > 1) {
          formik.setFieldValue('StartDate', fd[0])
          formik.setFieldValue('EndDate', fd[1])
        }
      } else {
        initialValues[item.startName] = ''
        initialValues[item.endName] = ''
      }
    }
  })
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      const arr = Object.entries(values)
      const r: { [key: string]: string } = {}
      arr.filter(ar => ar[1] !== '').forEach(([k, v]) => (r[k] = v as string))
      onSearch(r)
      if (r.hotelId) {
        rootStore.optimizeStore.update(r.hotelId)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      {config.map((item) => {
        if (item.type == "input") {
          let myItem = item as MyInput
          return (
            <Input
              key={myItem.name}
              size="mini"
              label={{ content: myItem.label, color: 'blue' }}
              icon={
                (formik.values as any)[myItem.name].trim() ? (
                  <Icon name="close" color="grey" link onClick={() => formik.setFieldValue(myItem.name, '')} />
                ) : null
              }
              labelPosition="left"
              placeholder={`请输入${myItem.label}`}
              style={{ marginRight: '.2em', marginBottom: '.2em' }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name={myItem.name}
              value={(formik.values as any)[myItem.name]}
              error={(formik.touched as any)[myItem.name] && (formik.errors as any)[myItem.name]}
            />
          )
        } else if (item.type == "select") {
          let myItem = item as MySelect
          return (
            <Select
              search
              key={myItem.name}
              onChange={(_, d) => formik.setFieldValue(myItem.name, d.value)}
              as={Input}
              style={{ marginRight: '.2em', marginBottom: '.2em' }}
              size="mini"
              placeholder={`--${myItem.label}--`}
              options={[
                {
                  key: 'no-select',
                  text: `--${myItem.label}--`,
                  value: '',
                },
                ...myItem.options.map((s: any) => ({
                  key: s.name,
                  text: s.name,
                  value: s.value,
                })),
              ]}
            />
          )
        } else if (item.type == "dateRange") {
          let myItem = item as MyDateRange
          return (
            <SemanticDatepicker
              size="mini"
              placeholder={`--${myItem.label}--`}
              locale={'zh-CN' as any}
              onChange={(e, data) => {
                if (Array.isArray(data?.value)) {
                  const fd = data.value.map(d => dayjs(d).format('YYYY-MM-DD'))
                  if (fd.length > 1) {
                    formik.setFieldValue(myItem.startName ? myItem.startName : "StartDate", fd[0])
                    formik.setFieldValue(myItem.endName ? myItem.endName : "EndDate", fd[1])
                  }
                } else {
                  formik.setFieldValue(myItem.startName ? myItem.startName : "StartDate", '')
                  formik.setFieldValue(myItem.endName ? myItem.endName : "EndDate", '')
                }
              }}
              type="range"
              datePickerOnly
              filterDate={date => {
                const sd = dayjs().subtract(myItem.range, 'day')
                if (dayjs(date).diff(dayjs()) > 0 || dayjs(date).diff(sd) < 0) {
                  return false
                }
                return true
              }}
            />
          )
        } else if (item.type == "selectGroup") {
          let myItem = item as MySelectGroup
          return (
            <Select
              placeholder={`${myItem.label}`}
              search
              options={(mgroups.data || []).map((d: any) => ({
                key: d.id,
                text: d.name,
                value: d.id,
              }))}
              onChange={(_: any, d: any) => {
                formik.setFieldValue(myItem.name, d.value)
                if (onSelectGroup) {
                  onSelectGroup(d.value)
                }
              }}
            />
          )
        } else if (item.type == "search") {
          let myItem = item as MySearch
          return (
            <Button
              content={myItem.label}
              size="mini"
              icon="arrow right"
              labelPosition="right"
              color="green"
              style={{ marginLeft: '.2em' }}
              type="submit"
            />
          )
        } else if (item.type == "button") {
          let myItem = item as MyButton
          return (
            <Button
              content={myItem.label}
              size="mini"
              color="green"
              style={{ marginLeft: '.2em' }}
              onClick={e => {
                myItem.click()
                e.preventDefault()
              }}
            />
          )
        }
      }
      )}

    </form>
  )
}

export default SearchBarNew
