import React, { useState, useEffect } from 'react'
import { Modal, Button, Image } from 'semantic-ui-react'
import { getAxios } from '../../../axios'
import { HIS_KEY } from '../../../config'

interface AlipayRecordProps {
  onClose: () => void
  tid: string
}

const AlipayRecord: React.FC<AlipayRecordProps> = ({ onClose, tid }) => {
  const [url, setUrl] = useState<string>('')
  const [image, setImage] = useState<ArrayBuffer | null | undefined | string>(undefined)
  useEffect(() => {
    const ax = getAxios('hotel')
    ax.post(
      `ordersWaiting/downFileStream?his_key=${HIS_KEY}`,
      { tid },
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth') || '{}').token || ''}`,
        },
        responseType: 'blob', // 设置响应数据类型
      },
    )
      .then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data])
          const url = window.URL.createObjectURL(blob)
          setUrl(url)
          const a = new FileReader()
          a.readAsDataURL(blob)
          a.onload = function (e) {
            const base64 = e.target?.result?.toString()
            setImage(base64?.replace('data:application/octet-stream;', 'data:application/pdf;'))
          }
        }
      })
      .catch(err => {
        window.alert(err)
      })
  }, [])

  return (
    <>
      <Modal.Header>支付宝回单</Modal.Header>
      <Modal.Content scrolling>
        <embed src={image?.toString()} type="application/pdf" width="100%" style={{ minHeight: '75vh' }}></embed>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          disabled={!url}
          onClick={() => {
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `${tid}.pdf`) // 自定义下载文件名（如exemple.txt）
            document.body.appendChild(link)
            link.click()
          }}
        >
          下载
        </Button>
        <Button onClick={onClose}>取消</Button>
      </Modal.Actions>
    </>
  )
}

export default AlipayRecord