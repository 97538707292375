import React, { useState, useCallback, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { rootStore, RootStoreContext } from '../../../stores/root'
import { usePageStuff } from '../../../utils/hooks'
import MyTable from '../../../components/MyTable'
import { Button } from 'semantic-ui-react'
import ModalTrigger from '../../../components/ModalTrigger'
import InlineDivider from '../../../components/InlineDivider'
import { PAGE_SIZE_10 } from '../../../constant/PAGE_SIZE'
import SearchBarNew from '../../../components/admin/SearchBarNew'
import HotelForm from '../../../components/forms/hotel/HotelForm'
import { IZQHotel } from '../../../constant/New'
import RoomList from '../../../components/merchant/RoomList'
import { colors } from '../../../constant/CSS'


interface IProps {
}

const Hotels: React.FC<IProps> = observer(() => {
  const [hotel, setHotel] = useState<IZQHotel | "新增酒店" | null>(null)
  const [hotel2, setHotel2] = useState<IZQHotel | null>(null)
  const errorHandler = useCallback(
    (message: string) => {
      rootStore.notificationStore.push({
        id: 'fetch-hotels-fail',
        color: 'red',
        header: '读取酒店列表失败',
        content: message,
        expire: 30,
      })
    },
    [rootStore.notificationStore],
  )
  const { onPage, page, pageSize, data, total, loading, searchKeys, orderBy } = usePageStuff<IZQHotel>(
    PAGE_SIZE_10,
    'zq/Hotel',
    {
      groupId: rootStore.authStore.user?.groupId,
      hotelList: rootStore.authStore.user?.hotelList,
    },
    errorHandler
  )

  return (
    <>
      <SearchBarNew
        config={[
          { type: "input", name: 'hotelId', label: '酒店编码' },
          { type: "search", label: '搜索' },
        ]}
        onSearch={searchKeys => {
          onPage({ page: 1, pageSize, searchKeys, orderBy: [] })
        }}
      />
      <MyTable
        indexed
        celled
        paged
        selectable
        size="small"
        data={data}
        page={page}
        count={total}
        pageSize={pageSize}
        loading={loading}
        onPage={(page, pageSize) => onPage({ page, pageSize, searchKeys, orderBy })}
        onSort={orderBy => {
          onPage({ page, pageSize, searchKeys, orderBy })
        }}
        headers={[
          { name: '酒店ID' },
          { name: '酒店名称' },
          { name: '地址电话' },
          { name: '通知电话' },
          { name: '短信接收' },
        ]}
        columns={[
          { name: 'hotelId', width: 1 },
          { name: 'hotelName', width: 2 },
          {
            name: 'address',
            render: (data: IZQHotel) => (
              <>
                <span>
                  {data.cityName}<InlineDivider />{data.address} <InlineDivider /> {data.telephone}
                </span>
              </>
            ),
          },
          { name: 'noticePhone', width: 1 },
          { name: 'smsPhone', width: 1 },
        ]}
        rightBtnGroupWidth={3}
        rightBtnGroup={(data: IZQHotel) => (
          <>
            <Button
              secondary
              inverted
              size="tiny"
              compact
              style={{ margin: '.1em' }}
              onClick={() => {
                setHotel(data)
              }}
            >
              修改
            </Button>
            <Button
              size="tiny"
              compact
              color="grey"
              content="标准房型"
              onClick={() => setHotel2(data)}
            />
          </>
        )}
        rightBottomCompSpan={3}
      />
      <ModalTrigger size="large" open={!!hotel}>
        <HotelForm onClose={() => setHotel(null)} hotel={hotel!} onUpdateHtml={() => onPage({ page, pageSize, searchKeys, orderBy })} />
      </ModalTrigger>
      <ModalTrigger size="large" open={!!hotel2}>
        <RoomList
          data={hotel2!}
          onClose={() => setHotel2(null)}
        />
      </ModalTrigger>
    </>
  )
})
const HotelListWrapper: React.FC<{}> = observer(() => {
  const rootStore = useContext(RootStoreContext)

  if (rootStore.authStore.user?.groupId === undefined) return <span style={{ color: colors.grey }}>加载中...</span>
  return <Hotels />
})
export default HotelListWrapper
