//在卖平台
export type ShopType = 'syz' | 'sp' | 'swy' | 'wechat' | 'zqfz' | 'qunar' | "zqqunar" | "sl" | "zqsl"
//在卖的平台（用于客服提现）
export const ShopAll: ShopType[] = ['syz', 'sp', 'swy', 'wechat', 'zqfz', 'qunar', "zqqunar", "sl", "zqsl"]
//获取售卖平台名称
export const getShopName = (shop: string | undefined) => {
    if (shop === 'sp') {
        return '盛平'
    } else if (shop === 'syz') {
        return '盛易住'
    } else if (shop === 'zqfz') {
        return '盛吾游直签'
    } else if (shop === 'wx') {
        return '微信'
    } else if (shop === 'swy') {
        return '盛吾游'
    } else if (shop === 'sl') {
        return '商旅'
    } else if (shop === 'zqsl') {
        return '商旅直签'
    } else if (shop === 'qunar') {
        return '去哪儿'
    } else if (shop === 'zqqunar') {
        return '去哪儿直签'
    } else {
        return '接单系统'
    }
}
//获取售卖平台颜色
export const getShopColor = (shop: string | undefined) => {
    if (shop === 'sp') {
        return 'red'
    } else if (shop === 'syz') {
        return 'pink'
    } else if (shop === 'zqfz') {
        return 'yellow'
    } else if (shop === 'wx') {
        return 'green'
    } else if (shop === 'swy') {
        return 'purple'
    } else if (shop === 'sl') {
        return 'orange'
    } else if (shop === 'zqsl') {
        return 'orange'
    } else if (shop === 'qunar') {
        return 'blue'
    } else if (shop === 'zqqunar') {
        return 'blue'
    } else {
        return 'blue'
    }
}