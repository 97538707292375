import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Container, Button, Icon, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import AdminMenu from '../components/admin/AdminMenu'
import AdminHeader from '../components/admin/AdminHeader'
import MenuToggle from '../components/MenuToggle'
import Notification from '../components/Notification'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { RootStoreContext } from '../stores/root'
import PortalWrapper from './PortalWrapper'
import Footer from './Footer'
import Confirm from './Confirm'
import { getSocket } from '../utils/ws'

const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1em;
  margin-bottom: 1em;
`

interface IProps { }

const AdminLayout: React.FC<IProps> = observer(({ children }) => {
  const [fold, setFold] = useState<boolean>(false)
  const rootStore = useContext(RootStoreContext)
  const conRef = useRef<HTMLDivElement>(null)
  const [goTop, setGoTop] = useState<boolean>(false)
  const [pushFold, setPushFold] = useState<boolean>(false)

  const nLen = rootStore.notificationStore.notifications.length

  const handleScroll = useCallback(() => {
    if (conRef.current) {
      const { height } = conRef.current.getBoundingClientRect()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const wh = document.querySelector('body')!.getBoundingClientRect().height

      if (height > wh && window.scrollY > 500) {
        setGoTop(true)
      } else {
        setGoTop(false)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    getSocket()
  }, [])

  return (
    <Container style={{ width: '100vw' }}>
      {rootStore.authStore.sideMenu.length > 0 && (
        <MenuToggle
          direction={fold ? 'right' : 'left'}
          onClick={() => {
            setFold(!fold)
          }}
        />
      )}

      {rootStore.wsStore.status === false ? (
        <Message
          color="orange"
          style={{
            position: 'fixed',
            top: '50vh',
            left: '50vw',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            fontSize: '1.2em',
          }}
        >
          <Icon name="unlink" />
          Websocket通知连接断开, 请刷新页面或
          <a style={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>
            [点击重连]
          </a>
        </Message>
      ) : null}

      <AdminHeader />
      <div
        ref={conRef}
        style={{
          display: 'grid',
          gridTemplateColumns: !fold ? 'auto 1fr' : '1fr',
          gridGap: '1em',
          paddingLeft: '1em',
          paddingRight: '1em',
          paddingBottom: '2em',
          alignItems: 'start',
          paddingTop: '5em', 
        }}
      >
        {rootStore.authStore.sideMenu.length > 0 && !fold && <AdminMenu />}

        <div>{children}</div>
      </div>

      {nLen > 0 ? (
        <NotificationWrapper>
          {!pushFold &&
            rootStore.notificationStore.notifications.map(({ id, header, content, color, expire }) => (
              <Notification
                key={id}
                color={color}
                header={header}
                content={content}
                expire={expire}
                onDismiss={() => rootStore.notificationStore.delete(id)}
              />
            ))}

          <div>
            {nLen > 1 && (
              <Button
                color="red"
                size="small"
                circular
                floated="right"
                onClick={() => rootStore.notificationStore.clear()}
              >
                <Icon name="trash" /> x &nbsp;{nLen}
              </Button>
            )}

            {nLen > 5 && (
              <Button secondary size="small" circular floated="right" onClick={() => setPushFold(!pushFold)}>
                <Icon name={pushFold ? 'angle double up' : 'angle double down'} /> x &nbsp; {nLen}
              </Button>
            )}
          </div>
        </NotificationWrapper>
      ) : null}

      {goTop && (
        <Button
          circular
          icon="arrow up"
          color="green"
          onClick={() => {
            document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
          }}
          style={{ position: 'fixed', bottom: '1em', left: '1em' }}
        />
      )}

      <PortalWrapper />

      <Confirm />

      <Footer />
    </Container>
  )
})

export default AdminLayout
