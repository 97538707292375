import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Button, Table, Label } from 'semantic-ui-react'
import { windowTypes } from '../../constant/constants'
import { IZQHotel, IZQRoom } from '../../constant/New';
import { post } from '../../axios'
import { colors } from '../../constant/CSS';

interface RoomListProps {
  data: IZQHotel
  onClose: () => void
}
const RoomList: React.FC<RoomListProps> = ({ data, onClose }) => {
  const [roomList, setRoomList] = useState<IZQRoom[]>([])
  useEffect(() => {
    initRoom(data.hotelId)
  }, [data.hotelId])
  const initRoom = useCallback(
    async (hotelId: string) => {
      if (hotelId) {
        post<any>(`/zq/Room/${hotelId}`, {}).then(res => {
          setRoomList(res)
        })
      }
    },
    [],
  )
  return (
    <>
      <Modal.Header>{data.hotelName}</Modal.Header>
      <Modal.Content>
        <Table size="small" striped compact selectable collapsing celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>房型</Table.HeaderCell>
              <Table.HeaderCell>美团房型</Table.HeaderCell>
              <Table.HeaderCell>床型</Table.HeaderCell>
              <Table.HeaderCell>可用面积</Table.HeaderCell>
              <Table.HeaderCell>所在楼层</Table.HeaderCell>
              <Table.HeaderCell>是否有窗</Table.HeaderCell>
              <Table.HeaderCell>最大入住人数</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(roomList || []).map((item, idx) => {
              return (
                <Table.Row key={idx}  >
                  <Table.Cell>
                    {item.realroomName}
                    {item.realroomName.includes('单') ? (
                      <Label circular color="pink" size="mini" style={{ marginLeft: '1em' }}>
                        单
                      </Label>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>  {item.realroomNameMT} </Table.Cell>
                  <Table.Cell>  {item.bedInfos[0].parentBedName} </Table.Cell>
                  <Table.Cell>  {item.areaRange} </Table.Cell>
                  <Table.Cell>  {item.floor} </Table.Cell>
                  <Table.Cell>
                    <>
                      {windowTypes.find(r => r.value === item.windowType)?.text || (
                        <strong style={{ color: colors.red }}>异常</strong>
                      )}
                    </>
                  </Table.Cell>
                  <Table.Cell>  {item.maxOccupancy} </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={onClose}>
          取消
        </Button>
      </Modal.Actions>
    </>
  )
}

export default RoomList
