import React, { ReactElement, useState } from 'react'
import { Step, Icon, Button } from 'semantic-ui-react'
import { CommonSizeType, UIColor } from '../constant/ALL'

interface IProps {
  config: {
    icon?: string
    title?: string
    desc?: string
    component: ReactElement
    canNext?: boolean
  }[]
  size?: CommonSizeType
  fluid?: boolean
  onDone: () => void
  nextBtnText?: string
  doneBtnText?: string
  nextBtnColor?: UIColor
  doneBtnColor?: UIColor
  floated?: 'right' | 'left'
  btnSize?: CommonSizeType
  canBack?: boolean
  backBtnColor?: UIColor
  backBtnText?: string
}

const MultiSteps: React.FC<IProps> = ({
  size = 'mini',
  config,
  fluid = true,
  onDone,
  nextBtnText = '下一步',
  doneBtnText = '完成',
  nextBtnColor = 'blue',
  doneBtnColor = 'green',
  btnSize = 'small',
  floated = 'right',
  canBack = false,
  backBtnColor = 'black',
  backBtnText = '返回',
}) => {
  const [active, setActive] = useState<number>(0)

  return (
    <>
      <Step.Group size={size} fluid={fluid}>
        {config.map(({ icon, title, desc }, i) => (
          <Step key={i} active={i === active}>
            {icon && <Icon name={icon as any} />}
            <Step.Content>
              {title && <Step.Title>{title}</Step.Title>}
              {desc && <Step.Description>{desc}</Step.Description>}
            </Step.Content>
          </Step>
        ))}
      </Step.Group>

      {config[active].component}
      <div style={{ marginTop: '1em' }}>
        <Button
          disabled={!config[active].canNext}
          floated={floated}
          size={btnSize}
          onClick={() => {
            if (active < config.length - 1) {
              setActive(active + 1)
            } else {
              onDone()
            }
          }}
          color={active === config.length - 1 ? doneBtnColor : nextBtnColor}
        >
          {active === config.length - 1 ? doneBtnText : nextBtnText}
        </Button>
        {canBack && active > 0 ? (
          <Button
            size={btnSize}
            color={backBtnColor}
            floated={floated}
            onClick={() => {
              setActive(active - 1)
            }}
          >
            {backBtnText}
          </Button>
        ) : null}
      </div>
    </>
  )
}

export default MultiSteps
