export const formatDate = (day: Date) => {
  const fullyear = day.getFullYear()
  const month = day.getMonth()
  const date = day.getDate()

  return `${fullyear}-${month < 9 ? '0' : ''}${month + 1}-${date < 10 ? '0' : ''}${date}`
}

export const formatDatetime = (day: Date, split = 'T') => {
  const dateStr = formatDate(day)
  const hours = day.getHours()
  const minutes = day.getMinutes()
  const seconds = day.getSeconds()
  return `${dateStr}${split}${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`
}

export const nextSomeDate = (day = 0, start = new Date()) => {
  let now = start
  if (day > 0) {
    now = new Date(now.getTime() + 1000 * 60 * 60 * 24 * day)
  }

  return formatDate(now)
}


export const generateDurations2 = (days = 1, start = new Date()) => {
  const dayArr = []
  for (let d = 0; d <= days + 1; d++) {
    dayArr.push(nextSomeDate(d, start))
  }

  const r = []
  for (let d = 0; d < dayArr.length - 1; d++) {
    r.push([dayArr[d], dayArr[d + 1]])
  }

  return r
}


export const utilDayOfWeek = (day: string) => {
  const day_str: any[] = day.split('-')
  const date = new Date(day_str[0], day_str[1] - 1, day_str[2])
  if (date.getDay() == 1) {
    return '星期一'
  } else if (date.getDay() == 2) {
    return '星期二'
  } else if (date.getDay() == 3) {
    return '星期三'
  } else if (date.getDay() == 4) {
    return '星期四'
  } else if (date.getDay() == 5) {
    return '星期五'
  } else if (date.getDay() == 6) {
    return '星期六'
  } else {
    return '星期天'
  }
}
