import styled from 'styled-components'
import { colors } from './CSS'


export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
`
export const A = styled.a<{ color?: string }>`
  cursor: pointer;
  color: ${props => props.color || colors.blue};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Toggle = styled.div`
  position: fixed;
  top: 50vh;
  transform: translate(-70%, -60%);
  transition: 0.5s;
  &:hover {
    transform: translate(-10%, -60%);
  }
  z-index: 100;
  padding-left: 100px;
  padding: 0;
`
export const Point = styled.div<{ color?: string }>`
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background-color: ${props => props.color || '#db2828'};
  position: absolute;
  bottom: -0.2em;
  right: -0.2em;
  cursor: pointer;
`
export const AfterAeterisk = styled.span`
  &::after {
    content: ' *';
    color: red;
  }
`
export const Cell = styled.div`
  margin: 0.5em;
  margin-left: 0;
`

export const CellGroup = styled.div`
  margin: 0.5em;
  margin-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1em;
  margin-bottom: 1em;
`
export const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  background: white;
  border: 1px solid lightgray;
  height: auto;
  border-radius: 0.2em;
  overflow: auto;
`



export const Row = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const Column = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
`
export const Header = styled(Row)`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid lightgray;
  align-items: center;
  display: flex;
  flex-direction: row;
  & .icon:first-of-type {
    margin-left: 1em;
  }
  & .icon:last-of-type {
    margin-right: 1em;
  }
`


export const Days = styled(Row)`
  text-transform: uppercase;
  font-weight: 400;
  color: gray;
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid lightgray;
`

export const Body = styled.div`
  & .cell {
    position: relative;
    border-right: 1px solid lightgray;
    overflow: hidden;
    cursor: pointer;
    background: white;
    transition: 0.25s ease-out;
    &:hover {
      background: whitesmoke;
      transition: 0.5s ease-out;
    }
    min-height: 6em;
  }

  & .selected {
    border-left: 5px solid transparent;
    border-image: linear-gradient(45deg, #1affa0 0%, #cff153 40%);
    border-image-slice: 1;
  }

  & .disabled {
    color: lightgray;
    pointer-events: none;
  }

  & .row {
    border-bottom: 1px solid lightgray;
    display: flex;
    flex-direction: row;
  }

  & .row:last-child {
    border-bottom: none;
  }

  & .cell:last-child {
    border-right: none;
  }

  & .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: 0.75em;
    right: 0.75em;
    font-weight: 700;
  }

  & .cell:hover .number,
  & .selected .number {
    visibility: hidden;
  }

  & .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: #1affa0;
    opacity: 0;
    font-size: 5em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }

  & .cell:hover .bg,
  & .selected .bg {
    opacity: 0.3;
    transition: 0.5s ease-in;
  }

  & .column {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }

  & .events {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`


