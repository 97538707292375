import { IZQHotel, IZQRP, RoomState } from "../constant/New"

export const getUploadPrice1 = (cal: RoomState, rp: IZQRP | undefined, hotel: IZQHotel) => {
    //卖价模式
    if (hotel.cooperationModel == 1) {
        let point = (100 - hotel.point) / 100
        cal.basePrice = Math.floor(cal.price * point * 100) / 100
    }
    cal.uploadPriceFZ = hotel.targetOtaType.indexOf("FZ") > -1 ? cal.price + Number(hotel.fzReducePrice) + 10 : 0
    cal.uploadPriceMT = hotel.targetOtaType.indexOf("MT") > -1 ? cal.price + Number(hotel.mtReducePrice) : 0
    cal.uploadPriceQUNAR = hotel.targetOtaType.indexOf("QUNAR") > -1 ? cal.price + Number(hotel.qunarReducePrice) : 0
    cal.uploadPriceSL = hotel.targetOtaType.indexOf("SL") > -1 ? cal.price + Number(hotel.slReducePrice) : 0
    cal.uploadPriceDLT = hotel.targetOtaType.indexOf("DLT") > -1 ? cal.price + Number(hotel.dltReducePrice) : 0
    return cal
}
export const getUploadPrice2 = (cal: RoomState, rp: IZQRP | undefined, hotel: IZQHotel) => {
    cal.uploadPriceFZBase = hotel.targetOtaType.indexOf("FZ") > -1 ? Math.floor((cal.uploadPriceFZ - 10) * hotel.pointFZ * 100) / 100 : 0
    cal.uploadPriceMTBase = hotel.targetOtaType.indexOf("MT") > -1 ? Math.floor(Math.ceil(cal.uploadPriceMT * hotel.pointMT) * 100) / 100 : 0;
    cal.uploadPriceQUNARBase = hotel.targetOtaType.indexOf("QUNAR") > -1 ? Math.floor(cal.uploadPriceQUNAR * hotel.pointQUNAR * 100) / 100 : 0;
    cal.uploadPriceSLBase = hotel.targetOtaType.indexOf("SL") > -1 ? Math.floor(cal.uploadPriceSL * hotel.pointSL * 100) / 100 : 0
    cal.uploadPriceDLTBase = hotel.targetOtaType.indexOf("DLT") > -1 ? Math.floor(cal.uploadPriceDLT * hotel.pointDLT * 100) / 100 : 0
    return cal
}