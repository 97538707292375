import React from 'react'
import { Table, Label } from 'semantic-ui-react'
import _ from 'lodash'
import { IRawMenu, swapId, swapIds } from '../../utils/menu'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import { colors } from '../../constant/CSS'

interface IProps {
  menu: IRawMenu[]
  currentMenu: number[]
  updateMenu: (ids: number[]) => void
}

const checkParent = (id: number, data: IRawMenu[]): number[] => {
  const p = data.find(d => d.menuId === id)
  if (p?.parentId) {
    return [p.parentId, ...(checkParent(p.parentId, data) || [])]
  }
  return []
}

const uncheckChildren = (id: number, data: IRawMenu[]): number[] => {
  const c = data.filter(d => d.parentId === id)
  return _.flattenDeep([
    c.map(cc => cc.menuId),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    c.map(cc => uncheckChildren(cc.menuId, data)),
  ])
}

const CellLabel: React.FC<{ name: string; url: string; selected: boolean; onClick: () => void }> = ({
  name,
  url,
  selected,
  onClick,
}) => (
  <Label
    size="small"
    color={(selected ? 'blue' : undefined) as SemanticCOLORS}
    style={{ cursor: 'pointer' }}
    onClick={onClick}
  >
    {name}
    <Label.Detail>{url}</Label.Detail>
  </Label>
)

const MenuList: React.FC<IProps> = ({ menu, currentMenu, updateMenu }) => {
  const data = menu
  const levelOne = menu?.filter(item => item.parentId === null)

  return (
    <>
      {data && levelOne && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}
        >
          {levelOne.map(lo => {
            const levelTwo = data.filter(d => d.parentId === lo.menuId)
            const selected1 = currentMenu.includes(lo.menuId)
            return (
              <div key={lo.menuId} style={{ marginRight: '1em', marginBottom: '1em' }}>
                <Table celled size="small" compact collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan={2}>
                        <CellLabel
                          name={lo.menuName}
                          url={lo.menuUrl}
                          selected={selected1}
                          onClick={() => {
                            if (selected1) {
                              updateMenu(
                                swapIds(selected1, [...uncheckChildren(lo.menuId, data), lo.menuId], currentMenu),
                              )
                            } else {
                              updateMenu(swapId(selected1, lo.menuId, currentMenu))
                            }
                          }}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {levelTwo.map(l2 => {
                    const levelThree = data.filter(d => d.parentId === l2.menuId)
                    const selected2 = currentMenu.includes(l2.menuId)
                    return (
                      <Table.Body key={l2.menuId}>
                        {levelThree.map((l3, idx3) => (
                          <Table.Row key={l3.menuId}>
                            {idx3 === 0 && (
                              <Table.Cell rowSpan={levelThree.length || 1}>
                                <CellLabel
                                  name={l2.menuName}
                                  url={l2.menuUrl}
                                  selected={selected2}
                                  onClick={() => {
                                    if (selected2) {
                                      updateMenu(swapId(selected2, l2.menuId, currentMenu))
                                    } else {
                                      updateMenu(
                                        swapIds(selected2, [...checkParent(l2.menuId, data), l2.menuId], currentMenu),
                                      )
                                    }
                                  }}
                                />
                              </Table.Cell>
                            )}
                            <Table.Cell  >
                              <i>
                                <b
                                  style={{
                                    fontWeight: 'bold',
                                    color: currentMenu.includes(l3.menuId) ? colors.blue : colors.grey,
                                  }}
                                >
                                  {idx3 + 1}{' '}
                                </b>
                              </i>

                              <CellLabel
                                name={l3.menuName}
                                url={l3.menuUrl}
                                selected={currentMenu.includes(l3.menuId)}
                                onClick={() => {
                                  updateMenu(swapId(currentMenu.includes(l3.menuId), l3.menuId, currentMenu))
                                  if (currentMenu.includes(l3.menuId)) {
                                    updateMenu(swapId(currentMenu.includes(l3.menuId), l3.menuId, currentMenu))
                                  } else {
                                    updateMenu(
                                      swapIds(
                                        currentMenu.includes(l3.menuId),
                                        [...checkParent(l3.menuId, data), l3.menuId],
                                        currentMenu,
                                      ),
                                    )
                                  }
                                }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    )
                  })}
                </Table>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default MenuList
