import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Icon, Header, Segment } from 'semantic-ui-react'
import { getAxios, AxiosKey } from '../axios'
import { SemanticICONS, SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import { HIS_KEY } from '../config'

interface IProps {
  title?: string
  basic?: boolean
  btnColor?: SemanticCOLORS
  icon?: SemanticICONS
  onLoad?: (url: string) => void
  onSuccess?: (message: any) => void
  onError?: (message: string) => void
  url: string
  accept?: string
  ak: AxiosKey
}

const FileUpload: React.FC<IProps> = ({
  title = '拖拽或点击选择上传文件',
  basic = true,
  btnColor = 'blue',
  icon = 'upload',
  onLoad,
  onSuccess,
  onError,
  url,
  accept,
  ak
}) => {
  const [uploading, setUploading] = useState<boolean>(false)
  const onDrop = useCallback(acceptedFiles => {
    let count = 1
    setUploading(true)
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()
      reader.onabort = () => {
        if (onError) {
          onError('操作被中断')
        }
      }
      reader.onerror = () => {
        if (onError) {
          onError('文件读取失败')
        }
      }
      reader.onload = () => {
        if (onLoad) {
          const preview = URL.createObjectURL(file)
          onLoad(preview)
        }
      }
      reader.readAsArrayBuffer(file)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('key', HIS_KEY)
      getAxios(ak)
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth') || '{}').token || ''}`,
          },
        })
        .then(res => {
          const { code, message, data } = res.data
          if (code === '1') {
            if (onSuccess) {
              onSuccess(data)
            }
          } else {
            if (onError) {
              onError(message)
            }
          }
        })
        .catch(err => {
          if (onError) {
            onError(err.message || err.toString())
          }
        })
        .finally(() => {
          if (count === acceptedFiles.length) {
            setUploading(false)
          }
          count++
        })
    })
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ accept: accept || 'image/*', onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {basic ? (
        <Button loading={uploading} disabled={uploading} color={btnColor} inverted onClick={e => e.preventDefault()}>
          {title}
          &nbsp;&nbsp;
          <Icon name={icon} />
        </Button>
      ) : (
        <Segment placeholder>
          <Button loading={uploading} disabled={uploading} color={btnColor} onClick={e => e.preventDefault()}>
            添加上传文件
          </Button>
          <Header icon>
            <Icon name={icon} />
            {title && '没有选中文件'}
          </Header>
        </Segment>
      )}
    </div>
  )
}

export default FileUpload
