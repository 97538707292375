import { observable, action } from 'mobx'
import { RootStore } from './root'
import { persist } from 'mobx-persist'

interface IPush {
  id: string
  metadata: any
  title?: string
  type?: 'NEW_BROADCAST' | 'NEW _UPDATE'
  createdTime: Date
}

export class PushStore {
  rootStore: RootStore

  @persist('list') @observable pushes: IPush[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action ignore() {
    if (this.pushes.length > 0) {
      this.pushes.shift()
    }
  }
}
