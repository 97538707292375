import * as Yup from 'yup'

const nameReg = new RegExp('^([\u4E00-\u9FA5]+)|([a-zA-Z]+)$')

const sharedYup = {
  firstName: Yup.string()
    .matches(nameReg, '请输入中文名或英文First Name')
    .required('请输入名'),
  lastName: Yup.string()
    .matches(nameReg, '请输入中文姓或英文Last Name')
    .required('请输入姓'),
  email: Yup.string(),
  mobileNumber: Yup.string()
    .min(7, '电话号码过短')
    .max(50, '电话号码过长')
    .required('请输入电话号码'),
  mobileNumber2: Yup.string()
    .min(7, '电话号码过短')
    .max(50, '电话号码过长'),
  roleId: Yup.number().required('请选择角色'),
  birthday: Yup.string(),
  gender: Yup.number().required('请选择性别'),
  headSculpture: Yup.string(),
}

export const userSchema = Yup.object().shape({
  ...sharedYup,
  userName: Yup.string()
    .matches(/^\w+@\d+$/, '不能还有空格')
    .min(6, '用户名需大于等于8个字符!')
    .max(24, '用户名需小于等于24个字符!')
    .required('请输入登录账户'),
  password: Yup.string()
    .min(8, '密码需大于等于8个字符!')
    .max(24, '密码需小于等于24个字符!')
    .matches(/^(?=.*\d)(?=.*\w).{8,24}$/, '密码必须包含数字、英文字母')
    .required('请输入登录密码'),
})

export const userSchemaForUpdate = Yup.object().shape(sharedYup)
