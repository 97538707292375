//更新指定的行数据
export const updateRow = (setData: Function, list: any[], key: string, map: { [key: string]: any }) => {
    setData(
        list.map(old => {
            //更新指定一条数据的值
            if (old[key] == map[key]) {
                console.log({ ...old, ...map, })
                return { ...old, ...map, }
            }
            return old
        }),
    )
}
//删除指定行
export const deleteRow = (setData: Function, list: any[], key: string, value: any) => {
    setData(list.filter(old => old[key] !== value))
}
//在头部添加行，去重
export const addHeaderRow = (setData: Function, list: any[], key: string, value: any) => {
    setData([value, ...list.filter(old => old[key] !== value[key])])
}
