import React, { useState, useEffect, useCallback } from 'react'
import { Table, Select, Form, Label, Divider, Button, Checkbox, Icon } from 'semantic-ui-react'
import _ from 'lodash'
import dayjs from 'dayjs'
import { usePostCancel } from '../../../utils/hooks'
import { IZQHotel, IZQRoom, IZQRP } from '../../../constant/New'
import { PAGE_SIZE_MAX } from '../../../constant/PAGE_SIZE'
import { post } from '../../../axios'
import { modeDict, targetOtaDict, transferDict } from '../../../constant/ALL'
import ModalTrigger from '../../../components/ModalTrigger'
import CloseList from '../../../components/merchant/CloseList'
import RPList2 from '../../../components/merchant/RPList2'
import { colors } from '../../../constant/CSS'
import { windowTypes } from '../../../constant/constants'
import { rootStore } from '../../../stores/root'
interface RoomProps {
}
const RoomWrapper: React.FC<RoomProps> = () => {
  const [hotelId, setHotelId] = useState<number>(0)
  const [hotels, setHotels] = useState<IZQHotel[]>([])
  const [hotel, setHotel] = useState<IZQHotel>()
  const [todayState, setTodayState] = useState<{ [key: string]: number }>({})
  const [roomList, setRoomList] = useState<IZQRoom[]>([])
  const [showClose, setShowClose] = useState<boolean>(false)
  const [room, setRoom] = useState<IZQRoom | null>(null)
  useEffect(() => {
    post<IZQHotel[]>(`zq/Hotel`, {
      groupId: rootStore.authStore.user?.groupId,
      hotelList: rootStore.authStore.user?.hotelList,
      page: 0,
      pageSize: PAGE_SIZE_MAX,
    }).then(res => {
      if (res) {
        setHotels(res)
      }
    })
  }, [])
  const initRoom = useCallback(
    async (hotelId: string) => {
      if (hotelId) {
        post<any>(`/zq/Room/${hotelId}`, {}).then(res => {
          setRoomList(res)
        })
      }
    },
    [],
  )
  const initTodayState = useCallback(
    async (hotelId: string) => {
      if (hotelId) {
        post<any>(`/zq/Room/todayState/${hotelId}`, {}).then(res => {
          console.log(res)
          setTodayState(res)
        })
      }
    },
    [],
  )
  return (
    <>
      <Form>
        <Form.Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Form.Field>
            <Select
              placeholder="选择酒店"
              search
              options={(hotels || []).map(d => ({
                key: d.hotelId,
                text: d.hotelName,
                value: d.hotelId,
              }))}
              value={hotelId}
              onChange={(_: any, d: any) => {
                setHotel(hotels.find(h => h.hotelId === d.value))
                setHotelId(d.value)
                initRoom(d.value)
                initTodayState(d.value)
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider />
      {hotel && (
        <>
          <Table size="small" striped compact selectable collapsing celled sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>名称</Table.HeaderCell>
                <Table.HeaderCell>地址</Table.HeaderCell>
                <Table.HeaderCell>前台电话</Table.HeaderCell>
                <Table.HeaderCell>通知电话</Table.HeaderCell>
                <Table.HeaderCell>短信接收</Table.HeaderCell>
                <Table.HeaderCell>合作模式</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{hotel.hotelName}</Table.Cell>
                <Table.Cell>{hotel.address}</Table.Cell>
                <Table.Cell>{hotel.telephone}</Table.Cell>
                <Table.Cell>{hotel.noticePhone}</Table.Cell>
                <Table.Cell>{hotel.smsPhone}</Table.Cell>
                <Table.Cell>{modeDict[hotel.cooperationModel]} </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
      {hotel && (
        <Table size="small" striped compact selectable collapsing celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>房型</Table.HeaderCell>
              <Table.HeaderCell>可用面积</Table.HeaderCell>
              <Table.HeaderCell>所在楼层</Table.HeaderCell>
              <Table.HeaderCell>是否有窗</Table.HeaderCell>
              <Table.HeaderCell>最大入住人数</Table.HeaderCell>
              <Table.HeaderCell>
                <Button
                  color="red"
                  inverted
                  size="tiny"
                  compact
                  style={{ margin: '.1em' }}
                  onClick={() => {
                    setShowClose(true)
                  }}
                >
                  批量关闭
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(roomList || []).map((item, idx) => {
              return (
                <Table.Row key={idx}  >
                  <Table.Cell>{idx + 1} </Table.Cell>
                  <Table.Cell>
                    <Label
                      key={item.realroomId}
                      color={'blue'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setRoom(item)
                      }}
                    >
                      {item.realroomName}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>  {item.areaRange} </Table.Cell>
                  <Table.Cell>  {item.floor} </Table.Cell>
                  <Table.Cell>
                    <>
                      {windowTypes.find(r => r.value === item.windowType)?.text || (
                        <strong style={{ color: colors.red }}>异常</strong>
                      )}
                    </>
                  </Table.Cell>
                  <Table.Cell>  {item.maxOccupancy} </Table.Cell>
                  <Table.Cell>
                    {todayState[item.realroomId] && todayState[item.realroomId] == 1 ? (
                      <Button
                        color="red"
                        inverted
                        size="tiny"
                        compact
                        style={{ margin: '.1em' }}
                        onClick={() => {
                          if (window.confirm(`确定关闭当天[${item.realroomName}]?`)) {
                            post<IZQRP[]>(`zq/Rp/${item.realroomId}`, {}).then(res => {
                              let day = dayjs().format('YYYY-MM-DD')
                              if (res) {
                                let ratePlans: any[] = []
                                for (let rp of res) {
                                  for (let i = 0; i < rp.roomStates.length; i++) {
                                    if (rp.roomStates[i].date == day) {
                                      rp.roomStates[i].status = 0
                                    }
                                  }
                                  ratePlans.push({
                                    ratePlanCode: rp.ratePlanCode,
                                    priceList: rp.roomStates
                                  })
                                }
                                const data = {
                                  HotelId: hotel.hotelId,
                                  IsMerchant: true,
                                  ratePlans
                                }
                                post<any>(`zq/hotel/updateRpPrice`, data).then(res => {
                                  initTodayState(hotel.hotelId)
                                  window.alert("更新成功")
                                }).catch(err => {
                                  window.alert(err)
                                })
                              }
                            }).catch(err => window.alert(err))
                          }
                        }}
                      >
                        关闭当天
                      </Button>
                    ) : <Label color={'grey'}  >  已关闭当天   </Label>}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )}

      {hotel && (
        <>
          <ModalTrigger size="large" open={showClose}>
            <CloseList
              hotel={hotel}
              onClose={() => {
                initTodayState(hotel.hotelId)
                setShowClose(false)
              }}
            />
          </ModalTrigger>
          <ModalTrigger size="large" open={!!room}>
            <RPList2
              data={room!}
              onClose={() => {
                initTodayState(hotel.hotelId)
                setRoom(null)
              }}
              hotel={hotel}
            />
          </ModalTrigger>
        </>
      )}
    </>
  )
}

export default RoomWrapper
