import React, { useState } from 'react'
import { Grid, Form, Button } from 'semantic-ui-react'
import MyTextArea from '../MyTextArea'

interface RefundReasonCardProps {
  onSuccess: (r: string) => void
  onCancel: () => void
}

const RefundReasonCard: React.FC<RefundReasonCardProps> = ({ onSuccess, onCancel }) => {
  const [reason, setReason] = useState<string>('')
  return (
    <Grid style={{ minWidth: '200px' }}>
      <Grid.Column>
        <Form>
          <MyTextArea maxLength={150} onChange={(_, data: any) => setReason(data.value)} />
          <Button.Group size="mini" fluid>
            <Button size="mini" onClick={onCancel}>
              取 消
            </Button>
            <Button
              size="mini"
              color="red"
              onClick={e => {
                e.preventDefault()
                onSuccess(reason)
              }}
              disabled={!reason.trim()}
            >
              拒 绝
            </Button>
          </Button.Group>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

export default RefundReasonCard
