import { action, observable } from 'mobx'
import { RootStore } from './root'
import { persist } from 'mobx-persist'
import { ShopType } from '../utils/shop'

export class ReminderOrderStore {
  rootStore: RootStore

  @persist('object') @observable count: Record<ShopType, number> = {
    syz: 0,
    sp: 0,
    swy: 0,
    sl: 0,
    qunar: 0,
    zqqunar: 0,
    wechat: 0,
    zqfz: 0,
    zqsl: 1,
  }

  @action add(shop: ShopType) {
    this.count[shop] += 1
  }

  @action reset(shop: ShopType) {
    this.count[shop] = 0
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
}
