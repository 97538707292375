import React, { ReactElement, SyntheticEvent } from 'react'
import { Modal } from 'semantic-ui-react'

interface IProps {
  trigger?: ReactElement
  header?: string
  content?: ReactElement
  actions?: ReactElement
  basic?: boolean
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
  centered?: boolean
  dimmer?: 'inverted' | 'blurring' | undefined
  closeIcon?: boolean
  closeOnDimmerClick?: boolean
  closeOnDocumentClick?: boolean
  onActionClick?: (event: SyntheticEvent, data: object) => void
  onClose?: (event: SyntheticEvent, data: object) => void
  onMount?: (event: SyntheticEvent, data: object) => void
  onOpen?: (event: SyntheticEvent, data: object) => void
  onUnmount?: (event: SyntheticEvent, data: object) => void
  open?: boolean
}

const ModalTrigger: React.FC<IProps> = ({
  trigger,
  header,
  content,
  basic = false,
  size = 'small',
  centered = false,
  actions,
  dimmer = 'blurring',
  closeIcon = false,
  closeOnDimmerClick = true,
  closeOnDocumentClick = false,
  open,
  children,
}) => {
  return (
    <Modal
      open={open}
      dimmer={dimmer}
      trigger={trigger}
      basic={basic}
      size={size}
      centered={centered}
      closeIcon={closeIcon}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnDocumentClick={closeOnDocumentClick}
    >
      {children || (
        <>
          {header && <Modal.Header>{header}</Modal.Header>}
          <Modal.Content scrolling>{content}</Modal.Content>
          {actions && <Modal.Actions>{actions}</Modal.Actions>}
        </>
      )}
    </Modal>
  )
}

export default ModalTrigger
