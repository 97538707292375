import React, { useState } from 'react'
import { Form, Input, Select, Button, Icon, Message, Image } from 'semantic-ui-react'
import { usePostCancel } from '../../utils/hooks'
import FileUpload from '../FileUpload'
import { useFormik } from 'formik'
import { userSchema, userSchemaForUpdate } from '../../validationSchemas/userSchema'
import dayjs from 'dayjs'
import { post } from '../../axios'
import { AVATAR_BASE_URL, HIS_KEY } from '../../config'
import GroupHotelList from '../hotel/GroupHotelList'
import { IRole, IUser } from '../../constant/interface'
import { IZQHotel } from '../../constant/New'
import { colors } from '../../constant/CSS'

const GENDER_OPTIONS = [
  { key: 'm', text: '男', value: 1 },
  { key: 'f', text: '女', value: 0 },
]

export const ACTIVE_STATUS = [
  { key: '1', text: '激活', value: 1 },
  { key: '2', text: '禁用', value: 2 },
  { key: '3', text: '黑名单', value: 3 },
]

interface IProps {
  onClose: () => void
  user: IUser | null
  onSuccess?: (user: IUser) => void
  onError?: (message: string) => void
  groupId?: any
}

const UserForm: React.FC<IProps> = ({ onClose, user, onError, onSuccess, groupId }) => {
  const [showPwd, setShowPwd] = useState<boolean>(true)
  const [preview, setPreview] = useState<string | null>(null)
  const [headSculpture, setHeadSculpture] = useState<string>('')
  const [uploadStatus, setUploadStatus] = useState<{ type: 'negative' | 'positive'; message: string } | null>(null)
  const roles = usePostCancel<IRole[]>(groupId ? 'his/Role/querySupplierList' : 'roles', { groupId })
  const bindingHotels = usePostCancel<IZQHotel[]>('zq/Hotel/queryBindingHotel', { userName: user?.userName })

  const formik = useFormik({
    initialValues: {
      userName: user?.userName || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      password: '',
      activeStatus: user?.activeStatus,
      mobileNumber: user?.mobileNumber || '',
      mobileNumber2: user?.mobileNumber2 || '',
      email: user?.email || '',
      headSculpture: user?.headSculpture || '',
      birthday: user?.birthday ? dayjs(user.birthday).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
      gender: user?.genderDisplay ? (user.genderDisplay === '男' ? 1 : 0) : 1,
      roleId: user?.roleId,
      groupId: user?.groupId || groupId,
      hotelList: [],
    },
    onSubmit: values => {
      const url = !!user ? 'users/updateUser' : 'users/insertUser'
      let body: any = { ...values, headSculpture }
      if (!!user) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { password, ...rest } = values
        body = { ...rest, headSculpture, id: user.id }
      }
      post<{ status: string; user: number }>(url, body)
        .then(res => {
          if (onSuccess && res?.user) {
            onSuccess({
              ...values,
              id: res.user,
              name: values.lastName + values.firstName,
              genderDisplay: GENDER_OPTIONS.find(g => g.value === values.gender)?.text || '男',
            } as any)
          }
        })
        .catch(err => {
          if (onError) {
            onError(err.message || err.toString())
          }
        })
        .finally(() => {
          onClose()
        })
    },
    validationSchema: user ? userSchemaForUpdate : userSchema,
  })

  if (roles.loading) return <span style={{ color: colors.grey }}>加载中...</span>
  if (roles.error)
    return (
      <Message negative>
        <Message.Header>获取角色、菜单、权限列表失败</Message.Header>
        {roles.error && <p>{roles.error.message || JSON.stringify(roles.error)}</p>}
      </Message>
    )

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="姓 (Last Name)"
          placeholder="姓 (Last Name)"
          required
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && formik.errors.lastName}
        />

        <Form.Field
          control={Input}
          label="名 (First Name)"
          placeholder="名 (First Name)"
          required
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && formik.errors.firstName}
        />

        <Form.Field
          control={Input}
          label="账户名"
          placeholder="账户名"
          required
          name="userName"
          value={formik.values.userName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.userName && formik.errors.userName}
          disabled={!!user}
        />
        <Form.Field
          control={Select}
          label="性别"
          options={GENDER_OPTIONS}
          placeholder="性别"
          defaultValue={formik.values.gender}
          name="gender"
          onChange={(_: any, d: any) => formik.setFieldValue('gender', d.value)}
          error={formik.touched.gender && formik.errors.gender}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="邮箱"
          placeholder="邮箱"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
        />
        <Form.Field
          control={Input}
          label="联系电话"
          placeholder="联系电话"
          required
          name="mobileNumber"
          value={formik.values.mobileNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.mobileNumber && formik.errors.mobileNumber}
        />
        <Form.Field
          control={Input}
          label="紧急联系人"
          placeholder="紧急联系人"
          name="mobileNumber2"
          value={formik.values.mobileNumber2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.mobileNumber2 && formik.errors.mobileNumber2}
        />
        <Form.Field
          control={Input}
          label="出生日期"
          placeholder="出生日期"
          type="date"
          name="birthday"
          value={formik.values.birthday}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.birthday && formik.errors.birthday}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Select}
          label="账号状态"
          options={ACTIVE_STATUS}
          placeholder="账号状态"
          defaultValue={formik.values.activeStatus}
          name="activeStatus"
          onChange={(_: any, d: any) => formik.setFieldValue('activeStatus', d.value)}
          error={formik.touched.activeStatus && formik.errors.activeStatus}
        />
        <Form.Field required>
          <label>初始密码</label>
          <Form.Input
            placeholder="初始密码"
            type={showPwd ? 'text' : 'password'}
            icon={<Icon name={showPwd ? 'eye slash' : 'eye'} onClick={() => setShowPwd(prev => !prev)} link />}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            disabled={!!user}
          />
        </Form.Field>
        <Form.Field
          search
          control={Select}
          label="角色选择"
          defaultValue={formik.values.roleId}
          options={(roles.data || []).map(d => ({ key: d.id, text: d.name, value: d.id }))}
          placeholder="角色选择"
          required
          name="roleId"
          onChange={(_: any, d: any) => formik.setFieldValue('roleId', d.value)}
          error={formik.touched.roleId && formik.errors.roleId}
        />

        <Form.Field>
          <label>头像上传</label>
          <FileUpload
            btnColor="green"
            onLoad={url => setPreview(url)}
            ak='hotelNewHisApi'
            url={`his/user/upload`}
            onSuccess={res => {
              const { url } = res
              setHeadSculpture(url)
              setUploadStatus({ type: 'positive', message: '上传头像成功' })
            }}
            onError={message => {
              setUploadStatus({ type: 'negative', message })
            }}
          />
        </Form.Field>
      </Form.Group>
      {groupId && !bindingHotels.loading && (
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              绑定酒店 <b style={{ fontSize: '0.9em', color: colors.orange }}>默认全选</b>
            </label>
            <GroupHotelList
              groupId={groupId}
              onSelected={hotelList => formik.setFieldValue('hotelList', hotelList)}
              defaultHotels={bindingHotels.data || []}
            />
          </Form.Field>
        </Form.Group>
      )}
      {uploadStatus && (
        <Message positive={uploadStatus.type === 'positive'} negative={uploadStatus.type === 'negative'}>
          <strong>{uploadStatus.message}</strong>
        </Message>
      )}
      {(preview || user?.headSculpture) && (
        <Form.Group>
          <Form.Field>
            <label>头像预览</label>
            <Image
              src={preview || (user?.headSculpture && `${AVATAR_BASE_URL}/${user.headSculpture}?his_key=${HIS_KEY}`)}
              bordered
              rounded
              style={{ width: '16em', height: '16em', objectFit: 'contain' }}
            />
          </Form.Field>
        </Form.Group>
      )}
      <Button size="small" onClick={onClose} disabled={formik.isSubmitting}>
        取消
      </Button>
      <Button
        size="small"
        primary
        labelPosition="right"
        icon="checkmark"
        content={user ? '修改' : '创建'}
        type="submit"
        loading={formik.isSubmitting}
        disabled={!formik.isValid}
      />
    </Form>
  )
}

export default UserForm
