// HIS API IP和端口
export const HOST = "36.26.55.26"; // '36.26.55.26'  http://26d7-111-3-21-249.ngrok.io
export const PORT = "14339"; // '14339'
const needHttps = window.location.href.includes("seniin");

// 根据地址判断是否启动https // `https://api.seniin.com` // `http://${HOST}:${PORT}`
export const BASE_URL = needHttps ? `https://api.seniin.com` : `http://${HOST}:${PORT}`;
export const BASE_NEW_URL = "https://api.seniin.com/debugnewhisapi"
// WebSocket 主机地址
export const WEBSOCKET_ENDPOINT = needHttps ? `ws.seniin.com` : `36.26.55.26:3001`;

export const WEBSOCKET_URL = `${needHttps ? "https" : "http"}://${WEBSOCKET_ENDPOINT}/`;
export const WEBSOCKET_WSS = `${needHttps ? "wss" : "ws"}://${WEBSOCKET_ENDPOINT}/`;

export const PUBLIC_URL = `${BASE_URL}/uploads`;
export const AVATAR_BASE_URL = `${PUBLIC_URL}/avatars`;
export const HIS_KEY = "e295b7862ea544f08f42c4009a7dac04";


