import React, { useCallback, useState, useContext, useEffect } from 'react'
import { Table, Button, Icon, Message } from 'semantic-ui-react'
import ModalTrigger from '../../../components/ModalTrigger'
import UserForm from '../../../components/forms/UserForm'
import { ACTIVE_STATUS } from '../../../components/forms/UserForm'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../stores/root'
import { post } from '../../../axios'
import { colors } from '../../../constant/CSS'
import { ACTIVE_STATUS_COLORS } from '../../../constant/ALL'

export interface IUser {
  id: number
  userName: string
  name: string
  age?: number
  genderDisplay: string
  roleName: string
  roleId: number
  activeStatus: number
  accountType?: number
  loginStatus?: boolean
  mobileValidationCode?: string | null
  mobileNumber: string | null
  email: string | null
  validateEmailStatus?: boolean
  validationEmailCode?: string | null
  sign?: any
  activationCode?: string | null
  headSculpture: string | null
  birthday: Date
  firstName: string
  lastName: string
  groupId?: string
}


interface IProps {
  groupId: string
}

const MerchantUsers: React.FC<IProps> = observer(({ groupId }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<IUser | null>(null)
  const rootStore = useContext(RootStoreContext)
  const [users, setUsers] = useState<IUser[]>([])
  const [data, setData] = useState<IUser[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [usersError, setUsersError] = useState<string>('')

  useEffect(() => {
    setUsersLoading(true)
    post<IUser[]>(`his/User/query`, { page: 1, pageSize: 9999, groupId })
      .then(res => setUsers(res || []))
      .catch(err => setUsersError(err))
      .finally(() => setUsersLoading(false))
  }, [groupId])

  const resetPassword = useCallback((id: number, userName: string) => {
    post<any>('his/User/resetPassword', { id })
      .then(() => {
        rootStore.notificationStore.push({
          id: `reset-password-success-${id}`,
          color: 'green',
          header: '密码重置',
          content: `密码重置成功, ${userName}的新密码为Sh@888888`,
          expire: 30,
        })
      })
      .catch(() => {
        rootStore.notificationStore.push({
          id: `reset-password-fail-${id}`,
          color: 'red',
          header: '密码重置',
          content: '密码重置失败',
          expire: 30,
        })
      })
  }, [])

  useEffect(() => {
    if (users) {
      setData(users)
    }
  }, [users])

  if (usersLoading) return <span style={{ color: colors.grey }}>加载中...</span>
  if (usersError)
    return (
      <Message negative>
        <Message.Header>获取用户列表失败</Message.Header>
        {usersError && <p>{usersError}</p>}
      </Message>
    )

  return (
    <>
      <Table celled size="small" compact collapsing striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>用户</Table.HeaderCell>
            <Table.HeaderCell>账号</Table.HeaderCell>
            <Table.HeaderCell>角色</Table.HeaderCell>
            <Table.HeaderCell>联系电话</Table.HeaderCell>
            <Table.HeaderCell>状态</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                color="green"
                size="tiny"
                compact
                style={{ margin: '.1em' }}
                floated="right"
                onClick={() => setOpen(true)}
              >
                <Icon name="plus square outline" />
                新建用户
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((user: IUser, idx: number) => (
            <Table.Row key={user.id}>
              <Table.Cell>{idx + 1}</Table.Cell>
              <Table.Cell>{user.lastName + user.firstName}</Table.Cell>
              <Table.Cell>{user.userName}</Table.Cell>
              <Table.Cell>{user.roleName}</Table.Cell>
              <Table.Cell>{user.mobileNumber}</Table.Cell>
              <Table.Cell>
                <strong style={{ color: ACTIVE_STATUS_COLORS[user.activeStatus || 0] }}>
                  {ACTIVE_STATUS.find(s => s.value === user.activeStatus)?.text || '接口异常'}
                </strong>
              </Table.Cell>
              <Table.Cell>
                <Button size="tiny" compact style={{ margin: '.1em' }} inverted color="red">
                  <Icon name="trash alternate" />
                  删除
                </Button>
                <Button
                  size="tiny"
                  compact
                  style={{ margin: '.1em' }}
                  primary
                  onClick={() => {
                    setCurrentUser(user)
                    setOpen(true)
                  }}
                >
                  <Icon name="id card" />
                  修改
                </Button>
                <Button
                  size="tiny"
                  compact
                  style={{ margin: '.1em' }}
                  inverted
                  color="violet"
                  onClick={() => {
                    rootStore.confirmStore.show({
                      callback: () => {
                        resetPassword(user.id, user.userName)
                      },
                      header: '密码重置',
                      content: `是否确认将用户(${user.lastName}${user.firstName} - ${user.userName})的密码重置为 Sh@888888`,
                    })
                  }}
                >
                  <Icon name="key" />
                  重置密码
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <ModalTrigger
        header={`新建用户`}
        size="large"
        content={
          <UserForm
            onClose={() => {
              setOpen(false)
              setCurrentUser(null)
            }}
            groupId={groupId}
            user={currentUser}
            onError={message => {
              rootStore.notificationStore.push({
                id: !!currentUser ? `update-user-fail-${currentUser.id}` : 'create-user-fail',
                color: 'red',
                header: !!currentUser ? `${currentUser.userName}修改信息失败` : '创建用户失败',
                content: message,
                expire: 30,
              })
            }}
            onSuccess={user => {
              if (!!currentUser) {
                setData(data.map(d => (d.id === user.id ? user : d)))
              } else {
                setData(d => [...d, user])
              }
            }}
          />
        }
        open={!!open}
      />
    </>
  )
})

const MerchantUsersWrapper: React.FC<{}> = observer(() => {
  const [groupId, setGroupId] = useState<string | null>(null)
  const rootStore = useContext(RootStoreContext)
  return (
    <>
      {rootStore.authStore.user?.groupId ? (
        <MerchantUsers groupId={rootStore.authStore.user?.groupId} />
      ) : (
        <>
        </>
      )}
    </>
  )
})

export default MerchantUsersWrapper
