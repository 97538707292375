import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'
import { colors } from './CSS'
export const modeDict: { [key: number]: string } = {
  1: "卖价",
  2: "底价",
  3: "包房",
}
export const transferDict: { [key: number]: string } = {
  0: "确认后转账",
  1: "离店转账",
  2: "月结",
}

export const GENDER_OPTIONS = [
  { key: 'm', text: '男', value: 1 },
  { key: 'f', text: '女', value: 0 },
]

export const ACTIVE_STATUS = [
  { key: '1', text: '激活', value: 1 },
  { key: '2', text: '禁用', value: 2 },
  { key: '3', text: '黑名单', value: 3 },
]


export const PARENT_BED_INFO = [
  { label: '单人床', value: 362 },
  { label: '大床', value: 360 },
  { label: '双床', value: 361 },
  { label: '多张床', value: 363 },
]

export const SUB_BED_INFO = [
  { label: '特大床', value: 4061 },
  { label: '大床', value: 4060 },
  { label: '小型双人床', value: 4059 },
  { label: '太空舱', value: 851 },
  { label: '沙发床', value: 1144 },
  { label: '胶囊床', value: 852 },
  { label: '上下铺', value: 657 },
  { label: '单人床', value: 370 },
  { label: '炕', value: 369 },
  { label: '榻榻米', value: 368 },
  { label: '圆床', value: 366 },
  { label: '双人床', value: 365 },
  { label: '水床', value: 367 },
]

export const WEEK_MAP: { [key: number]: string } = {
  0: '周一',
  1: '周二',
  2: '周三',
  3: '周四',
  4: '周五',
  5: '周六',
  6: '周日',
}

export const BREAKFAST_DICT: Record<number, string> = {
  0: '无早',
  1: '单早',
  2: '双早',
}
export const getBreakFast = (bf: number) => BREAKFAST_DICT[bf] || `${bf}早`


export const cancelRuleDict = {
  0: '不可取消',
  1: '免费取消',
  3: '有条件取消',
}

export const ROW_CELLS = 7
export type AlignType = 'left' | 'right' | 'center'
export type VerticalAlignType = 'top' | 'middle' | 'bottom'

export type CellWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16

export interface RowType {
  cmp?: any
  name?: string
  align?: AlignType
  sortable?: boolean
  width?: CellWidth
  maxLength?: number
}

export type TableSizeType = 'small' | 'large'

export type CommonSizeType = 'big' | 'huge' | 'large' | 'massive' | 'mini' | 'small' | 'tiny'
export const CTRIP_STATUS_MAP: Record<string, SemanticCOLORS> = {
  已确认: 'blue',
  确认中: 'green',
  已取消: 'red',
  无房: 'orange',
  成交: 'green',
  订单未提交: 'yellow',
  在住: 'teal',
}

export const ZL_MAP: Record<string, { source: string, color: string }> = {
  0: { source: '盛吾游', color: colors.orange },
  1: { source: '美团', color: colors.teal },
  2: { source: '代理通', color: colors.olive },
  3: { source: '去哪儿', color: colors.blue },
  4: { source: '京东', color: colors.red },
  6: { source: '商旅', color: colors.pink },
}
export const targetOtaDict: Record<string, any> = {
  FZ: { name: '飞猪', color: 'orange', color2: colors.orange },
  SL: { name: '商旅', color: 'pink', color2: colors.pink },
  QUNAR: { name: '去哪儿', color: 'blue', color2: colors.blue },
  DLT: { name: '代理通', color: 'olive', color2: colors.olive },
  GD: { name: '高德', color: 'orange', color2: colors.orange },
  MT: { name: '美团', color: 'teal', color2: colors.teal },
}


export const ACTIVE_STATUS_COLORS: any = {
  1: colors.green,
  2: colors.yellow,
  3: colors.red,
  0: colors.purple,
}


export type UIColor =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'olive'
  | 'green'
  | 'teal'
  | 'blue'
  | 'violet'
  | 'purple'
  | 'pink'
  | 'brown'
  | 'black'
  | 'grey'

export const REQUEST_METHOD_MAP: any = {
  POST: 'green',
  GET: 'blue',
  DELETE: 'red',
}