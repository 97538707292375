import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { IZQHotel, IZQRP, RoomState } from '../../constant/New'
import { getUploadPrice1, getUploadPrice2 } from '../../utils/rp'

interface RpCalProps {
  hotel: IZQHotel
  rp: IZQRP | undefined
  cal: RoomState
  calOld: RoomState | undefined
  onChange: (s: any) => void
  onDelete: (s: any) => void
}
const RpCal: React.FC<RpCalProps> = ({
  hotel,
  rp,
  cal,
  calOld,
  onChange,
  onDelete,
}) => {
  const [data, setData] = useState<RoomState>({ ...cal })
  const setValue = (name: "price" | "status" | "basePrice" | "uploadPriceFZ" | "uploadPriceSL" | "uploadPriceMT" | "uploadPriceDLT" | "uploadPriceQUNAR", value: number) => {
    let newData = { ...data }
    if (name == "price") {
      newData.price = value
    } else if (name == "status") {
      newData.status = value
    } else if (name == "basePrice") {
      newData.basePrice = value
    } else if (name == "uploadPriceFZ") {
      newData.uploadPriceFZ = value
    } else if (name == "uploadPriceSL") {
      newData.uploadPriceSL = value
    } else if (name == "uploadPriceMT") {
      newData.uploadPriceMT = value
    } else if (name == "uploadPriceDLT") {
      newData.uploadPriceDLT = value
    } else if (name == "uploadPriceQUNAR") {
      newData.uploadPriceQUNAR = value
    }
    if (name == "price") {
      //设置最低价
      getUploadPrice1(newData, rp, hotel)
    }
    getUploadPrice2(newData, rp, hotel)
    let changeSet = new Set()
    //新的日历
    if (!calOld) {
      changeSet.add("new")
    } else {
      if (newData.price != calOld.price / 100) {
        changeSet.add("price")
      }
      if (newData.status != calOld.status / 100) {
        changeSet.add("status")
      }
      if (newData.basePrice != calOld.basePrice / 100) {
        changeSet.add("basePrice")
      }
      if (newData.uploadPriceFZ != calOld.uploadPriceFZ / 100) {
        changeSet.add("uploadPriceFZ")
      }
      if (newData.uploadPriceSL != calOld.uploadPriceSL / 100) {
        changeSet.add("uploadPriceSL")
      }
      if (newData.uploadPriceMT != calOld.uploadPriceMT / 100) {
        changeSet.add("uploadPriceMT")
      }
      if (newData.uploadPriceDLT != calOld.uploadPriceDLT / 100) {
        changeSet.add("uploadPriceDLT")
      }
      if (newData.uploadPriceQUNAR != calOld.uploadPriceQUNAR / 100) {
        changeSet.add("uploadPriceQUNAR")
      }
    }
    newData.change = Array.from(changeSet)
    setData(newData)
    onChange(newData)
  }
  return (
    <>
      <div style={{ margin: '.2em' }}>
        <label>售&emsp;价: </label>
        <input style={{ width: '40%', color: (data.change.indexOf("price") > -1) ? 'red' : '#000' }} value={data.price} onChange={(e: any) => { setValue("price", Number(e.target.value.trim())) }} />
      </div>
      {/* <div style={{ margin: '.2em' }}>
        <label>协议价: </label>
        {hotel.cooperationModel == 1 ? (//  1: "卖价", 2: "底价", 3: "包房",
          <label> {data.basePrice}</label>
        ) : (
          <input style={{ width: '40%', color: (data.change.indexOf("basePrice") > -1) ? 'red' : '#000' }} value={data.basePrice} onChange={(e: any) => { setValue("basePrice", Number(e.target.value.trim())) }} />
        )
        }
      </div>
       {hotel.targetOtaType.indexOf("FZ") > -1 && (
        <div style={{ margin: '.2em' }}>
          <label>飞&emsp;猪: </label>
          <input style={{ width: '25%', color: (data.change.indexOf("uploadPriceFZ") > -1) ? 'red' : '#000' }} value={data.uploadPriceFZ} onChange={e => { setValue("uploadPriceFZ", Number(e.target.value.trim())) }} />
          <label>实收金额:{data.uploadPriceFZBase}</label>
        </div>
      )}
      {hotel.targetOtaType.indexOf("MT") > -1 && (
        <div style={{ margin: '.2em' }}>
          <label>美&emsp;团: </label>
          <input style={{ width: '25%', color: (data.change.indexOf("uploadPriceMT") > -1) ? 'red' : '#000' }} value={data.uploadPriceMT} onChange={e => { setValue("uploadPriceMT", Number(e.target.value.trim())) }} />
          <label>实收金额:{data.uploadPriceMTBase}</label>
        </div>
      )}
      {hotel.targetOtaType.indexOf("QUNAR") > -1 && (
        <div style={{ margin: '.2em' }}>
          <label>去哪儿: </label>
          <input style={{ width: '25%', color: (data.change.indexOf("uploadPriceQUNAR") > -1) ? 'red' : '#000' }} value={data.uploadPriceQUNAR} onChange={e => { setValue("uploadPriceQUNAR", Number(e.target.value.trim())) }} />
          <label>实收金额:{data.uploadPriceQUNARBase}</label>
        </div>
      )}
      {hotel.targetOtaType.indexOf("DLT") > -1 && (
        <div style={{ margin: '.2em' }}>
          <label>代理通: </label>
          <input style={{ width: '25%', color: (data.change.indexOf("uploadPriceDLT") > -1) ? 'red' : '#000' }} value={data.uploadPriceDLT} onChange={e => { setValue("uploadPriceDLT", Number(e.target.value.trim())) }} />
          <label>实收金额:{data.uploadPriceDLTBase}</label>
        </div>
      )}
      {hotel.targetOtaType.indexOf("SL") > -1 && (
        <div style={{ margin: '.2em' }}>
          <label>商&emsp;旅: </label>
          <input style={{ width: '25%', color: (data.change.indexOf("uploadPriceSL") > -1) ? 'red' : '#000' }} value={data.uploadPriceSL} onChange={e => { setValue("uploadPriceSL", Number(e.target.value.trim())) }} />
          <label>实收金额:{data.uploadPriceSLBase}</label>
        </div>
      )} */}
      <div style={{ margin: '.2em', display: 'inline-block' }}>
        <b style={{ fontSize: '1.2em', marginRight: '1em' }}> {data.date} </b>
      </div>
      <div style={{ display: 'inline-block' }}>
        <Button size="tiny" style={{ marginLeft: '.2em' }} compact secondary onClick={() => { onDelete(data) }}> 删除</Button>
        <Button size="tiny" style={{ marginLeft: '.2em' }} compact inverted onClick={() => { setValue("status", data.status == 1 ? 0 : 1) }} color={data.status === 1 ? 'red' : 'green'}> {data.status === 1 ? '关闭' : '开启'} </Button>
      </div>
    </>
  )
}
export default RpCal