import * as Yup from 'yup'

export const pwdYup = (message: string) =>
  Yup.string()
    .min(8, '密码需大于等于8个字符!')
    .max(24, '密码需小于等于24个字符!')
    .required(message)

const shared = {
  username: Yup.string()
    .min(6, '用户名需大于等于8个字符!')
    .max(24, '用户名需小于等于24个字符!')
    .required('请输入登录账户'),
  password: pwdYup('请输入登录密码'),
}

export const loginSchema = Yup.object().shape({
  ...shared,
})

export const merchantLoginSchema = Yup.object().shape({
  ...shared,
})
