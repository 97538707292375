export const colors = {
  red: '#DB282890',
  orange: '#F2711CD0',
  yellow: '#FBBD08D0',
  olive: '#B5CC18D0',
  green: '#21BA45D0',
  teal: '#00B5ADD0',
  blue: '#2185D0D0',
  violet: '#6435C9D0',
  purple: '#A333C8D0',
  pink: '#E03997D0',
  brown: '#A5673FD0',
  grey: '#767676D0',
  black: '#1B1C1DD0',
}

export const BORDER = '1px solid rgba(34, 36, 38, 0.1)'