import React, { useContext, useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../stores/root'
import { usePageStuff4 } from '../../../utils/hooks'
import { Form, Select, Button } from 'semantic-ui-react'
import dayjs from 'dayjs'
import MyTable from '../../../components/MyTable'
import { post } from '../../../axios'
import { usePostCancel } from '../../../utils/hooks'
import DateInput from '../../../components/DateInput'
import { PAGE_SIZE_MAX } from '../../../constant/PAGE_SIZE'
import { IZQHotel } from '../../../constant/New'
interface D2 {
  date: string //日期
  orderCount: string //总数
  confirmCount: string //成功数
  zqNights: string //间夜
  costPrice: string //成本价
  refundOrderCount: string //退款订单数
  profit: string //利润
}

const PAGE_SIZE = 10

interface MerchantDailyReportStatisticalProps { }

const MerchantDailyReportStatistical: React.FC<MerchantDailyReportStatisticalProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const [hotels, setHotels] = useState<IZQHotel[]>([])
  const [currentHotel, setCurrentHotel] = useState<IZQHotel | undefined>(undefined)
  const [currentHotelId, setCurrentHotelId] = useState<string>('')
  const [hotelAdmin, setHotelAdmin] = useState<boolean>(false)
  const [start, setStart] = useState<string>('')
  const [end, setEnd] = useState<string>('')
  const errorHandler = useCallback(
    (message: string) => {
      rootStore.notificationStore.push({
        id: 'fetch-daily-statistics-fail',
        color: 'red',
        header: '读取日订单统计列表失败',
        content: message,
        expire: 30,
      })
    },
    [rootStore.notificationStore],
  )

  const { onPage, page, pageSize, data, total, loading, searchKeys, orderBy } = usePageStuff4<D2>(
    PAGE_SIZE,
    'merchant/StatisticDaily/QueryZQHotelOrderStatistical',
    {},
    {},
    errorHandler,
  )
  useEffect(() => {
    post<IZQHotel[]>(`zq/Hotel`, {
      groupId: rootStore.authStore.user?.groupId,
      hotelList: rootStore.authStore.user?.hotelList,
      page: 0,
      pageSize: PAGE_SIZE_MAX,
    }).then(res => {
      if (res) {
        setHotels(res)
      }
    })

    if (!rootStore.authStore.user?.hotelList?.toString()) {
      setHotelAdmin(true)
    }
  }, [])
  return (
    <>
      <Form>
        <Form.Group>
          <Form.Field>
            <Select
              clearable
              placeholder="选择酒店"
              search
              options={
                hotelAdmin
                  ? (hotels || []).map(d => ({
                    key: d.hotelId,
                    text: d.hotelName,
                    value: d.hotelId,
                  }))
                  : (hotels || [])
                    .map(d => ({
                      key: d.hotelId,
                      text: d.hotelName,
                      value: d.hotelId,
                    }))
                    .filter(({ value }) => rootStore.authStore.user?.hotelList?.includes(value))
              }
              onChange={(__: any, d: any) => {
                setCurrentHotel(hotels.find(h => h.hotelId === d.value))
                setCurrentHotelId(d.value)
              }}
            />
          </Form.Field>
          <Form.Field>
            <DateInput
              size="small"
              labeled="开始时间"
              onChange={e => {
                setStart(e.target.value)
              }}
            />
          </Form.Field>
          <Form.Field>
            <DateInput
              size="small"
              labeled="结束时间"
              onChange={e => {
                setEnd(e.target.value)
              }}
            />
          </Form.Field>
          <Form.Field>
            <Button
              primary
              disabled={hotelAdmin ? false : !currentHotel}
              onClick={() => {
                console.log('951')
                if (currentHotelId && currentHotelId.length > 0) {
                  onPage({
                    page: 1,
                    pageSize,
                    searchKeys: {
                      ...searchKeys,
                      hotelId: currentHotelId,
                      StartDate: start
                        ? start
                        : dayjs()
                          .subtract(9, 'day')
                          .format('YYYY-MM-DD'),
                      EndDate: end ? end : dayjs().format('YYYY-MM-DD'),
                    },
                    orderBy: [],
                  })
                }
              }}
            >
              生成统计
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
      {searchKeys && searchKeys.hotelId == '607674' && (
        <MyTable
          celled
          paged
          selectable
          size="small"
          data={data}
          page={page}
          count={total}
          pageSize={pageSize}
          loading={loading}
          onPage={(page, pageSize) => {
            console.log('111')
            onPage({ page, pageSize, searchKeys, orderBy })
          }}
          onSort={orderBy => {
            console.log('4444')
            onPage({ page, pageSize, searchKeys, orderBy })
          }}
          headers={[
            { name: '日期' },
            { name: '订单数' },
            { name: '成功数' },
            { name: '间夜' },
            { name: '成本价' },
            { name: '退款订单数' },
            { name: '利润' },
          ]}
          columns={[
            { name: 'date' },
            { name: 'orderCount' },
            { name: 'confirmCount' },
            { name: 'zqNights' },
            { name: 'costPrice' },
            { name: 'refundOrderCount' },
            { name: 'profit' },
          ]}
        />
      )}
      {searchKeys && searchKeys.hotelId != '607674' && (
        <MyTable
          celled
          paged
          selectable
          size="small"
          data={data}
          page={page}
          count={total}
          pageSize={pageSize}
          loading={loading}
          onPage={(page, pageSize) => {
            console.log(111)
            onPage({ page, pageSize, searchKeys, orderBy })
          }}
          onSort={orderBy => {
            console.log(222)
            onPage({ page, pageSize, searchKeys, orderBy })
          }}
          headers={[
            { name: '日期' },
            { name: '总数' },
            { name: '成功数' },
            { name: '间夜' },
            { name: '成本价' },
            { name: '退款订单数' },
          ]}
          columns={[
            { name: 'date' },
            { name: 'orderCount' },
            { name: 'confirmCount' },
            { name: 'zqNights' },
            { name: 'costPrice' },
            { name: 'refundOrderCount' },
          ]}
        />
      )}
    </>
  )
})

export default MerchantDailyReportStatistical
