import React, { useRef, ReactElement } from 'react'
import { Input, Button, Dropdown, Icon, Select, Radio, Label } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import _ from 'lodash'
import { usePointing, useFixDatepicker, usePostCancel } from '../../utils/hooks'
import DateInput from '../DateInput'
import { post } from '../../axios'
import { RootStoreContext } from '../../stores/root'
import { useContext, useEffect, useState } from 'react'
import { IZQHotel } from '../../constant/New'
import { UIColor } from '../../constant/ALL'
export type SearchArgType = {
  name: string
  label: string
  color?: UIColor
  select?: {
    name: string
    value: any
  }[]
  group?: {
    id: string
    name: any
  }[]
  radio?: {
    name: string
    value: any
  }[]
  value?: any
  isDateInput?: boolean
  isMonthly?: boolean
  isHotel?: boolean
}

interface IProps {
  searchArgs: SearchArgType[]
  dateRange?: boolean
  monthRange?: boolean
  group?: {
    id: string
    name: any
  }[]
  range?: number
  onSearch: (args: Record<string, string>) => void
  customizedSubmitBtn?: ReactElement
}

const SearchBar: React.FC<IProps> = ({ searchArgs, dateRange, monthRange, onSearch, range = 90, customizedSubmitBtn }) => {
  const rootStore = useContext(RootStoreContext)
  const hotelAll = usePostCancel<IZQHotel[]>(`zq/Hotel/queryBindingHotel`, {})
  const hotelAll2: any[] = []

  if (hotelAll.data) {
    const optimizeStore = rootStore.optimizeStore?.optimizeHotel
    for (let i = 0; i < optimizeStore.length; i++) {
      let index = -1;
      for (let l = 0; l < hotelAll.data.length; l++) {
        if (optimizeStore[i] == hotelAll.data[l].hotelId) {
          index = l
        }
      }
      if (index > -1) {
        hotelAll2.push(hotelAll.data[index])
      }
    }

    for (let i = 0; i < hotelAll.data.length; i++) {
      let bool = true;
      for (let l = 0; l < optimizeStore.length; l++) {
        if (optimizeStore[l] == hotelAll.data[i].hotelId) {
          bool = false
        }
      }
      if (bool) {
        hotelAll2.push(hotelAll.data[i])
      }
    }
  }
  const dpRef = useRef<any>()
  const pointing = usePointing(dpRef)
  const [hotels, setHotels] = useState<IZQHotel[]>([])
  const [hotelsRaw, setHotelsRaw] = useState<IZQHotel[]>([])
  const [hotelsRefresh, setHotelsRefresh] = useState<number>(0)
  const [gid, setGid] = useState<number | null>(null)
  const formik = useFormik({
    initialValues: {
      ..._.zipObject(
        searchArgs.map(a => a.name),
        searchArgs.map(a => (a.value ? a.value : '')),
      ),

      ...(dateRange ? { StartDate: '', EndDate: '' } : {}),
      groupId: '',
      hotelList: '',
    },
    onSubmit: values => {
      setHotelsRefresh(hotelsRefresh + 1)
      const arr = Object.entries(values)
      const r: { [key: string]: string } = {}
      arr.filter(ar => ar[1] !== '').forEach(([k, v]) => (r[k] = v as string))
      onSearch(r)
      if (r.hotelId) {
        rootStore.optimizeStore.update(r.hotelId)
      }
    },
  })

  useFixDatepicker(dpRef)

  useEffect(() => {
    if (gid) {
      post<IZQHotel[]>(`zq/Hotel`, {
        groupId: gid,
        hotelList: rootStore.authStore.user?.hotelList,
        page: 0,
        pageSize: 999,
        searchKeys: {
          groupId: gid,
        },
      }).then(res => {
        if (res) {
          setHotelsRaw(res)
        }
      })
    }
  }, [gid])

  useEffect(() => {
    const hotelAll2: any[] = []
    console.log("hotelAll2")
    if (hotelsRaw) {
      const optimizeStore = rootStore.optimizeStore?.optimizeHotel
      for (let i = 0; i < optimizeStore.length; i++) {
        let index = -1;
        for (let l = 0; l < hotelsRaw.length; l++) {
          if (optimizeStore[i] == hotelsRaw[l].hotelId) {
            index = l
          }
        }
        if (index > -1) {
          hotelAll2.push(hotelsRaw[index])
        }
      }

      for (let i = 0; i < hotelsRaw.length; i++) {
        let bool = true;
        for (let l = 0; l < optimizeStore.length; l++) {
          if (optimizeStore[l] == hotelsRaw[i].hotelId) {
            bool = false
          }
        }
        if (bool) {
          hotelAll2.push(hotelsRaw[i])
        }
      }
    }
    setHotels(hotelAll2)
  }, [hotelsRefresh, hotelsRaw])
  return (
    <form onSubmit={formik.handleSubmit}>
      {searchArgs.map(({ name, label, color, select, radio, isDateInput, isMonthly, group, isHotel }) =>
        select ? (
          <>
            {group}
            <Select
              search
              key={name}
              onChange={(_, d) => formik.setFieldValue(name, d.value)}
              as={Input}
              style={{ marginRight: '.2em', marginBottom: '.2em' }}
              size="mini"
              placeholder={`--${label}--`}
              options={[
                {
                  key: 'no-select',
                  text: `--${label}--`,
                  value: '',
                },
                ...select.map(s => ({
                  key: s.name,
                  text: s.name,
                  value: s.value,
                })),
              ]}
            />
          </>
        ) : radio ? (
          <Label key={name} as="div" style={{ marginRight: '.2em', marginBottom: '.2em' }}>
            {label} :&nbsp;&nbsp;
            {radio.map(r => (
              <React.Fragment key={r.name}>
                <Radio
                  label={r.name}
                  name={name}
                  style={{ fontSize: '1em', color: 'white' }}
                  value={r.value}
                  checked={r.value === (formik.values as any)[name]}
                  onChange={(_, d) => {
                    formik.setFieldValue(name, d.value)
                  }}
                />{' '}
              </React.Fragment>
            ))}
          </Label>
        ) : isDateInput ? (
          <DateInput
            key={name}
            size="mini"
            labeled={label}
            labelColor={color || 'blue'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name={name}
            isMonthly={isMonthly}
            value={(formik.values as any)[name]}
          />
        ) : group ? (
          <>
            <Select
              search
              key={name}
              as={Input}
              style={{ marginRight: '.2em', marginBottom: '.2em' }}
              size="mini"
              placeholder="选择组织"
              onChange={(_, d) => {
                console.log(d.value)
                setGid(parseInt(d.value + ''))
                formik.setFieldValue('groupId', d.value)
                formik.setFieldValue('hotelId', '')
              }}
              options={[
                {
                  key: 'no-select',
                  text: `--选择组织--`,
                  value: '',
                },
                ...group.map(s => ({
                  key: s.id,
                  text: s.name,
                  value: s.id,
                })),
              ]}
            />
            <Select
              search
              key={name + '_2'}
              as={Input}
              style={{ marginRight: '.2em', marginBottom: '.2em' }}
              size="mini"
              placeholder="选择酒店"
              onChange={(_, d) => {
                formik.setFieldValue('hotelId', d.value)
              }}
              options={[
                {
                  key: 'no-select',
                  text: `--选择酒店--`,
                  value: '',
                },
                ...hotels.map(s => ({
                  key: s.hotelId,
                  text: s.hotelName,
                  value: s.hotelId,
                })),
              ]}
            />
          </>
        ) : isHotel ? (
          <Dropdown
            placeholder="选择酒店"
            search
            selection
            disabled={(hotelAll2 || []).length === 0}
            options={[
              {
                key: 'no-select',
                text: `--选择酒店--`,
                value: '',
              },
              ...(hotelAll2 || []).map(d => ({
                key: d.hotelId,
                value: d.hotelId,
                text: d.hotelName,
                label: (
                  <Label color="blue" size="tiny">
                    {d.groupName}
                  </Label>
                ),
              })),
            ]}
            onChange={(_: any, d: any) => {
              formik.setFieldValue(name, d.value)
            }}
          />
        ) : (
          <Input
            key={name}
            size="mini"
            label={{ content: label, color: color || 'blue' }}
            icon={
              (formik.values as any)[name].trim() ? (
                <Icon name="close" color="grey" link onClick={() => formik.setFieldValue(name, '')} />
              ) : null
            }
            labelPosition="left"
            placeholder={`请输入${label}`}
            style={{ marginRight: '.2em', marginBottom: '.2em' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name={name}
            value={(formik.values as any)[name]}
            error={(formik.touched as any)[name] && (formik.errors as any)[name]}
          />
        ),
      )}

      {monthRange && (
        <SemanticDatepicker
          ref={dpRef}
          size="mini"
          locale={'zh-CN' as any}
          onChange={(e, data) => {

            if (monthRange && Array.isArray(data?.value)) {
              const fd = data.value.map(d => dayjs(d).format('YYYY-MM-DD'))
              if (fd.length > 1) {
                formik.setFieldValue('StartDate', fd[0])
                formik.setFieldValue('EndDate', fd[1])
              }
            } else {
              formik.setFieldValue('StartDate', '')
              formik.setFieldValue('EndDate', '')
            }
          }}
          type="range"
          datePickerOnly
          pointing={pointing}
          filterDate={date => {
            const sd = dayjs().subtract(range, 'day')
            if (dayjs(date).diff(dayjs()) > 0 || dayjs(date).diff(sd) < 0) {
              return false
            }
            return true
          }}
        />
      )}
      {dateRange && (
        <SemanticDatepicker
          ref={dpRef}
          size="mini"
          locale={'zh-CN' as any}
          onChange={(e, data) => {

            if (dateRange && Array.isArray(data?.value)) {
              const fd = data.value.map(d => dayjs(d).format('YYYY-MM-DD'))
              if (fd.length > 1) {
                formik.setFieldValue('StartDate', fd[0])
                formik.setFieldValue('EndDate', fd[1])
              }
            } else {
              formik.setFieldValue('StartDate', '')
              formik.setFieldValue('EndDate', '')
            }
          }}
          type="range"
          datePickerOnly
          pointing={pointing}
          filterDate={date => {
            const sd = dayjs().subtract(range, 'day')
            if (dayjs(date).diff(dayjs()) > 0 || dayjs(date).diff(sd) < 0) {
              return false
            }
            return true
          }}
        />
      )}


      {customizedSubmitBtn || (
        <Button
          content="搜索"
          size="mini"
          icon="arrow right"
          labelPosition="right"
          color="green"
          style={{ marginLeft: '.2em' }}
          type="submit"
        />
      )}
    </form>
  )
}

export default SearchBar
