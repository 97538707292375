import { post } from '../../axios'
import { rootStore } from '../../stores/root'

export const merchantOrderAction = (tid: string, storeStatus: string, remark?: string) => {
  const params: Record<string, string> = {
    tid,
    storeStatus,
  }

  if (storeStatus === '拒绝入住' && remark) {
    params['refundOrderRemark'] = remark
  }

  if (storeStatus === '拒绝退款' && remark) {
    params['refundPriceRemark'] = remark
  }

  return new Promise((resolve, reject) => {
    post<any>(`merchant/OrderWaiting/confirmOrder`, params)
      .then(() => {
        rootStore.notificationStore.push({
          id: `merchant-order-action-${storeStatus}-${tid}-success`,
          color: 'green',
          header: `${storeStatus}`,
          content: '操作成功',
          expire: 30,
        })
        resolve(true)
      })
      .catch(err => {
        rootStore.notificationStore.push({
          id: `merchant-order-action-${storeStatus}-${tid}-fail`,
          color: 'red',
          header: `${storeStatus} - 失败`,
          content: err,
          expire: 30,
        })
        reject(false)
      })
  })
}
