import _ from 'lodash'
import { IMenu } from '../stores/auth'

export interface IRawMenu {
  menuId: number
  menuName: string
  menuUrl: string
  parentId: number | null
  sort: number
}

export const transMenu = (menu: IRawMenu[]): IMenu[] =>
  menu.map(m => ({
    id: m.menuId,
    sort: m.sort,
    name: m.menuName,
    url: m.menuUrl,
    parent: m.parentId || 0,
    actived: false,
  }))

const getMenu = (menu: IMenu, left: IMenu[]): IMenu[] => {
  return left
    .filter(l => l.parent === menu.id)
    .sort((a, b) => a.sort - b.sort)
    .map(m => ({
      ...m,
      url: `${menu.url}${m.url}`,
    }))
    .map(m => ({
      ...m,
      sub: getMenu(m, left),
    }))
}

export const initStatus = (menu: IMenu[], index: number): IMenu[] => {
  let newMenu: IMenu[] = []
  if (menu.length > index) {
    newMenu = menu.map((m, i) => ({
      ...m,
      actived: i === index,
      sub: m.sub && i === index ? initStatus(m.sub, 0) : m.sub,
    }))
  }
  return newMenu
}

export const activeStatus = (menu: IMenu[], id: number): IMenu[] =>
  menu.map(m => ({
    ...m,
    actived: m.id === id,
    sub: activeStatus(m.sub || [], id),
  }))

export const generateMenu = (menu: IRawMenu[]) => {
  const top: IMenu[] = transMenu(menu)
    .filter(m => m.parent === 0)
    .sort((a, b) => a.sort - b.sort)

  const left: IMenu[] = transMenu(menu)
    .filter(m => m.parent !== 0)
    .sort((a, b) => a.sort - b.sort)

  return top.map(m => ({
    ...m,
    sub: getMenu(m, left),
  }))

}

export const getRootPathname = (pathname: string) => {
  const arr = pathname.split('/')
  if (arr.length > 1) {
    return `/${arr[1]}`
  }
  return '/'
}

export const getParentPathname = (pathname: string) => {
  const arr = pathname.split('/')
  if (arr.length > 2) {
    return arr.slice(0, -1).join('/')
  }
  return '/'
}

export const goto = (menu: IMenu): string => {
  if (menu.sub && menu.sub.length > 0) {
    return goto(menu.sub[0])
  } else {
    return menu.url
  }
}

export const swapId = (selected: boolean, id: number, ids: number[]) => {
  const temp = [...ids]
  if (selected) {
    const idx = temp.indexOf(id)
    if (idx >= 0) temp.splice(idx, 1)
  } else {
    temp.push(id)
  }
  return temp
}

export const swapIds = (select: boolean, ids: number[], menu: number[]) => {
  let temp = [...menu]
  if (select) {
    temp = _.difference(menu, ids)
  } else {
    temp = [...temp, ...ids]
  }
  return temp
}
