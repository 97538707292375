import { rootStore } from "../../stores/root"
import { addHeaderRow, deleteRow, updateRow } from "../table"
//新订单
export const listener_NEW_ORDER = (setData: Function, list: any[], payload: any) => {
    const { data: newOrder, shop } = JSON.parse(payload)
    addHeaderRow(setData, list, "tid", { ...newOrder, shop })
}
//确认有房（待处理页面）
export const listener_ORDER_CONFIRM = (setData: Function, list: any[], payload: any) => {
    const { shop, tid } = JSON.parse(payload)
    deleteRow(setData, list, "tid", tid)
}
//确认有房（已确认页面）
export const listener_ORDER_CONFIRM2 = (setData: Function, list: any[], payload: any, setConfirm: Function) => {
    const { shop, tid } = JSON.parse(payload)
    const exist = list.filter(old => old.tid === tid)
    if (exist) {
        setConfirm((r: number) => r + 1)
    }
}
//订单退款
export const listener_ORDER_REFUNDED = (setData: Function, list: any[], payload: any, setRefund: Function) => {
    const { shop: shop, tid } = JSON.parse(payload)
    const exist = list.filter(old => old.tid === tid)
    if (exist) {
        setRefund((r: number) => r + 1)
    }
}
//订单取消（待处理页面）
export const listener_ORDER_CANCEL = (setData: Function, list: any[], payload: any) => {
    const { shop: shop, tid } = JSON.parse(payload)
    deleteRow(setData, list, "tid", tid)
}

//申请取消
export const listener_ORDER_UPDATE = (setData: Function, list: any[], payload: any) => {
    const { shop: shop, tid } = JSON.parse(payload)
    updateRow(setData, list, "tid", {
        tid: tid,
        fzStatus: '申请退款',
    })
}
