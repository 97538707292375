import { BASE_URL } from './../config'
import { useState, useEffect, useCallback, useContext, useRef, useLayoutEffect } from 'react'
import axios, { AxiosError } from 'axios'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import { SortSchemaType } from '../components/MyTable'
import { IPageBody, } from '../stores/root'
import { page as goPage, getAxios, getToken, AxiosKey, post } from '../axios'
import { HIS_KEY } from '../config'



export const usePostCancel = <T>(url: string, body: Record<string, any> = {}, ak: AxiosKey = 'hotelNewHisApi') => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<AxiosError | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const source = axios.CancelToken.source()
    const ax = getAxios(ak)
    const loadData = async () => {
      try {
        setLoading(true)
        const res = await ax.post(`${url}?his_key=${HIS_KEY}`, body, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
        })
        const { code, data } = res.data
        if (code == '1') {
          setData(data)
        } else {
          setError(res.data)
        }
        setLoading(false)
      } catch (err) {
        if (axios.isCancel(err)) {
        } else {
          setError(err as any)
          setLoading(false)
        }
      }
    }

    loadData()
    return () => {
      source.cancel()
    }
  }, [])

  return { data, error, loading }
}

export const usePointing = (ref: any) => {
  const [point, setPoint] = useState<'left' | 'right' | 'top right' | 'top left'>('left')

  useEffect(() => {
    if (ref) {
      // eslint-disable-next-line react/no-find-dom-node
      const dpDom = ReactDOM.findDOMNode(ref.current) as Element
      if (dpDom) {
        const { x, y } = dpDom.getBoundingClientRect()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const w = document.querySelector('body')!.offsetWidth / 2
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const h = document.querySelector('body')!.offsetHeight / 2
        if (x < w && y < h) {
          setPoint('left')
        } else if (x < w && y >= h) {
          setPoint('top left')
        } else if (x > w && y < h) {
          setPoint('right')
        } else {
          setPoint('top right')
        }
      }
    }
  }, [ref])

  return point
}


export const usePageStuff = <T>(
  ps: number,
  url: string,
  params: { [key: string]: any },
  errorHandler?: (message: string) => void,
  ak: AxiosKey = 'hotelNewHisApi',
  isSearchKeys: boolean = true,
) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(ps)
  const [data, setData] = useState<T[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchKeys, setSearchKeys] = useState<{ [key: string]: string } | undefined>()
  const [orderBy, setOrderBy] = useState<SortSchemaType | undefined>([])

  const source = axios.CancelToken.source()

  const onPage = useCallback(
    ({ page, pageSize, searchKeys, orderBy }: IPageBody) => {
      setLoading(true)
      setPage(page)
      setPageSize(pageSize)
      setSearchKeys(searchKeys)
      setOrderBy(orderBy)
      var body: any = {}
      if (!isSearchKeys || ak == 'hotelNewHisApi') {
        body = {
          page,
          pageSize,
          ...searchKeys,
          orderBy,
          ...params,
        }
      } else {
        body = {
          page,
          pageSize,
          searchKeys,
          orderBy,
          ...params,
        }
      }
      goPage<T>(
        {
          url,
          body: body,
        },
        source,
        ak,
      )
        .then(res => {
          setData(res.data)
          setTotal(res.total)
          setPage(page)
          setPageSize(pageSize)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          if (errorHandler) {
            errorHandler(err.message || err.toString())
          }
        })
    },
    [ps, errorHandler, source, url],
  )

  useEffect(() => {
    onPage({ page: 1, pageSize: ps, searchKeys, orderBy: [] })
  }, [ps])

  useEffect(() => {
    return () => {
      if (source.token) source.cancel()
    }
  }, [])

  return {
    onPage,
    page,
    pageSize,
    data,
    setData,
    total,
    loading,
    searchKeys,
    orderBy,
  }
}

export const usePageStuff2 = <T>(
  ps: number,
  url: string,
  params: { [key: string]: any },
  defaultSearchKeys: { [key: string]: any },
  errorHandler?: (message: string) => void,
  ak: AxiosKey = 'hotelNewHisApi',
  isSearchKeys: boolean = true,
) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(ps)
  const [data, setData] = useState<T[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchKeys, setSearchKeys] = useState<{ [key: string]: string } | undefined>()
  const [orderBy, setOrderBy] = useState<SortSchemaType | undefined>([])

  const source = axios.CancelToken.source()

  const onPage = useCallback(
    ({ page, pageSize, searchKeys, orderBy }: IPageBody) => {
      setLoading(true)
      setPage(page)
      setPageSize(pageSize)
      setSearchKeys(searchKeys)
      setOrderBy(orderBy)
      var body: any = {}
      if (!isSearchKeys || ak == 'hotelNewHisApi') {
        body = {
          page,
          pageSize,
          ...searchKeys,
          orderBy,
          ...params,
        }
      } else {
        body = {
          url,
          body: {
            page,
            pageSize,
            searchKeys: {
              ...defaultSearchKeys,
              ...searchKeys,
            },
            orderBy,
            ...params,
          },
        }
      }
      goPage<T>(
        {
          url,
          body: body,
        },
        source,
        ak,
      )
        .then(res => {
          setData(res.data)
          setTotal(res.total)
          setPage(page)
          setPageSize(pageSize)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          if (errorHandler) {
            errorHandler(err.message || err.toString())
          }
        })
    },
    [ps, errorHandler, source, url],
  )

  useEffect(() => {
    onPage({ page: 1, pageSize: ps, searchKeys: params, orderBy: [] })
  }, [ps])

  useEffect(() => {
    return () => {
      if (source.token) source.cancel()
    }
  }, [])

  return {
    onPage,
    page,
    pageSize,
    data,
    setData,
    total,
    loading,
    searchKeys,
    orderBy,
  }
}


export const usePageStuff4 = <T>(
  ps: number,
  url: string,
  params: { [key: string]: any },
  defaultSearchKeys: { [key: string]: any },
  errorHandler?: (message: string) => void,
) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(ps)
  const [data, setData] = useState<T[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchKeys, setSearchKeys] = useState<{ [key: string]: string } | undefined>()
  const [orderBy, setOrderBy] = useState<SortSchemaType | undefined>([])

  const source = axios.CancelToken.source()

  const onPage = useCallback(
    ({ page, pageSize, searchKeys, orderBy }: IPageBody) => {
      setLoading(true)
      setPage(page)
      setPageSize(pageSize)
      setSearchKeys(searchKeys)
      setOrderBy(orderBy)
      goPage<T>(
        {
          url,
          body: {
            page,
            pageSize,
            ...defaultSearchKeys,
            ...searchKeys,
            orderBy,
            ...params,
          },
        },
        source,
      )
        .then(res => {
          setData(res.data)
          setTotal(res.total)
          setPage(page)
          setPageSize(pageSize)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          if (errorHandler) {
            errorHandler(err.message || err.toString())
          }
        })
    },
    [ps, errorHandler, source, url],
  )

  useEffect(() => {
    if (params.hotelId && params.hotelId.length > 0) {
      onPage({ page: 1, pageSize: ps, searchKeys: params, orderBy: [] })
    }
  }, [ps])

  useEffect(() => {
    return () => {
      if (source.token) source.cancel()
    }
  }, [])

  return {
    onPage,
    page,
    pageSize,
    data,
    setData,
    total,
    loading,
    searchKeys,
    orderBy,
  }
}

export const useFixDatepicker = (ref: React.MutableRefObject<any>) => {
  useEffect(() => {
    if (ref.current) {
      const MutationObserver =
        window.MutationObserver || (window as any).WebKitMutationObserver || (window as any).MozMutationObserver
      const mutationObserverSupport = !!MutationObserver
      if (mutationObserverSupport) {
        const observer = new MutationObserver(() => {
          document.querySelectorAll('[title^=星期').forEach(el => {
            el.textContent = (el as any).getAttribute('title').substr(2, 1)
          })
        })

        const options = {
          childList: true,
          subtree: true,
        }
        observer.observe(ref.current.el.current, options)
        return () => {
          observer.disconnect()
        }
      }
    }
  }, [ref])
}


export const useTitle2 = (title: string) => {
  useEffect(() => {
    document.title = title
  }, [])
}
