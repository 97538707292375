import io from 'socket.io-client'
import { getToken } from '../axios'
import { WEBSOCKET_WSS } from '../config'
import { rootStore } from '../stores/root'

let socket: SocketIOClient.Socket | undefined = undefined
export const getSocket = () => {
  if (!socket) {
    socket = io(WEBSOCKET_WSS, {
      extraHeaders: {
        Authorization: getToken(),
      },
    } as any)
    socket.on('connect', () => {
      rootStore.wsStore.status = true
    })
    socket.on('disconnect', () => {
      rootStore.wsStore.status = false
    })
  }
  return socket
}
