import React, { useContext } from 'react'
import { Menu, Accordion } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../stores/root'
import { useHistory, useLocation } from 'react-router-dom'
import { goto } from '../../utils/menu'

interface IProps { }

const AdminMenu: React.FC<IProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const history = useHistory()
  const location = useLocation()
  const defaultIdx = rootStore.authStore.sideMenu.findIndex(p => location.pathname.includes(p.url))

  return (
    <Accordion
      defaultActiveIndex={defaultIdx !== -1 ? defaultIdx : 0}
      style={{ width: 'auto' }}
      panels={rootStore.authStore.sideMenu.map(p => ({
        key: p.id,
        title: p.name,
        content: {
          content:
            p.sub && p.sub.length > 0 ? (
              <Menu secondary vertical>
                {p.sub.map(m => (
                  <Menu.Item
                    key={m.id}
                    as="a"
                    active={location.pathname === m.url}
                    onClick={() => {
                      rootStore.authStore.activeMenu(m.id)
                      history.push(goto(m))
                    }}
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp;{m.name}
                  </Menu.Item>
                ))}
              </Menu>
            ) : null,
        },
      }))}
      styled
    />
  )
})

export default AdminMenu
