import React, { useState, useContext, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../stores/root'
import { post } from '../../../axios'
import { Dimmer, Loader, Icon, Button, Table, Popup } from 'semantic-ui-react'
import { A } from '../../../components/A'
import RefundReasonCard from '../../../components/merchant/RefundReasonCard'
import { merchantOrderAction } from '../../../utils/merchant/hooks'
import { getSocket } from '../../../utils/ws'
import { colors } from '../../../constant/CSS'
import { OrderInfo } from '../../../constant/interface'
import { listener_NEW_ORDER, listener_ORDER_CANCEL, listener_ORDER_CONFIRM, listener_ORDER_UPDATE } from '../../../utils/order/listener'

const PAGE_SIZE = 999

interface WaitingProps { }

const Waiting: React.FC<WaitingProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<(OrderInfo)[]>([])
  const [popup1, setPopup1] = useState<string | null>(null)

  const loadData = useCallback(() => {
    setLoading(true)
    post<OrderInfo[]>(`merchant/OrderWaiting/getOrders`, {//
      page: 0,
      pageSize: PAGE_SIZE,
      groupId: rootStore.authStore.user?.groupId,
      hotelList: rootStore.authStore.user?.hotelList,
    }).then((data) => {
      if (data) {
        setData(data)
      }
    }).catch(err => {
      console.log(err)
      window.alert(err)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    loadData()
  }, [])


  useEffect(() => {
    getSocket().on('NEW_ORDER', (payload: any) => { listener_NEW_ORDER(setData, data, payload) })
    getSocket().on('ORDER_CONFIRM', (payload: any) => { listener_ORDER_CONFIRM(setData, data, payload) })
    getSocket().on('ORDER_CANCEL', (payload: any) => { listener_ORDER_CANCEL(setData, data, payload) })
    getSocket().on('ORDER_UPDATE', (payload: any) => { listener_ORDER_UPDATE(setData, data, payload) })
    return () => {
      getSocket().off('NEW_ORDER')
      getSocket().off('ORDER_CONFIRM')
      getSocket().off('ORDER_CANCEL')
      getSocket().off('ORDER_UPDATE')
    }
  }, [data])

  return (
    <>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted style={{ zIndex: 99, position: 'fixed', bottom: '50vh' }}>
          <Loader size="medium">数据加载中...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
      <Button
        compact
        size="tiny"
        color="twitter"
        onClick={() => {
          loadData()
        }}
      >
        <Icon name="refresh" />
        刷新订单列表
      </Button>
      <Table celled striped compact size="small" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell width="1">创建时间</Table.HeaderCell>
            <Table.HeaderCell width="1">订单号</Table.HeaderCell>
            <Table.HeaderCell width="2">酒店名称</Table.HeaderCell>
            <Table.HeaderCell width="2">房型名称</Table.HeaderCell>
            <Table.HeaderCell width="1">金额</Table.HeaderCell>
            <Table.HeaderCell width="2">入离时间</Table.HeaderCell>
            <Table.HeaderCell width="1">联系人</Table.HeaderCell>
            <Table.HeaderCell width="1">开票方</Table.HeaderCell>
            <Table.HeaderCell width="1">订单状态</Table.HeaderCell>
            <Table.HeaderCell width="1">商家状态</Table.HeaderCell>
            <Table.HeaderCell width="3">#</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>

          {data.map((row, idx) => {
            const item = row as OrderInfo
            const arr = item.createTime.toString().split('T')
            return (
              <Table.Row key={row.tid}>
                <Table.Cell>{idx + 1}</Table.Cell>
                <Table.Cell>
                  {arr[0]}
                  <br />
                  {arr[1]}
                </Table.Cell>
                <Table.Cell>
                  <b style={{ color: colors.black }}>{item.tid}</b>
                </Table.Cell>
                <Table.Cell>
                  <span style={{ color: colors.black }}>{item.hotelName}</span>
                </Table.Cell>
                <Table.Cell>
                  <b style={{ color: colors.black }}>
                    {item.stdRealRoomName}(
                    {item.breakfast == 0
                      ? '无早'
                      : item.breakfast == 1
                        ? '单早'
                        : item.breakfast == 2
                          ? '双早'
                          : `${item.breakfast}早`}
                    )
                  </b>
                </Table.Cell>
                <Table.Cell>
                  <>
                    <span style={{ color: colors.grey, fontSize: '0.8em' }}>卖价: </span>
                    <b style={{ color: colors.grey }}>{(item.payment / 100).toFixed(2)}</b>

                    <br />
                  </>

                  <span style={{ color: colors.grey, fontSize: '0.8em' }}>成本: </span>
                  <b style={{ color: colors.black }}>
                    {item.costPrice || 0}
                  </b>
                </Table.Cell>
                <Table.Cell>
                  {item.customerNames}
                  <br />
                  {item.checkinDate}至{item.checkoutDate}
                  <br />
                  {item.roomNumber}间{item.nights}晚
                </Table.Cell>
                <Table.Cell>
                  {item.contactName}
                  <br />
                  {item.contactPhone}
                </Table.Cell>
                <Table.Cell>
                  <span>{item.invoiceType === 2 ? '酒店开票' : '平台开票'}</span>
                </Table.Cell>
                <Table.Cell>
                  <span>{item.fzStatus}</span>
                </Table.Cell>
                <Table.Cell>
                  <span>{item.storeStatus}</span>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  <Popup
                    basic
                    open={popup1 === item.tid}
                    trigger={
                      <Button
                        size="mini"
                        compact
                        color="red"
                        inverted
                        onClick={() => setPopup1(item.tid)}
                        disabled={!!item.storeStatus}
                      >
                        拒绝接单
                      </Button>
                    }
                    on="click"
                    position="top right"
                  >
                    <RefundReasonCard
                      onSuccess={reason => {
                        merchantOrderAction(item.tid, '拒绝入住', reason).then(() => {
                          setPopup1(null)
                          setData(
                            data.map(d =>
                              d.tid === item.tid ? { ...d, refundOrderRemark: reason, storeStatus: '拒绝入住' } : d,
                            ),
                          )
                        })
                      }}
                      onCancel={() => setPopup1(null)}
                    />
                  </Popup>

                  <Button
                    size="tiny"
                    primary
                    compact
                    style={{ margin: '.1em' }}
                    disabled={!!item.storeStatus}
                    onClick={() => {
                      if (window.confirm('确认有房?') === true) {
                        merchantOrderAction(item.tid, '确认有房').then(() => {
                          setData(data.map(d => (d.tid === item.tid ? { ...d, storeStatus: '确认有房' } : d)))
                        })
                      }
                    }}
                  >
                    确认接单
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
})

const WaitingWrapper: React.FC<{}> = observer(() => {
  const rootStore = useContext(RootStoreContext)

  if (rootStore.authStore.user?.groupId === undefined) return <span style={{ color: colors.grey }}>加载中...</span>
  return <Waiting />
})
export default WaitingWrapper
