import React, { useState, useCallback, useContext, useEffect } from 'react'
import { Input, Button, Table, Pagination, Icon, Dropdown } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../stores/root'
import { usePageStuff } from '../../../utils/hooks'
import { OrderInfo } from '../../../constant/interface'
import { PAGE_SIZE_OPTIONS } from '../../../constant/PAGE_SIZE'
import AlipayRecord from '../../../components/forms/manage/AlipayRecord'
import ModalTrigger from '../../../components/ModalTrigger'
import { colors } from '../../../constant/CSS'

const PAGE_SIZE = 10

interface RefundedProps { }

const Refunded: React.FC<RefundedProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const [goto, setGoto] = useState<number>(0)
  const [tid, setTid] = useState<string>('')
  const [telephone, setTelephone] = useState<string>('')
  const [contactName, setContactName] = useState<string>('')

  const { onPage, page, pageSize, data, total, loading, searchKeys, orderBy, setData } = usePageStuff<OrderInfo>(
    PAGE_SIZE,
    `merchant/OrderRefunded/getOrders`,
    {
      groupId: rootStore.authStore.user?.groupId,
      hotelList: rootStore.authStore.user?.hotelList,
    },
    (message: string) => {
      rootStore.notificationStore.push({
        id: 'fetch-wx-refunded-orders-fail',
        color: 'red',
        header: '读取列表失败',
        content: message,
        expire: 30,
      })
    }
  )
  const getSearchKeys = useCallback(() => {
    const s: any = {}
    if (tid) {
      s['tid'] = tid
    }
    if (telephone) {
      s['telephone'] = telephone
    }
    if (contactName) {
      s['contactName'] = contactName
    }
    return s
  }, [tid, telephone, contactName, searchKeys])
  const [alipayRecord, setAlipayRecord] = useState<boolean>(false)
  const printOrder = (data: OrderInfo) => {
    let newWin: any = window.open(""); //将本页在新窗口中打开,方便打印完成后关闭
    newWin.document.body.innerHTML = `
    <div style=" position: relative;width: 700px;height: 800px;">
        <div style=" text-align: center;font-size: 50px;font-weight: 600;">盛吾游预订单</div>   
        <div style="padding:80px 20px 10px">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">客人姓名：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">${data.customerNames}</div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">住宿日期：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">${data.checkinDate}至${data.checkoutDate}</div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">预定房型：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">
              ${data.stdRealRoomName}
              (${data.breakfast == 0 ? '无早' : data.breakfast == 1 ? '单早' : data.breakfast == 2 ? '双早' : `${data.breakfast}早`})
            </div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">预订间数：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">${data.roomNumber}间${data.nights}晚</div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">房价：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">${!data.refundJson
        ? data?.basePrice || data.costPrice
        : JSON.parse(data.refundJson)
          .reduce((p: any, c: any) => p + c.price * c.rooms, 0)
          .toFixed(2)} 元</div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">付款方式：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">${data.is_checkout_transfer == "1" ? '离店后转账' : '确认后转账'}</div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">发票要求：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">
              ${data.invoiceType === 2 ? '酒店开票' : '由平台给客人开具发票，酒店无需向客人开具发票，但酒店需开具发票给平台，具体开票金额以月底账单为准。'}
            </div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">取消政策：</div>
            <div style="width:500px;display:inline-block;vertical-align: top;">不可取消</div>
        </div>
        <div style="padding:10px 20px ">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">特殊要求：</div>
        </div>
        <div style="position: absolute;  bottom: 0px;  right: 200px;">
            <div style="width:100px; text-align: right;display:inline-block;vertical-align: top;">确认人签名：</div>
        </div>
    </div>
    `; //将打印的部分覆盖新打开窗帘的body元素；

    newWin.print();//打印
    newWin.close();//关闭打印窗口
  }
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Input
          size="mini"
          label={{ content: '订单号', color: 'blue' }}
          labelPosition="left"
          placeholder={`请输入订单号`}
          style={{ marginRight: '.2em', marginBottom: '.2em' }}
          value={tid}
          onChange={(_, d) => setTid(d.value.trim())}
        />
        <Input
          size="mini"
          label={{ content: '手机号码', color: 'blue' }}
          labelPosition="left"
          placeholder={`请输入手机号码`}
          style={{ marginRight: '.2em', marginBottom: '.2em' }}
          value={telephone}
          onChange={(_, d) => setTelephone(d.value.trim())}
        />
        <Input
          size="mini"
          label={{ content: '联系人', color: 'blue' }}
          labelPosition="left"
          placeholder={`请输入联系人`}
          style={{ marginRight: '.2em', marginBottom: '.2em' }}
          value={contactName}
          onChange={(_, d) => setContactName(d.value.trim())}
        />
        <Button
          content="搜索"
          size="mini"
          icon="arrow right"
          labelPosition="right"
          color="green"
          style={{ marginLeft: '.2em' }}
          onClick={() => {
            onPage({ page: 1, pageSize, searchKeys: getSearchKeys() })
          }}
        />
      </div>
      <Table celled striped compact size="small" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell width="2">创建时间</Table.HeaderCell>
            <Table.HeaderCell width="2">订单号</Table.HeaderCell>
            <Table.HeaderCell width="2">酒店名称</Table.HeaderCell>
            <Table.HeaderCell width="2">房型名称</Table.HeaderCell>
            <Table.HeaderCell width="1">金额</Table.HeaderCell>
            <Table.HeaderCell width="3">退款间夜</Table.HeaderCell>
            <Table.HeaderCell width="2">联系人</Table.HeaderCell>
            <Table.HeaderCell width="1">开票方</Table.HeaderCell>
            <Table.HeaderCell width="1">订单状态</Table.HeaderCell>
            <Table.HeaderCell>#</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((data, idx) => {
            return <>
              <Table.Row>
                <Table.Cell>{idx + 1}</Table.Cell>
                <Table.Cell>
                  {data.createTime.toString().split('T')?.[0]}
                  <br />
                  {data.createTime.toString().split('T')?.[1]}
                </Table.Cell>
                <Table.Cell>
                  <b style={{ color: colors.black }}>{data.tid}</b>
                  <br />
                  {/*   <b style={{ color: colors.red }}>（商旅）</b> */}
                </Table.Cell>
                <Table.Cell>
                  <span style={{ color: colors.black }}>{data.hotelName}</span>
                </Table.Cell>
                <Table.Cell>
                  <b style={{ color: colors.black }}>
                    {data?.stdRealRoomName}(
                    {data.breakfast == 0
                      ? '无早'
                      : data.breakfast == 1
                        ? '单早'
                        : data.breakfast == 2
                          ? '双早'
                          : `${data.breakfast}早`}
                    )
                  </b>
                </Table.Cell>
                {rootStore.authStore.user?.roleId == 136 ? (
                  undefined
                ) : rootStore.authStore.user?.roleId == 131 ? (
                  <Table.Cell>
                    <b style={{ color: colors.green }}>{data.profit ? data.profit.toFixed(2) : ''}</b>
                    <br />
                  </Table.Cell>
                ) : (
                  <Table.Cell>
                    <span style={{ color: colors.grey, fontSize: '0.8em' }}>成本: </span>
                    <b style={{ color: colors.black }}>
                      {!data.refundJson
                        ? data?.basePrice || data.costPrice
                        : JSON.parse(data.refundJson)
                          .reduce((p: any, c: any) => p + c.price * c.rooms, 0)
                          .toFixed(2)}
                    </b>
                    <br />
                    {((data.costPrice ? data.costPrice : 0) - (data.zfbPrice ? data.zfbPrice : 0)) != 0 && data.isSend != "" ? (
                      <>
                        <span style={{ color: colors.grey, fontSize: '0.8em' }}>抵扣: </span>
                        <b style={{ color: colors.red }}>{((data.costPrice ? data.costPrice : 0) - (data.zfbPrice ? data.zfbPrice : 0))?.toFixed(2)}</b>
                      </>
                    ) : (
                      undefined
                    )
                    }
                  </Table.Cell>
                )}
                <Table.Cell>
                  {!data.refundJson ? (
                    <>
                      {data.checkinDate}至
                      {data.checkoutDate}
                      <br />
                      {data.roomNumber}间{data.nights}晚 <b style={{ color: colors.red }}>全退</b>
                    </>
                  ) : (
                    JSON.parse(data.refundJson)
                      .map((r: any) => r.date + ' ' + r.rooms + '间x' + r.price)
                      .join(', ')
                  )}
                </Table.Cell>
                <Table.Cell>
                  {data.contactName}
                  <br />
                  {data.contactPhone}
                </Table.Cell>
                <Table.Cell>
                  <span>{data.invoiceType === 2 ? '酒店开票' : '平台开票'}</span>
                </Table.Cell>
                <Table.Cell>{data.fzStatus}</Table.Cell>
                <Table.Cell>
                  {data.storeStatus == '已离店' ? (
                    data?.isSend == 'T' ? (
                      <>
                        <Button
                          size="tiny"
                          compact
                          color="orange"
                          inverted
                          style={{ margin: '.1em' }}
                          onClick={() => {
                            setAlipayRecord(true)
                          }}
                        >
                          支付宝回单
                        </Button>
                        <ModalTrigger size="large" open={!!alipayRecord}>
                          <AlipayRecord onClose={() => setAlipayRecord(false)} tid={data.tid} />
                        </ModalTrigger>
                      </>
                    ) : null
                  ) : (
                    null
                  )}
                  {data.hotelName.indexOf('千岛湖明豪国际度假酒店') != -1 ? (
                    <Button
                      size="tiny"
                      compact
                      color="orange"
                      inverted
                      style={{ margin: '.1em' }}
                      onClick={() => {
                        printOrder(data)
                      }}
                    >
                      打印订单
                    </Button>
                  ) : (
                    null
                  )}
                </Table.Cell>

              </Table.Row>
            </>
          })}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={11}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                <Pagination
                  activePage={page}
                  ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                  firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                  lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{ content: <Icon name="angle right" />, icon: true }}
                  totalPages={Math.ceil(total / pageSize)}
                  onPageChange={(_, d) => {
                    if (d && d.activePage) {
                      onPage({ page: d.activePage as number, pageSize, searchKeys: getSearchKeys() })
                    }
                  }}
                  siblingRange={2}
                  size="small"
                />

                <Dropdown
                  selection
                  options={PAGE_SIZE_OPTIONS}
                  compact
                  size="mini"
                  defaultValue={10}
                  style={{ marginLeft: '1em', width: 'auto', minWidth: '12em' }}
                  onChange={(_e, d) => {
                    onPage({ page: 0, pageSize: d.value as number, searchKeys: getSearchKeys() })
                  }}
                />

                <Input
                  label={{ basic: true, content: '页码' }}
                  labelPosition="left"
                  placeholder=""
                  action={{
                    color: 'green',
                    icon: 'arrow right',
                    onClick: () => {
                      onPage({ page: goto, pageSize, searchKeys: getSearchKeys() })
                    },
                  }}
                  size="small"
                  style={{ marginLeft: '1em', width: '5em' }}
                  type="number"
                  value={goto}
                  onChange={e => {
                    const r = parseInt(e.target.value)
                    if (Number.isNaN(r) || r < 1) {
                      setGoto(1)
                    } else {
                      setGoto(r)
                    }
                  }}
                />
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  )
})

const RefundedWrapper: React.FC<{}> = observer(() => {
  const rootStore = useContext(RootStoreContext)

  if (rootStore.authStore.user?.groupId === undefined) return <span style={{ color: colors.grey }}>加载中...</span>
  return <Refunded />
})
export default RefundedWrapper
