import React, { useContext, useState, useCallback } from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { merchantLoginSchema } from '../validationSchemas/loginSchema'
import { RootStoreContext } from '../stores/root'
import { observer } from 'mobx-react-lite'
import { post } from '../axios'

const MerchantLogin: React.FC = observer(() => {
  const [error, setError] = useState<string | null>(null)
  const rootStore = useContext(RootStoreContext)
  const [seconds, setSeconds] = useState<number>(0)
  const [vcodeShow, setVcodeShow] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      verificationCode: '',
    },
    onSubmit: values => {
      rootStore.authStore
        .login(values.username, values.password, values.verificationCode)
        .then(v => {
          if (v === '需要验证码') {
            setVcodeShow(true)
          } else if (v) {
            rootStore.authStore
              .me()
              .then((res: any) => {
                rootStore.authStore
                  .getMenu(res.roleId, res.groupId)
                  .then(menu => {
                    if (Array.isArray(menu)) {
                      rootStore.authStore.activeMenu(rootStore.authStore.menu[0].id)
                      window.location.href = menu[0]
                    }
                  })
                  .catch(err => {
                    console.log(err)
                    setError('登录失败')
                  })
              })
              .catch(err => {
                console.log(err)
                setError('登录失败')
              })
          }
        })
        .catch(err => {
          console.log(err)
          setError('登录失败')
        })
    },
    validationSchema: merchantLoginSchema,
  })

  const countDown = useCallback((ms: number) => {
    let maxtime = ms
    let timer: number | null = null
    setSeconds(ms)
    timer = setTimeout(function f() {
      if (maxtime >= 0) {
        setSeconds(--maxtime)
        timer = setTimeout(f, 1000)
      } else {
        setSeconds(0)
        if (timer) clearTimeout(timer)
        return
      }
    }, 1000)
  }, [])

  const getVCode = useCallback(() => {
    post(`authentication/verificationcode/${formik.values.username}`, {})
      .then(() => {
        countDown(60)
      })
      .catch(err => window.alert(err))
  }, [formik.values.username])

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="blue" textAlign="center">
          盛吾游商家登录系统
        </Header>
        <Form onSubmit={formik.handleSubmit}>
          <Segment>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="请输入账户"
              name="username"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              error={formik.touched.username && formik.errors.username}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="请输入密码"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              type="password"
              error={formik.touched.password && formik.errors.password}
            />

            {vcodeShow && (
              <Form.Group>
                <Form.Field>
                  <Form.Input
                    placeholder="输入验证码"
                    size="small"
                    name="verificationCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.verificationCode}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    disabled={seconds > 0}
                    content={seconds <= 0 ? '获取验证码' : `${seconds}秒后重发`}
                    size="small"
                    color="orange"
                    inverted
                    type="button"
                    onClick={() => {
                      getVCode()
                    }}
                  />
                </Form.Field>
              </Form.Group>
            )}

            <Button
              primary
              fluid
              size="large"
              type="submit"
              disabled={!formik.isValid}
            >
              商家登录
            </Button>
          </Segment>
        </Form>
        {!!error ? <Message color="red">{error}</Message> : null}
        {!!rootStore.errorStore.globalError ? <Message color="red">{rootStore.errorStore.globalError}</Message> : null}
      </Grid.Column>
    </Grid>
  )
})

export default MerchantLogin
