import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { IZQHotel } from '../../constant/New'
import { PAGE_SIZE_MAX } from '../../constant/PAGE_SIZE'
import { usePostCancel } from '../../utils/hooks'

interface GroupHotelListProps {
  groupId: any
  onSelected: (hotels: string[]) => void
  defaultHotels: IZQHotel[]
}

const GroupHotelList: React.FC<GroupHotelListProps> = ({ groupId, onSelected, defaultHotels }) => {
  const { loading, error, data } = usePostCancel<IZQHotel[]>('zq/Hotel', { groupId, page: 0, pageSize: PAGE_SIZE_MAX, })
  return (
    <Dropdown
      placeholder="选择绑定酒店"
      multiple
      search
      selection
      options={(data || []).map(d => ({
        key: d.hotelId,
        value: d.hotelId,
        text: d.hotelName,
      }))}
      onChange={(_: any, d: any) => {
        onSelected(d.value)
      }}
      defaultValue={defaultHotels.map(d => d.hotelId)}
    />
  )
}

export default GroupHotelList
