import React, { useEffect, useState } from 'react'
import { Button, Input } from 'semantic-ui-react'
import { post } from '../axios'

interface IDsData {
  oid: string
  breakfast: string
  checkin: string
  checkout: string
  customerNames: string
  hotelId: string
  hotelName: string
  nights: string
  payment: string
  realRoomId: string
  realRoomName: string
  roomNumber: string
  supplier: string
  createTime: string
  status: string // 已付款- ； 确认有房- 修改
}

interface WechatConfirmNumProps { }

const WechatConfirmNum: React.FC<WechatConfirmNumProps> = () => {
  const [data, setData] = useState<IDsData | null>(null)
  const [confirmNum, setConfirmNum] = useState<string>('')
  const [tid, setTid] = useState<string>('')

  useEffect(() => {
    const arr = window.location.search.split('?tid=')
    if (arr.length === 2) {
      setTid(arr[1]?.trim())
      post<IDsData>(`merchant/OrderWaiting/orderInfos`, {
        tid: arr[1],
      })
        .then(res => {
          if (res) {
            setData(res)
          } else {
            window.alert('参数不正确')
          }
        })
        .catch(err => window.alert(err))
    } else {
      window.alert('参数不正确')
    }
  }, [])

  return (
    <>
      <Input
        style={{ marginTop: '.5em' }}
        size="huge"
        fluid
        placeholder="请输入订单确认号"
        onChange={(__, d) => setConfirmNum(d.value.trim())}
      />

      <Button
        style={{ marginBottom: '.5em', marginTop: '.5em' }}
        size="huge"
        type="button"
        fluid
        primary
        disabled={!data || !tid || data?.status === '确认有房' || data?.status === '拒绝入住'}
        onClick={() => {
          if (window.confirm('是否确认有房?')) {
            post(`merchant/OrderWaiting/confirmOrder`, {
              oid: data?.oid,
              tid,
              confirmNum,
              supplier: data?.supplier,
              storeStatus: '确认有房',
            })
              .then(() => {
                if (confirmNum && confirmNum.trim())
                  post(
                    'order-confirmed',
                    {
                      tid,
                      hotelName: data?.hotelName,
                    },
                    'wechaty',
                  ).catch(err => window.alert(err))

                setData(data === null ? null : { ...data, status: '确认有房' })

                window.alert('确认成功!')
              })
              .catch(err => window.alert(err))
          }
        }}
      >
        确认
      </Button>


      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '0 1em', gridGap: '.2em' }}>
        <span>下单时间</span>
        <span>{data?.createTime}</span>
        <span>酒店</span>
        <span>{data?.hotelName}</span>
        <span>房型</span>
        <span>{data?.realRoomName}</span>
        <span>早餐</span>
        <span>
          {data?.breakfast == '0' ? '无早' : data?.breakfast == '1' ? '单早' : data?.breakfast == '2' ? '双早' : '多早'}
        </span>
        <span>入住人</span>
        <span>{data?.customerNames}</span>
        <span>入离时间</span>
        <span>
          {data?.checkin}至{data?.checkout}
        </span>
        <span>
          {data?.roomNumber}间{data?.nights}晚
        </span>
      </div>
    </>
  )
}

export default WechatConfirmNum
