import React from 'react'
import { Container, Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { colors } from '../constant/CSS'

interface IProps {
  status?: number
  message?: string
}

const Error: React.FC<IProps> = ({ status, message = '程序出错了!' }) => {
  const history = useHistory()

  return (
    <Container
      textAlign="center"
      fluid
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!!status && (
        <>
          <strong style={{ fontSize: '1.4em', color: colors.red }}>
            <i>&nbsp;{status}&nbsp;</i>
          </strong>
          &nbsp; &nbsp;{' '}
        </>
      )}
      <strong style={{ fontSize: '1.2em' }}>{message}</strong> &nbsp; &nbsp;{' '}
      <Button.Group>
        <Button content="返回上页" icon="left arrow" labelPosition="left" primary onClick={() => history.goBack()} />
        <Button
          content="去首页"
          icon="right arrow"
          labelPosition="right"
          color="green"
          onClick={() => history.push('/login')}
        />
      </Button.Group>
    </Container>
  )
}

export default Error
