import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Toggle = styled.div`
  position: fixed;
  top: 50vh;
  transform: translate(-70%, -60%);
  transition: 0.5s;
  &:hover {
    transform: translate(-10%, -60%);
  }
  z-index: 100;
  padding-left: 100px;
  padding: 0;
`

interface IProps {
  direction?: string
  onClick: () => void
}

const MenuToggle: React.FC<IProps> = ({ direction = 'left', onClick }) => {
  return (
    <Toggle>
      <Button color="teal" onClick={onClick} style={{ margin: 0, paddingLeft: '1em', paddingRight: '1em' }}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <Icon name={`hand point ${direction} outline` as any} />
      </Button>
    </Toggle>
  )
}

export default MenuToggle
