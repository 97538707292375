import React, { useCallback, useEffect, useState } from 'react'
import * as dateFns from 'date-fns'
import { zhCN } from 'date-fns/locale'
import { Icon, Modal, Button, Input, Checkbox, Form } from 'semantic-ui-react'
import { Wrapper, Header, Column, Days, Body } from '../../constant/DIV'
import { useFormik } from 'formik'
import { formatDate, generateDurations2, utilDayOfWeek } from '../../utils/date'
import dayjs from 'dayjs'
import PriceCalendarCal from './PriceCalendarCal'
import { targetOtaDict } from '../../constant/ALL'
import { IZQHotel, IZQRP, RoomState } from '../../constant/New'
import { post } from '../../axios'
import { sort_bubble } from '../../utils/new/sort'
import { getUploadPrice1, getUploadPrice2 } from '../../utils/rp'

interface IProps {
  rpCode: string
  hotel: IZQHotel
  onClose: () => void
}

const PriceCalendar: React.FC<IProps> = ({
  rpCode,
  onClose,
  hotel
}) => {
  const [rp, setRp] = useState<IZQRP>()
  const [roomStates, setRoomStates] = useState<RoomState[]>([])
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [refreshIndex, setRefreshIndex] = useState<{ index: number }>({ index: 0 })
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [weekAll, setWeekAll] = useState<boolean | undefined>(true)
  const [week1, setWeek1] = useState<boolean | undefined>(true)
  const [week2, setWeek2] = useState<boolean | undefined>(true)
  const [week3, setWeek3] = useState<boolean | undefined>(true)
  const [week4, setWeek4] = useState<boolean | undefined>(true)
  const [week5, setWeek5] = useState<boolean | undefined>(true)
  const [week6, setWeek6] = useState<boolean | undefined>(true)
  const [week7, setWeek7] = useState<boolean | undefined>(true)

  const [price, setPrice] = useState<number>()
  const [pricePoint, setPricePoint] = useState<number>()
  const [basePricePoint, setBasePricePoint] = useState<number>()
  const [trimPrice, setTrimPrice] = useState<number>()
  const [basePrice, setBasePrice] = useState<number>()
  const [trimBasePrice, setTrimBasePrice] = useState<number>()
  const [trimUploadPriceFZ, setTrimUploadPriceFZ] = useState<number>()
  const [trimUploadPriceSL, setTrimUploadPriceSL] = useState<number>()
  const [trimUploadPriceMT, setTrimUploadPriceMT] = useState<number>()
  const [trimUploadPriceQUNAR, setTrimUploadPriceQUNAR] = useState<number>()
  const [trimUploadPriceDLT, setTrimUploadPriceDLT] = useState<number>()

  const [dayType, setDayType] = useState<'全部' | '当月' | '指定日期' | ''>('全部')
  const [startDay, setStartDay] = useState<string>(formatDate(new Date()))
  const [endDay, setEndDay] = useState<string>(formatDate(new Date()))
  const initRp = useCallback(
    async () => {
      post<IZQRP>(`zq/Rp/GetRp/${rpCode}`, {}).then(res => {
        if (res) {
          setRp(res)
          //过滤无效日历
          let newRoomStates = res.roomStates.filter(d => d.date >= formatDate(new Date()))
          //转化格式
          newRoomStates = newRoomStates.map(rs => {
            let cal = {
              ...rs,
              price: Number(rs.price) / 100,
              basePrice: Number(rs.basePrice) / 100,
              uploadPriceFZ: Number(rs.uploadPriceFZ) / 100,
              uploadPriceSL: Number(rs.uploadPriceSL) / 100,
              uploadPriceMT: Number(rs.uploadPriceMT) / 100,
              uploadPriceDLT: Number(rs.uploadPriceDLT) / 100,
              uploadPriceQUNAR: Number(rs.uploadPriceQUNAR) / 100,
              change: [],
            }
            return getUploadPrice2(cal, rp, hotel)
          })
          setRoomStates(newRoomStates)
        }
      }).catch(err => window.alert(err))
    },
    [],
  )
  useEffect(() => {
    initRp()
  }, [])

  const formik = useFormik({
    initialValues: {
      price: 0,
      basePrice: 0,
      startDate: formatDate(new Date()),
      endDate: formatDate(new Date()),
      status: 1,
    },
    onSubmit: values => {
      const { price, status, startDate, endDate, basePrice } = values
      //获取天数
      const days = (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000 / 60 / 60 / 24
      if (new Date(startDate).getTime() < new Date(formatDate(new Date())).getTime()) {
        window.alert('开始日期必须在当天或当天之后')
      } else if (days < 0) {
        window.alert('开始日期必须小于等于结束日期')
      } else {
        //清空
        const newRoomStates = generateDurations2(days, new Date(startDate)).map(r => {
          let cal = getUploadPrice1({
            price: Number(price),
            basePrice: Number(basePrice),
            status,
            date: r[0],
            uploadPriceFZ: 0,
            uploadPriceSL: 0,
            uploadPriceMT: 0,
            uploadPriceDLT: 0,
            uploadPriceQUNAR: 0,
            change: [],
            uploadPriceFZBase: 0,
            uploadPriceSLBase: 0,
            uploadPriceMTBase: 0,
            uploadPriceDLTBase: 0,
            uploadPriceQUNARBase: 0,
          }, rp, hotel)
          return getUploadPrice2(cal, rp, hotel)
        })
        let newRoomStatesAll = sort_bubble({ list: newRoomStates, key: 'date' })
        setRoomStates(newRoomStatesAll)
        setRefreshIndex({ index: refreshIndex.index + 1 })
      }
    },
  })
  const addCal = (date: string) => {
    const exist = roomStates.find(d => d.date === date)
    const today = formatDate(new Date())
    //不存在并且大于等于今天
    if (!exist && date >= today) {
      setRoomStates([...roomStates, {
        date: date,
        price: 0,
        status: 1,
        basePrice: 0,
        uploadPriceFZ: 0,
        uploadPriceSL: 0,
        uploadPriceMT: 0,
        uploadPriceQUNAR: 0,
        uploadPriceDLT: 0,
        change: ["new"],
        uploadPriceFZBase: 0,
        uploadPriceSLBase: 0,
        uploadPriceMTBase: 0,
        uploadPriceQUNARBase: 0,
        uploadPriceDLTBase: 0,
      }])
    }
  }
  const nextMonth = () => {
    const nm = dateFns.addMonths(currentDate, 1)
    setCurrentDate(nm)
  }
  const prevMonth = () => {
    const pm = dateFns.subMonths(currentDate, 1)
    setCurrentDate(pm)
  }
  const gotoMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let cYear = currentDate.getFullYear()
    let cMonth = currentDate.getMonth()
    if (e.target.name === 'year') {
      cYear = parseInt(e.target.value)
    } else {
      cMonth = parseInt(e.target.value)
    }
    setCurrentDate(new Date(cYear, cMonth))
  }
  const set_price = function (hotel: IZQHotel, type: '设置售价' | '微调售价' | '设置协议价' | '微调协议价' | '微调飞猪' | '微调美团' | '微调去哪儿' | '微调代理通' | '微调商旅' | '设置卖价' | '设置底价') {

    if (!week1 && !week2 && !week3 && !week4 && !week5 && !week6 && !week7) {
      window.alert('请选择设置星期')
      return
    }

    const new_roomStates = roomStates.map(rs => {
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const ym = '' + year + '-' + (month < 10 ? '0' + month : month)
      //判断是否在选择的时间范围内
      if (dayType == '当月') {
        if (rs.date.indexOf(ym) == -1) {
          return rs
        }
      }
      if (dayType == '指定日期') {
        if (startDay > rs.date || rs.date > endDay) {
          return rs
        }
      }

      //判断是否是在筛选的范围内
      const dayOfWeek = utilDayOfWeek(rs.date)
      if (
        !(
          (week1 && dayOfWeek == '星期一') ||
          (week2 && dayOfWeek == '星期二') ||
          (week3 && dayOfWeek == '星期三') ||
          (week4 && dayOfWeek == '星期四') ||
          (week5 && dayOfWeek == '星期五') ||
          (week6 && dayOfWeek == '星期六') ||
          (week7 && dayOfWeek == '星期天')
        )
      ) {
        return rs
      }
      //修改日历数据
      let newRs = { ...rs }
      if (type == '设置售价' && price) {
        newRs.price = price
        getUploadPrice1(newRs, rp, hotel)
      } else if (type == '微调售价' && trimPrice) {
        newRs.price = Number((Number(rs.price + "") + Number(trimPrice)).toFixed(2))
        getUploadPrice1(newRs, rp, hotel)
      } else if (type == '设置协议价' && basePrice) {
        newRs.basePrice = basePrice
      } else if (type == '微调协议价' && trimBasePrice) {
        newRs.basePrice = Number((Number(rs.basePrice + "") + Number(trimBasePrice)).toFixed(2))
      } else if (type == '微调飞猪' && trimUploadPriceFZ) {
        newRs.uploadPriceFZ = Number((Number(rs.uploadPriceFZ + "") + Number(trimUploadPriceFZ)).toFixed(2))
      } else if (type == '微调美团' && trimUploadPriceMT) {
        newRs.uploadPriceMT = Number((Number(rs.uploadPriceMT + "") + Number(trimUploadPriceMT)).toFixed(2))
      } else if (type == '微调去哪儿' && trimUploadPriceQUNAR) {
        newRs.uploadPriceQUNAR = Number((Number(rs.uploadPriceQUNAR + "") + Number(trimUploadPriceQUNAR)).toFixed(2))
      } else if (type == '微调商旅' && trimUploadPriceSL) {
        newRs.uploadPriceSL = Number((Number(rs.uploadPriceSL + "") + Number(trimUploadPriceSL)).toFixed(2))
      } else if (type == '微调代理通' && trimUploadPriceDLT) {
        newRs.uploadPriceDLT = Number((Number(rs.uploadPriceDLT + "") + Number(trimUploadPriceDLT)).toFixed(2))
      } else if (type == '设置卖价' && Number(newRs.basePrice + "") > 0) {
        newRs.price = Number((Number(newRs.basePrice + "") / Number(pricePoint)).toFixed(2))
        getUploadPrice1(newRs, rp, hotel)
      } else if (type == '设置底价' && Number(newRs.price + "") > 0) {
        newRs.basePrice = Number((Number(newRs.price + "") * Number(basePricePoint)).toFixed(2))
      }
      return getUploadPrice2(newRs, rp, hotel)
    })
    //修改日历数据
    setRoomStates(new_roomStates)
    setRefreshIndex({ index: refreshIndex.index + 1 })
  }
  const checkPrice = () => {
    let err_str = ''
    for (const item of roomStates) {
      if (item.basePrice == 0) {
        err_str += "协议价未设置；";
      }
      if (item.basePrice > item.uploadPriceFZBase && hotel.targetOtaType.indexOf("FZ") > -1) {
        err_str += "飞猪价格过低；";
      }
      if (item.basePrice > item.uploadPriceSLBase && hotel.targetOtaType.indexOf("SL") > -1) {
        err_str += "商旅价格过低；";
      }
      if (item.basePrice > item.uploadPriceMTBase && hotel.targetOtaType.indexOf("MT") > -1) {
        err_str += "美团价格过低；";
      }
      if (item.basePrice > item.uploadPriceQUNARBase && hotel.targetOtaType.indexOf("QUNAR") > -1) {
        err_str += "去哪儿价格过低；";
      }
      if (item.basePrice > item.uploadPriceDLTBase && hotel.targetOtaType.indexOf("DLT") > -1) {
        err_str += "代理通价格过低；";
      }
    }
    return err_str
  }
  const save = () => {
    //检查价格
    let err_str = checkPrice()
    if (err_str != '') {
      window.alert(err_str)
      return
    }
    if (window.confirm('确定提交当前日历设置并上传售卖?') == true) {
      let newRoomStates = sort_bubble({ list: roomStates, key: 'date' })
      const inventoryPrices = newRoomStates.map(rs => ({
        date: rs.date,
        status: rs.status,
        price: Number((parseFloat(rs.price + '') * 100).toFixed(0)),
        basePrice: Number((parseFloat(rs.basePrice + '') * 100).toFixed(0)),
        uploadPriceDLT: Number((parseFloat(rs.uploadPriceDLT + '') * 100).toFixed(0)),
        uploadPriceFZ: Number((parseFloat(rs.uploadPriceFZ + '') * 100).toFixed(0)),
        uploadPriceMT: Number((parseFloat(rs.uploadPriceMT + '') * 100).toFixed(0)),
        uploadPriceSL: Number((parseFloat(rs.uploadPriceSL + '') * 100).toFixed(0)),
        uploadPriceQUNAR: Number((parseFloat(rs.uploadPriceQUNAR + '') * 100).toFixed(0)),
        uploadPriceMTBase: Number((parseFloat(rs.uploadPriceMTBase + '') * 100).toFixed(0)),
        uploadPriceSLBase: Number((parseFloat(rs.uploadPriceSLBase + '') * 100).toFixed(0)),
      }))

      const data = {
        HotelId: hotel.hotelId,
        IsMerchant: true,
        ratePlans: [{
          ratePlanCode: rp?.ratePlanCode,
          priceList: inventoryPrices,
        }]
      }
      console.log(data)
      post<any>(`zq/hotel/updateRpPrice`, data).then(res => {
        window.alert("更新成功")
        onClose()
      }).catch(err => {
        window.alert(err)
      })
    }
  }
  const header = () => {
    const currentYear = dayjs()
    const yearOptions = [
      currentYear.subtract(1, 'year').format('YYYY'),
      currentYear.format('YYYY'),
      currentYear.add(1, 'year').format('YYYY'),
    ]
    return (
      <Header>
        <div>
          <div style={{ display: 'inline-block', margin: '0 50px 0 0 ' }}>
            <div style={{ display: 'inline-block' }}>
              <Icon name="arrow left" onClick={prevMonth} style={{ cursor: 'pointer' }} />
            </div>
            <div style={{ display: 'inline-block' }}>
              <select value={currentDate.getFullYear()} onChange={gotoMonth} name="year" style={{ width: '120px', textAlign: 'center' }}>
                {yearOptions.map(yo => (
                  <option key={yo} value={yo}> {yo} 年 </option>
                ))}
              </select>
              <select value={currentDate.getMonth()} onChange={gotoMonth} name="month" style={{ width: '80px', textAlign: 'center' }}>
                {Array.from({ length: 12 }, (_, idx) => (
                  <option key={idx} value={idx}> {idx + 1} 月</option>
                ))}
              </select>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Icon name="arrow right" onClick={nextMonth} style={{ cursor: 'pointer' }} />
            </div>
          </div>
          {/* 修改全部  */}
          <div style={{ display: 'inline-block', margin: '0 50px 0 0 ' }} >
            <Checkbox label={"修改全部日历数据"} checked={dayType == '全部' ? true : false} onChange={(_, d) => {
              if (d.checked) {
                setDayType('全部')
              } else {
                setDayType('')
              }
            }}
            />
          </div>
          {/* 修改当前月份  */}
          <div style={{ display: 'inline-block', margin: '0 50px 0 0 ' }} >
            <Checkbox label={"修改当前日历数据"} checked={dayType == '当月' ? true : false} onChange={(_, d) => {
              if (d.checked) {
                setDayType('当月')
              } else {
                setDayType('')
              }
            }}
            />
          </div>
          {/* 修改指定日期  */}
          <div style={{ display: 'inline-block', margin: '0 50px 0 0 ' }} >
            <Checkbox label={"修改指定日期日历数据"} checked={dayType == '指定日期' ? true : false} onChange={(_, d) => {
              if (d.checked) {
                setDayType('指定日期')
              } else {
                setDayType('')
              }
            }}
            />
            <Input name="startDate" size="mini" label="开始日期" type="date" defaultValue={startDay} onChange={(_, d) => { setStartDay(d.value) }} style={{ margin: ' 0 1em' }} />
            <Input name="endDate" size="mini" label="结束日期" type="date" defaultValue={endDay} onChange={(_, d) => { setEndDay(d.value) }} style={{ margin: ' 0 1em' }} />
          </div>
        </div>
        {/* 在日期中筛选 */}
        <div>
          <label>筛选：</label>
          <Checkbox label={<label>星期一</label>} style={{ margin: '5px' }} checked={week1} onChange={(_, d) => { setWeek1(d.checked) }} />
          <Checkbox label={<label>星期二</label>} style={{ margin: '5px' }} checked={week2} onChange={(_, d) => { setWeek2(d.checked) }} />
          <Checkbox label={<label>星期三</label>} style={{ margin: '5px' }} checked={week3} onChange={(_, d) => { setWeek3(d.checked) }} />
          <Checkbox label={<label>星期四</label>} style={{ margin: '5px' }} checked={week4} onChange={(_, d) => { setWeek4(d.checked) }} />
          <Checkbox label={<label>星期五</label>} style={{ margin: '5px' }} checked={week5} onChange={(_, d) => { setWeek5(d.checked) }} />
          <Checkbox label={<label>星期六</label>} style={{ margin: '5px' }} checked={week6} onChange={(_, d) => { setWeek6(d.checked) }} />
          <Checkbox label={<label>星期天</label>} style={{ margin: '5px' }} checked={week7} onChange={(_, d) => { setWeek7(d.checked) }} />
          <Checkbox label={<label>全选</label>} style={{ margin: '5px' }} checked={weekAll}
            onChange={(_, d) => {
              setWeekAll(d.checked)
              if (d.checked) {
                setWeek1(d.checked)
                setWeek2(d.checked)
                setWeek3(d.checked)
                setWeek4(d.checked)
                setWeek5(d.checked)
                setWeek6(d.checked)
                setWeek7(d.checked)
              } else {
                setWeek1(d.checked)
                setWeek2(d.checked)
                setWeek3(d.checked)
                setWeek4(d.checked)
                setWeek5(d.checked)
                setWeek6(d.checked)
                setWeek7(d.checked)
              }
            }}
          />
        </div>
        <div style={{ margin: '10px 0  0 0 ', width: '100%' }}>
          <div style={{ display: 'inline-block', margin: '0 10px 0 0 ' }}>
            <div>
              <Form>
                <Form.Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Form.Field>
                    <Input size="mini" placeholder="输入售价" style={{ marginRight: '1em' }} onChange={(_, d) => { setPrice(Number(d.value)) }} />
                  </Form.Field>
                  <Form.Field>
                    <Button size="mini" compact color="teal" content="设置售价" onClick={() => { set_price(hotel, '设置售价') }} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
            <div>
              <Form>
                <Form.Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Form.Field>
                    <Input size="mini" placeholder="输入微调售价" style={{ marginRight: '1em' }} onChange={(_, d) => { setTrimPrice(Number(d.value)) }} />
                  </Form.Field>
                  <Form.Field>
                    <Button size="mini" compact color="teal" content="微调售价" onClick={() => { set_price(hotel, '微调售价') }} />
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </Header >
    )
  }

  const daysOfWeek = () => {
    const dateFormat = 'EEEE'
    const days = []
    const startDate = dateFns.startOfWeek(currentDate)
    for (let i = 0; i < 7; i++) {
      days.push(
        <Column key={i} style={{ textAlign: 'center' }}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: zhCN })}
        </Column>,
      )
    }
    return <Days>{days}</Days>
  }

  const cells = () => {
    const monthStart = dateFns.startOfMonth(currentDate)
    const monthEnd = dateFns.endOfMonth(monthStart)
    const startDate = dateFns.startOfWeek(monthStart)
    const endDate = dateFns.endOfWeek(monthEnd)
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    const today = formatDate(new Date())
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        let formattedDate = dateFns.format(day, dateFormat)
        const cloneDay = day
        const cd = dateFns.format(day, 'yyyy-MM-dd')
        const cal = roomStates.find(d => d.date === cd)
        const calOld = rp?.roomStates.find(d => d.date === cd)
        days.push(
          <div
            className={`column cell ${!dateFns.isSameMonth(day, monthStart)
              ? 'disabled'
              : dateFns.isSameDay(day, selectedDate)
                ? 'selected'
                : ''
              }`}
            style={{ display: 'block' }}
            key={cd + '_' + refreshIndex.index}
            onClick={() => setSelectedDate(cloneDay)}
            onDoubleClick={() => {
              addCal(cd)
            }}
          >
            {cal && dateFns.isSameMonth(day, monthStart) && (
              <div className="events" style={{ width: '100%', display: 'block', border: cal.change.length > 0 ? '2px solid red' : '0px solid' }}>
                <PriceCalendarCal hotel={hotel} rp={rp} cal={cal} calOld={calOld} key={refreshIndex.index}
                  onChange={s => {
                    console.log(s)
                    setRoomStates(roomStates.map(rs => (rs.date === s.date ? s : rs)))
                  }} onDelete={s => {
                    setRoomStates(roomStates.filter(rs => rs.date !== s.date))
                  }} />
              </div>
            )}
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>,
        )
        day = dateFns.addDays(day, 1)
      }
      rows.push(
        <div className="row" key={day.toString()}> {days} </div>
      )
      days = []
    }
    return <Body>{rows}</Body>
  }

  return (
    <>
      <Modal.Header>
        {hotel.hotelName} {rp?.roomName}
      </Modal.Header>
      <Modal.Content>
        <Wrapper>
          <div>{header()}</div>
          <div>{daysOfWeek()}</div>
          <div key={refreshIndex.index}>{cells()}</div>
        </Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <form onSubmit={formik.handleSubmit} style={{ float: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center' }}   >
          <Input onChange={formik.handleChange} name="price" size="small" label="售价" placeholder="售价" style={{ marginRight: '1em' }} defaultValue={formik.values.price} />
          <Input onChange={formik.handleChange} name="basePrice" size="small" label="协议价" placeholder="协议价" style={{ marginRight: '1em' }} defaultValue={formik.values.basePrice} />
          <Input onChange={formik.handleChange} name="startDate" size="mini" label="开始日期" type="date" style={{ marginRight: '1em' }} defaultValue={formik.values.startDate} />
          <Input onChange={formik.handleChange} name="endDate" size="mini" label="结束日期" type="date" style={{ marginRight: '1em' }} defaultValue={formik.values.endDate} />
          <Button size="small" type="submit" color="twitter" style={{ marginRight: '1em' }}>
            批量设置
          </Button>
          <Button size="small" type="button" inverted color="red" onClick={() => setRoomStates([])} style={{ marginRight: '1em' }} >
            清空所有
          </Button>
        </form>
        <Button size="small" positive onClick={() => save()}  >
          提交并上传
        </Button>
        <Button size="small" onClick={onClose}>
          取消
        </Button>
      </Modal.Actions>
    </>
  )
}

export default PriceCalendar
