import React, { useEffect, useState, useRef } from 'react'
import { Message } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { UIColor } from '../constant/ALL'

interface IProps {
  color: UIColor
  header: string
  content: string
  onDismiss?: () => void
  expire?: dayjs.Dayjs
}

const Notification: React.FC<IProps> = ({ color, header, content, onDismiss, expire }) => {
  const [countdown, setCountdown] = useState<number>(0)
  const intervalRef = useRef<any>(undefined)

  useEffect(() => {
    if (expire) {
      setCountdown(dayjs(expire).diff(dayjs(), 'second'))

      intervalRef.current = setInterval(() => {
        setCountdown(countdown => countdown - 1)
      }, 1000)

      return () => clearInterval(intervalRef.current)
    }
  }, [expire])

  useEffect(() => {
    if (expire && countdown < 0 && onDismiss) {
      onDismiss()
    }
  }, [countdown, expire, onDismiss])

  return (
    <Message color={color} style={{ maxWidth: '25vw', minWidth: 200 }} onDismiss={onDismiss}>
      <Message.Header>{expire ? `${header} (${countdown})` : header}</Message.Header>
      <p>{content.toString()}</p>
    </Message>
  )
}

export default Notification
