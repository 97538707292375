import { post } from './../axios'
import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import { RootStore } from './root'
import { generateMenu, activeStatus, IRawMenu } from '../utils/menu'
import { IUser } from '../constant/interface'

export interface IMenu {
  id: number
  sort: number
  name: string
  url: string
  parent: number | null
  label?: string
  actived: boolean
  sub?: IMenu[]
}

export class AuthStore {
  rootStore: RootStore

  @persist @observable token = ''
  @persist @observable username = ''
  @persist('list') @observable menu: IMenu[] = []
  @persist('list') @observable sideMenu: IMenu[] = []
  @persist('list') @observable menuUrls: string[] = []
  @persist('object') @observable user: IUser | null = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action login(username: string, password: string, verificationCode?: string) {
    this.rootStore.errorStore.globalError = ''
    return new Promise((resolve, reject) =>
      this.rootStore
        .getAxios()
        .post(`his/login/gettoken`, {
          username,
          password,
          verificationCode,
        })
        .then(res => {
          console.log(res)
          const { code, data } = res.data
          if (code === 1) {
            this.token = data
            this.username = username
            resolve(data)
          }
          reject(false)
        })
        .catch(err => {
          this.rootStore.errorStore.globalError = err.message
          reject(false)
        }),
    )
  }

  @action logout() {
    this.token = ''
    this.menu = []
    this.sideMenu = []
    this.menuUrls = []
    this.user = null
  }

  @action me() {
    return new Promise((resolve, reject) =>
      post<IUser>('his/user/queryUserInfo', {})
        .then(res => {
          this.user = res
          resolve(res)
        })
        .catch(err => {
          reject(err)
        }),
    )
  }

  @action getMenu(id: number, groupId?: number) {
    return new Promise((resolve, reject) =>
      post<IRawMenu[]>('his/menu/queryRoleId', { id, groupId: groupId ? groupId : "" })
        .then(res => {
          if (res) {
            this.menu = generateMenu(res)

            const t: string[] = []
            this.menu.forEach(m => {
              if (!m.sub || m.sub.length === 0) {
                t.push(m.url)
              } else {
                m.sub.forEach(l2 => {
                  if (l2.sub && l2.sub.length > 0) {
                    l2.sub.forEach(l3 => t.push(l3.url))
                  }
                })
              }
            })
            this.menuUrls = t
            console.log({
              t: this.menuUrls,
            })
            resolve(t)
          } else {
            reject('获取目录失败')
          }
        })
        .catch(err => {
          reject(err.message || err.toString())
        }),
    )
  }

  @action activeMenu(id: number) {
    this.menu = activeStatus(this.menu, id)
    const temp = this.menu.filter(m => m.actived && m.parent === 0)
    if (temp && temp.length > 0) {
      this.sideMenu = temp[0].sub || []
    }
  }
}
