import React from 'react'
import { Modal, Button, Form, Input } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { useFormik } from 'formik'
import { post } from '../../../axios'
import * as Yup from 'yup'
import { IZQHotel } from '../../../constant/New'
interface IProps {
    hotel: IZQHotel | "新增酒店"
    onClose: () => void
    onUpdateHtml: () => void
}

const validationSchema = Yup.object().shape({
    telephone: Yup.string().required('请输入酒店电话'),
})
const HotelForm: React.FC<IProps> = observer(({ onClose, hotel, onUpdateHtml }) => {
    const formik = useFormik({
        initialValues: {
            telephone: hotel == "新增酒店" ? "" : hotel.telephone || "",
            noticePhone: hotel == "新增酒店" ? "" : hotel.noticePhone || "",
            smsPhone: hotel == "新增酒店" ? "" : hotel.smsPhone || "",
        },
        onSubmit: values => {
            let hotelId = hotel == "新增酒店" ? "" : hotel.hotelId;
            post('merchant/Hotel/updatePhone', { ...values, hotelId }).then(() => {
                window.alert('保存成功')
                formik.resetForm()
                onClose()
                onUpdateHtml()
            }).catch(err => {
                window.alert(err)
            }).finally(() => formik.setSubmitting(false))
        },
        validationSchema: validationSchema,
    })
    return (
        <>
            <Modal.Header>
                {hotel == "新增酒店" ? "新增酒店" : `修改酒店信息---${hotel.hotelName}`}
            </Modal.Header>
            <Modal.Content scrolling>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <Form.Field
                            control={Input}
                            label="酒店电话"
                            placeholder="酒店电话"
                            required
                            name="telephone"
                            value={formik.values.telephone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.telephone && formik.errors.telephone}
                        />
                        <Form.Field
                            control={Input}
                            label="通知电话"
                            placeholder="通知电话"
                            name="noticePhone"
                            value={formik.values.noticePhone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.noticePhone && formik.errors.noticePhone}
                        />
                        <Form.Field
                            control={Input}
                            label="短信电话"
                            placeholder="短信电话"
                            name="smsPhone"
                            value={formik.values.smsPhone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.smsPhone && formik.errors.smsPhone}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button size="small" onClick={onClose} >
                    关闭
                </Button>
                <Button
                    size="small"
                    primary
                    content="保存"
                    onClick={() => {
                        if (window.confirm('确认保存?') === true) {
                            formik.submitForm()
                        }
                    }}
                    loading={formik.isSubmitting}
                    disabled={!formik.isValid}
                />
            </Modal.Actions>
        </>
    )
})

export default HotelForm
