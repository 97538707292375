import { persist } from 'mobx-persist'
import { observable, action } from 'mobx'
import { RootStore } from './root'
import dayjs from 'dayjs'
import { UIColor } from '../constant/ALL'

export interface INotificationBase {
  id: string
  color: UIColor
  header: string
  content: string
}

export interface INotificationExpireDate extends INotificationBase {
  expire?: dayjs.Dayjs
}

export interface INotificationExpireSeconds extends INotificationBase {
  expire?: number
}

export class NotificationStore {
  rootStore: RootStore

  @persist('list') @observable notifications: INotificationExpireDate[] = []
  // @observable notifications: INotificationExpireDate[] = []

  @observable wsStatus = false

  @action push(notification: INotificationExpireSeconds) {
    const idx = this.notifications.findIndex(n => n.id === notification.id)
    if (idx < 0) {
      this.notifications.push({
        ...notification,
        expire: notification.expire ? dayjs().add(notification.expire, 'second') : undefined,
      })
    } else {
      const tempN = [...this.notifications]
      tempN.splice(idx, 1, {
        ...notification,
        expire: notification.expire ? dayjs().add(notification.expire, 'second') : undefined,
      })
      this.notifications = tempN
    }
  }

  @action clear() {
    this.notifications = []
  }

  @action delete(id: string) {
    this.notifications = this.notifications.filter(n => n.id !== id)
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action changeWsStatus(status: boolean) {
    this.wsStatus = status
  }
}
