import React from 'react'
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'

interface IProps {
  size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive'
  labeled?: string
  labelColor?: SemanticCOLORS
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  defaultValue?: string
  style?: React.CSSProperties
  value?: string
  onBlur?: (event: any) => void
  name?: string
  isMonthly?: boolean
}

const DateInput: React.FC<IProps> = ({
  size = 'mini',
  labeled,
  labelColor = 'blue',
  style,
  onChange,
  onBlur,
  value,
  name,
  isMonthly,
}) => {
  return (
    <div
      className={`ui ${size} left ${labeled ? 'labeled' : ''}  input`}
      style={style ? style : { marginRight: '.2em', marginBottom: '.2em' }}
    >
      {labeled && <div className={`ui ${labelColor} label`}>{labeled}</div>}
      <input
        type={isMonthly ? 'month' : 'date'}
        style={{ padding: '.25em' }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
      />
    </div>
  )
}

export default DateInput
