import { addInterceptors } from './../axios'
import { BASE_NEW_URL } from './../config'
import { createContext } from 'react'
import { create } from 'mobx-persist'
import { ErrorStore } from './error'
import { AuthStore } from './auth'
import axios from 'axios'
import { NotificationStore } from './notification'
import { PushStore } from './push'
import { ConfirmStore } from './confirm'
import { optimizeStore } from './optimize'
import { NewOrderStore } from './newOrder'
import { WsStore } from './ws'
import { ReminderOrderStore } from './reminderOrder'

export interface IPageBody {
  searchKeys?: { [key: string]: string } // 模糊查询
  matchkeys?: { [key: string]: string } // 精确查询
  orderBy?: { [key: string]: 'asc' | 'desc' }[]
  page: number
  pageSize: number
}
export interface IPageParams {
  url: string
  body: IPageBody
}

const hydrate = create({
  storage: localStorage,
  jsonify: true,
})

export class RootStore {

  errorStore = new ErrorStore(this)

  authStore = new AuthStore(this)

  optimizeStore = new optimizeStore(this)

  notificationStore = new NotificationStore(this)

  pushStore = new PushStore(this)

  confirmStore = new ConfirmStore(this)


  newOrderStore = new NewOrderStore(this)




  wsStore = new WsStore(this)

  reminderOrderStore = new ReminderOrderStore(this)


  source = axios.CancelToken.source()

  constructor() {
    hydrate('auth', this.authStore)
    hydrate('optimize', this.optimizeStore)
    hydrate('notification', this.notificationStore)
    hydrate('push', this.pushStore)
    hydrate('newOrder', this.newOrderStore)
    hydrate('reminderOrder', this.reminderOrderStore)
  }

  getAxios = () => {
    const instance = axios.create({
      baseURL: `${BASE_NEW_URL}/api/`,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    addInterceptors(instance)
    return instance
  }
  cancelPage = () => this.source.cancel()
}

export const rootStore = new RootStore()

export const RootStoreContext = createContext(rootStore)
