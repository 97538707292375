import { observable, action } from 'mobx'
import { RootStore } from './root'

export interface IConfirm {
  confirmButton?: string
  cancelButton?: string
  header?: string
  content?: string
  loading?: boolean
  callback: () => void
}

export class ConfirmStore {
  rootStore: RootStore

  @observable flag: IConfirm | null = null

  @action show(c: IConfirm) {
    this.flag = c
  }

  @action cancel() {
    this.flag = null
  }

  @action async confirm() {
    if (this.flag) {
      this.flag = { ...this.flag, loading: true }
      await this.flag.callback()
      this.flag = null
    }
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
}
