import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Button, Table, Checkbox, Icon, Input } from 'semantic-ui-react'
import dayjs from 'dayjs'
import { getDays } from '../../utils/new/sort'
import { post } from '../../axios'
import { Wrapper } from '../../constant/DIV'
import { IZQHotel, IZQRP } from '../../constant/New'
interface IProps {
  hotel: IZQHotel
  onClose: () => void
}

const SkuCalendar: React.FC<IProps> = ({
  hotel,
  onClose,
}) => {
  const [selectColAll, setSelectColALL] = useState<any[]>([])
  const [size, setSize] = useState<number>(30)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<{ min: number, max: number, page: number }>({ min: 0, max: 30, page: 1 })
  const [selectRow, setSelectRow] = useState<{ [key: string]: any }>({})
  const [selectCell, setSelectCell] = useState<{ [key: string]: any }>({})
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [refreshIndex, setRefreshIndex] = useState<{ index: number }>({ index: 0 })
  const [rpList, setRpList] = useState<IZQRP[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    post<IZQRP[]>(`zq/Rp/queryRpListByHotelId`, { HotelId: hotel.hotelId })
      .then(rps => {
        console.log(rps)
        if (rps) {
          let size = 0
          //获取最大日期和最小日期
          let max_day = dayjs().format('YYYY-MM-DD')
          let now_day = dayjs().format('YYYY-MM-DD')
          for (let rp of rps) {
            for (let item of rp.roomStates) {
              //获取最大日期
              if (max_day < item.date) {
                max_day = item.date
              }
              //过滤无效数据
              rp.roomStates = rp.roomStates.filter(d => d.date >= now_day)
            }
          }
          //获取列数
          size = getDays(dayjs().format('YYYY-MM-DD'), max_day) + 1
          //初始化列选择器属性
          let new_selectCol = []
          for (let i = 0; i < size; i++) {
            new_selectCol.push({
              day: dayjs().add(i, 'day').format('YYYY-MM-DD'),
              title: dayjs().add(i, 'day').format('MM-DD'),
              checked: false
            })
          }
          setSelectColALL(new_selectCol)
          setCount(new_selectCol.length)
          //初始化行选择器属性
          let new_selectRow: { [key: string]: any } = {}
          for (let rp of rps) {
            new_selectRow[rp.ratePlanCode] = false
          }
          setSelectRow(new_selectRow)

          //初始化单元格选择器
          let new_selectCell: { [key: string]: any } = {}
          for (let rp of rps) {
            let list: any[] = []
            let dayMap: { [key: string]: any } = {}
            //数组转对象
            for (let item of rp.roomStates) {
              dayMap[item.date] = item.status == 1
            }
            for (let i = 0; i < size; i++) {
              let day = dayjs().add(i, 'day').format('YYYY-MM-DD')
              if (dayMap[day] == undefined) {
                list.push({
                  day,
                  is_checked: false,
                  checked: dayMap[day]
                })
              } else {
                list.push({
                  day,
                  is_checked: true,
                  checked: dayMap[day]
                })
              }
            }
            new_selectCell[rp.ratePlanCode] = list
          }
          setSelectCell(new_selectCell)
          //设置rp
          setRpList(rps)
        }
      })
  }, [])
  const initPage = useCallback(
    async (page: number) => {
      let max = Math.ceil(count / (size * 1.0))
      if (page < 1) {
        page = 1
      }
      if (page > max) {
        page = max
      }
      setPage({ min: (page - 1) * size, max: page * size, page })
      console.log({ min: (page - 1) * size, max: page * size, page })
    },
    [size, count],
  )
  //全选
  return (
    <>
      <Modal.Header key={refreshIndex.index}>{hotel.hotelName}{page.min}-{page.max}-{size}</Modal.Header>
      <Modal.Content>
        <Wrapper>
          <div style={{ position: 'sticky', left: '0px', margin: '10px 15px 5px' }}>
            <div style={{ display: 'inline-block' }}>
              <Icon name="arrow left" onClick={() => { initPage(page.page - 1) }} style={{ cursor: 'pointer' }} />
            </div>
            <div style={{ display: 'inline-block' }}>
              <Input size="mini" label="显示天数" type="number" defaultValue={size} onChange={(_, d) => {
                setSize(Number(d.value))
              }}
                onBlur={() => {
                  initPage(1)
                }}
                style={{ margin: ' 0 1em' }} />
            </div>
            <div style={{ display: 'inline-block' }}>
              <Icon name="arrow right" onClick={() => { initPage(page.page + 1) }} style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <Table size="small" striped compact selectable collapsing celled sortable key={refreshIndex.index}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ position: 'sticky', left: '0px', zIndex: 2, background: '#fff' }}>
                  <Checkbox style={{ width: '150px' }} label={<label>房型</label>} checked={selectAll} onChange={(_, d) => {
                    for (let rp of rpList) {
                      for (let item of rp.roomStates) {
                        item.status = d.checked ? 1 : 0
                      }
                    }
                    for (let i = 0; i < selectColAll.length; i++) {
                      selectColAll[i].checked = d.checked
                    }
                    for (let key in selectRow) {
                      selectRow[key] = d.checked
                    }
                    for (let key in selectCell) {
                      for (let i = 0; i < selectCell[key].length; i++) {
                        selectCell[key][i].checked = d.checked
                      }
                    }
                    console.log(rpList)
                    setRpList(rpList)
                    setSelectColALL(selectColAll)
                    setSelectRow(selectRow)
                    setSelectCell(selectCell)
                    setSelectAll(d.checked ? true : false)
                    setRefreshIndex({ index: refreshIndex.index + 1 })
                  }} />
                </Table.HeaderCell>
                <Table.HeaderCell style={{ position: 'sticky', left: '150px', width: "150px", zIndex: 2, background: '#fff' }}>
                  <span style={{ width: '150px', display: 'inline-block' }} >rp</span>
                </Table.HeaderCell>
                {(selectColAll && (selectColAll || []).filter((item, index) => index >= page.min && index < page.max).map((g: any, idx: any) => {
                  return (
                    <Table.HeaderCell><Checkbox label={<label>{g.title}</label>} checked={g.checked} onChange={(_, d) => {
                      for (let rp of rpList) {
                        for (let item of rp.roomStates) {
                          if (item.date == g.day) {
                            item.status = d.checked ? 1 : 0
                          }
                        }
                      }
                      for (let key in selectCell) {
                        for (let i = 0; i < selectCell[key].length; i++) {
                          if (selectCell[key][i].day == g.day) {
                            selectCell[key][i].checked = d.checked
                          }
                        }
                      }
                      selectColAll[idx].checked = d.checked
                      setRpList(rpList)
                      setSelectColALL(selectColAll)
                      setSelectCell(selectCell)
                      setRefreshIndex({ index: refreshIndex.index + 1 })
                    }} /></Table.HeaderCell>
                  )
                })
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body >
              {(rpList || []).map((rpItem: IZQRP, idx: any) => {
                return (
                  <>
                    <Table.Row>
                      <Table.Cell style={{ position: 'sticky', left: '0px', width: "150px", zIndex: 2, background: '#fff' }}>
                        <Checkbox checked={selectRow[rpItem.ratePlanCode]} label={<label>{rpItem.roomName}</label>} onChange={(_, d) => {
                          for (let rp of rpList) {
                            if (rp.ratePlanCode == rpItem.ratePlanCode) {
                              for (let item of rp.roomStates) {
                                item.status = d.checked ? 1 : 0
                              }
                            }
                          }
                          for (let key in selectCell) {
                            if (key == rpItem.ratePlanCode) {
                              for (let i = 0; i < selectCell[key].length; i++) {
                                selectCell[key][i].checked = d.checked
                              }
                            }
                          }
                          selectRow[rpItem.ratePlanCode] = d.checked
                          setRpList(rpList)
                          setSelectRow(selectRow)
                          setSelectCell(selectCell)
                          setRefreshIndex({ index: refreshIndex.index + 1 })
                        }} />
                      </Table.Cell>
                      <Table.Cell style={{ position: 'sticky', left: '150px', width: "150px", zIndex: 2, background: '#fff' }}>
                        <span style={{ width: '150px', display: 'inline-block' }} >
                          {rpItem.breakFastNum == 0 ? '无早' : rpItem.breakFastNum == 1 ? '单早' : rpItem.breakFastNum == 2 ? '双早' : '多早'}|
                          {rpItem.cancelPolicy == 0 ? '不可取消' : '有条件取消'}
                        </span>
                      </Table.Cell>
                      {selectCell[rpItem.ratePlanCode].filter((item2: any, index2: number) => index2 >= page.min && index2 < page.max).map((g2: any, idx2: any) => {
                        return (
                          <Table.Cell>
                            {g2.is_checked && (
                              <>
                                <Checkbox checked={g2.checked} onChange={(_, d) => {
                                  for (let rp of rpList) {
                                    if (rp.ratePlanCode == rpItem.ratePlanCode) {
                                      for (let item of rp.roomStates) {
                                        if (item.date == g2.day) {
                                          item.status = d.checked ? 1 : 0
                                        }
                                      }
                                    }
                                  }
                                  for (let key in selectCell) {
                                    if (key == rpItem.ratePlanCode) {
                                      for (let i = 0; i < selectCell[key].length; i++) {
                                        if (selectCell[key][i].day == g2.day) {
                                          selectCell[key][i].checked = d.checked
                                        }
                                      }
                                    }
                                  }
                                  setRpList(rpList)
                                  setSelectCell(selectCell)
                                  setRefreshIndex({ index: refreshIndex.index + 1 })
                                }} />
                              </>
                            )}
                          </Table.Cell>
                        )
                      })
                      }
                    </Table.Row>
                  </>
                )
              }
              )}
            </Table.Body>
          </Table>
        </Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" primary disabled={loading} loading={loading} onClick={() => {
          setLoading(true)
          let ratePlans: any[] = []
          for (let rp of rpList) {
            ratePlans.push({
              ratePlanCode: rp.ratePlanCode,
              priceList: rp.roomStates
            })
          }
          const data = {
            HotelId: hotel.hotelId,
            IsMerchant: true,
            ratePlans: ratePlans
          }
          post<any>(`zq/hotel/updateRpPrice`, data).then(res => {
            window.alert("更新成功")
            onClose()
            setLoading(false)
          }).catch(err => {
            window.alert(err)
            setLoading(false)
          })
        }}>
          提交
        </Button>
        <Button size="small" onClick={onClose}>
          取消
        </Button>
      </Modal.Actions>
    </>
  )
}

export default SkuCalendar
