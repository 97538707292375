export const PAGE_SIZE_OPTIONS = [
    {
        key: 'k5',
        value: 5,
        text: '每页5条记录',
    },
    {
        key: 'k10',
        value: 10,
        text: '每页10条记录',
    },
    {
        key: 'k20',
        value: 20,
        text: '每页20条记录',
    },
    {
        key: 'k50',
        value: 50,
        text: '每页50条记录',
    },
    {
        key: 'k100',
        value: 100,
        text: '每页100条记录',
    },
]

export const PAGE_SIZE_10 = 10
export const PAGE_SIZE_MAX = 1000