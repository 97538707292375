import React, { useContext, useCallback, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../stores/root'
import { usePageStuff } from '../../../utils/hooks'
import SearchBar from '../../../components/admin/SearchBar'
import MyTable from '../../../components/MyTable'
import { post } from '../../../axios'
import { PAGE_SIZE_MAX } from '../../../constant/PAGE_SIZE'
import { D } from '../../../constant/interface'
import { IZQHotel } from '../../../constant/New'

const PAGE_SIZE = 10

interface DsMonthlyReportProps { }

const DsMonthlyReport: React.FC<DsMonthlyReportProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)
  const [hotels, setHotels] = useState<IZQHotel[]>([])
  const errorHandler = useCallback(
    (message: string) => {
      rootStore.notificationStore.push({
        id: 'fetch-daily-statistics-fail',
        color: 'red',
        header: '读取日订单统计列表失败',
        content: message,
        expire: 30,
      })
    },
    [rootStore.notificationStore],
  )
  useEffect(() => {
    post<IZQHotel[]>(`zq/Hotel`, {
      groupId: rootStore.authStore.user?.groupId,
      hotelList: rootStore.authStore.user?.hotelList,
      page: 0,
      pageSize: PAGE_SIZE_MAX,
    }).then(res => {
      if (res) {
        setHotels(res)
      }
    })
  }, [])
  const { onPage, page, pageSize, data, total, loading, searchKeys, orderBy } = usePageStuff<D>(
    PAGE_SIZE,
    'merchant/StatisticMonthly/QueryZQOrderStatisticalForBD',
    { groupId: rootStore.authStore.user?.groupId ? rootStore.authStore.user.groupId : "", },
    errorHandler
  )
  return (
    <>
      <SearchBar
        searchArgs={[
          {
            name: 'hotelId',
            label: '选择酒店',
            select: hotels ? hotels?.map(h => ({ name: h.hotelName, value: h.hotelId })) : []
          },
          {
            name: 'StartDate',
            label: '开始月份',
            isDateInput: true,
            isMonthly: true,
          },
          {
            name: 'EndDate',
            label: '结束月份',
            isDateInput: true,
            isMonthly: true,
          },
        ]}
        range={999999}
        onSearch={searchKeys => {
          rootStore.optimizeStore.update(searchKeys.hotelId)
          onPage({ page: 1, pageSize, searchKeys, orderBy: [] })
        }}
      />
      <MyTable
        celled
        paged
        selectable
        size="small"
        data={data}
        page={page}
        count={total}
        pageSize={pageSize}
        loading={loading}
        onPage={(page, pageSize) => onPage({ page, pageSize, searchKeys, orderBy })}
        onSort={orderBy => {
          onPage({ page, pageSize, searchKeys, orderBy })
        }}
        headers={[
          { name: '日期' },
          {
            name: '营业总额',
          },
          { name: '成功率' },
          {
            name: '退款订单数量',
          },
          {
            name: '退款收手续费订单数',
          },
          {
            name: '退款订单手续费',
          },
          {
            name: '已确认订单数',
          },
          {
            name: '间夜',
          },
          {
            name: '订单总数',
          },
          { name: '利润' },
        ]}
        columns={[
          { name: 'date' },
          { name: 'gmv', render: (item: D) => <span>{Math.round(parseFloat(item.gmv))}</span> },
          {
            name: '成功率',
            render: (item: D) => <span> {(item.confirmCount * 100 / item.orderCount).toFixed(2)} %</span>,
          },
          { name: 'refundOrderCount' },
          { name: 'handlingFeeCount' },
          { name: 'orderHandlingFee' },
          { name: 'confirmCount' },
          { name: 'zqNights' },
          { name: 'orderCount' },
          { name: 'profit' },
        ]}
      />
    </>
  )
})

export default DsMonthlyReport
