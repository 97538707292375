import React, { useContext } from 'react'
import { Modal, Header, Button, Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../stores/root'

interface IProps {}

const MyConfirm: React.FC<IProps> = observer(() => {
  const rootStore = useContext(RootStoreContext)

  return (
    <Modal open={!!rootStore.confirmStore.flag} basic size="small">
      {rootStore.confirmStore?.flag?.header && <Header content={rootStore.confirmStore?.flag?.header || null} />}
      {rootStore.confirmStore?.flag?.content && (
        <Modal.Content>
          <h3>{rootStore.confirmStore?.flag?.content || null}</h3>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button
          color="red"
          inverted
          onClick={() => rootStore.confirmStore.cancel()}
          disabled={rootStore.confirmStore?.flag?.loading}
        >
          <Icon name="close" /> {rootStore.confirmStore?.flag?.cancelButton || '取消'}
        </Button>

        <Button
          color="green"
          inverted
          onClick={() => rootStore.confirmStore.confirm()}
          disabled={rootStore.confirmStore?.flag?.loading}
          loading={rootStore.confirmStore?.flag?.loading}
        >
          <Icon name="checkmark" /> {rootStore.confirmStore?.flag?.confirmButton || '确定'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
})

export default MyConfirm
