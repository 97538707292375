import React, { ReactElement } from 'react'
import { Portal, Segment, Header, Button, Icon } from 'semantic-ui-react'

interface IProps {
  title?: string
  content?: string | ReactElement | null
  hasMore?: boolean
  onNegative?: () => void
  onPositive?: () => void
  negativeTitle?: string
  positiveTitle?: string
}

const MessagePortal: React.FC<IProps> = ({
  hasMore = false,
  title,
  content,
  onNegative,
  onPositive,
  negativeTitle,
  positiveTitle,
}) => {
  return (
    <Portal open={true} closeOnDocumentClick={false} closeOnEscape={false}>
      <Segment
        style={{
          position: 'fixed',
          bottom: '3em',
          right: '1em',
          zIndex: 1000,
        }}
        raised
        piled={hasMore}
      >
        {title && <Header size="tiny">{title}</Header>}
        {content}

        <Button
          size="small"
          floated="right"
          compact
          icon
          labelPosition="right"
          positive
          onClick={onPositive}
          disabled={!onPositive}
        >
          {negativeTitle || '处理'}
          <Icon name="arrow right" />
        </Button>
        <Button size="small" floated="right" compact icon labelPosition="left" negative onClick={onNegative}>
          {positiveTitle || '忽略'}
          <Icon name="dont" />
        </Button>
      </Segment>
    </Portal>
  )
}

export default MessagePortal
