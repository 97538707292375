import { observable, action } from 'mobx'
import { RootStore } from './root'
import { persist } from 'mobx-persist'

export class optimizeStore {
  rootStore: RootStore

  @persist('list') @observable optimizeHotel: string[] = []

  @action update(item: string) {
    const index = this.optimizeHotel.indexOf(item)
    if (index > -1) {
      this.optimizeHotel.splice(index, 1)
    }
    this.optimizeHotel.unshift(item)
  }

  @action reset() {
    this.optimizeHotel = []
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }
}
