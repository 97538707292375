
export const windowTypes = [
  { text: '无窗', value: 0 },
  { text: '部分有窗', value: 1 },
  { text: '有窗', value: 2 },
  { text: '内窗', value: 4 },
  { text: '天窗', value: 5 },
  { text: '封闭窗', value: 6 },
  { text: '飘窗', value: 7 },
  { text: '未知', value: 8 },
  { text: '未知', value: null },
]

export const bathroomTypes = [
  { text: '独立卫浴', value: 1 },
  { text: '公共卫浴', value: 2 },
  { text: '未知', value: 3 },
]

export const wirelessTypes = [
  { text: '没有', value: 0 },
  { text: '全部房间有且收费', value: 1 },
  { text: '全部房间有且免费', value: 2 },
  { text: '部分房间有且收费', value: 3 },
  { text: '部分房间有且免费', value: 4 },
]




export const parentBedMap = {
  362: '单人床',
  360: '大床',
  361: '双床',
  363: '多张床 ',
}

export const bedTypeMap = {
  4061: '特大床',
  4060: '大床',
  4059: '小型双人床',
  851: '太空舱',
  1144: '沙发床',
  852: '胶囊床',
  657: '上下铺',
  370: '单人床',
  369: '炕',
  368: '榻榻米',
  366: '圆床',
  365: '双人床',
  367: '水床',
}

export const WINDOW_TYPE_OPTIONS = windowTypes.map((wt, key) => ({ ...wt, key }))

export const BATHROOM_TYPE_OPTIONS = bathroomTypes.map((bt, key) => ({ ...bt, key }))

export const WIRELESS_OPTIONS = wirelessTypes.map(wt => ({ ...wt, key: wt.text }))





