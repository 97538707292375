import React, { useState, useEffect, useCallback } from 'react'
import { Modal, Button, Table } from 'semantic-ui-react'
import ModalTrigger from '../ModalTrigger'
import { post } from '../../axios'
import { colors } from '../../constant/CSS'
import PriceCalendar from './PriceCalendar'
import { IZQHotel, IZQRoom, IZQRP } from '../../constant/New'
interface RPListProps {
  data: IZQRoom
  onClose: () => void
  hotel: IZQHotel
}
const RPList2: React.FC<RPListProps> = ({
  data,
  onClose,
  hotel,
}) => {
  const [rps, setRps] = useState<IZQRP[]>([])
  const [cal, setCal] = useState<IZQRP | null>(null)
  useEffect(() => {
    initRp()
  }, [])
  const initRp = useCallback(
    async () => {
      post<IZQRP[]>(`zq/Rp/${data.realroomId}`, {}).then(res => {
        if (res) {
          setRps(res)
        }
      }).catch(err => window.alert(err))
    },
    [],
  )
  return (
    <>
      <Modal.Header style={{ color: colors.green }}>
        {data.realroomName}
      </Modal.Header>
      <Modal.Content>
        <Table size="small" striped compact selectable collapsing celled sortable style={{ marginRight: '1em' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>早餐</Table.HeaderCell>
              <Table.HeaderCell>创建时间</Table.HeaderCell>
              <Table.HeaderCell>状态</Table.HeaderCell>
              <Table.HeaderCell> #</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {(rps || []).map((rp, idx) => {
              return (
                <Table.Row key={rp.stdRoomId}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>{rp.ratePlanCode}</Table.Cell>
                  <Table.Cell style={{ fontWeight: 'bold', color: colors.black }}>
                    {rp.breakFastNum === 0
                      ? '无早'
                      : rp.breakFastNum === 1
                        ? '单早'
                        : rp.breakFastNum === 2
                          ? '双早'
                          : `${rp.breakFastNum}早`}
                  </Table.Cell>
                  <Table.Cell>{rp.createTime}</Table.Cell>
                  <Table.Cell style={{ color: rp.isValid ? colors.green : colors.red, fontWeight: 'bold' }}>
                    {rp.isValid ? '上线' : '下线'}
                  </Table.Cell>
                  <Table.Cell>
                    <Button size="tiny" compact color="teal" onClick={() => setCal(rp)}>
                      日历
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button size="small" onClick={onClose}>
          取消
        </Button>
      </Modal.Actions>
      {cal && (
        <ModalTrigger size="fullscreen" open={!!cal}>
          <PriceCalendar
            onClose={() => setCal(null)}
            rpCode={cal.ratePlanCode}
            hotel={hotel}
          />
        </ModalTrigger>
      )}
    </>
  )
}

export default RPList2
