import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './layouts/PrivateRoute'
import AdminLayout from './layouts/AdminLayout'
import MerchantLogin from './pages/MerchantLogin'
import Error from './pages/Error'
import WechatConfirmNum from './pages/WechatConfirmNum'
import MerchantUsers from './pages/manage/staff/MerchantUsers'
import MerchantRoles from './pages/manage/staff/MerchantRoles'
import Sku from './pages/manage/merchant/Sku'
import Waiting from './pages/manage/order/Waiting'
import Confirmed from './pages/manage/order/Confirmed'
import Refund from './pages/manage/order/Refund'
import Refunded from './pages/manage/order/Refunded'
import Dailyorderstatistical from './pages/manage/statistic/Dailyorderstatistical'
import Monthlyorderstatistical from './pages/manage/statistic/Monthlyorderstatistical'
import DailyorderstatisticalOperations from './pages/manage/statistic/DailyorderstatisticalOperations'
import MonthlyorderstatisticalOperations from './pages/manage/statistic/MonthlyorderstatisticalOperations'
//酒店
import Hotels from './pages/manage/merchant/Hotels'

const MERCHANT_ROUTES = [

  // 酒店列表
  {
    path: '/manage/merchant/hotels',
    component: Hotels,
  },
  // 库存设置
  {
    path: '/manage/merchant/sku',
    component: Sku,
  },
  // 待处理订单
  {
    path: '/manage/order/wait',
    component: Waiting,
  },
  // 已确认订单
  {
    path: '/manage/order/confirmed',
    component: Confirmed,
  },
  // 待退款订单
  {
    path: '/manage/order/refund',
    component: Refund,
  },
  // 退款订单
  {
    path: '/manage/order/refunded',
    component: Refunded,
  },
  // 用户列表
  {
    path: '/manage/staff/userlist',
    component: MerchantUsers,
  },
  // 角色管理
  {
    path: '/manage/staff/rolesetting',
    component: MerchantRoles,
  },
  //日订单统计商家
  {
    path: '/manage/statistic/dailyorderstatistical',
    component: Dailyorderstatistical,
  },
  //日订单统计商家
  {
    path: '/manage/statistic/monthlyorderstatistical',
    component: Monthlyorderstatistical,
  },
  //日订单统计运营
  {
    path: '/manage/statistic/dailyorderstatisticalOperations',
    component: DailyorderstatisticalOperations,
  },
  //日订单统计运营
  {
    path: '/manage/statistic/monthlyorderstatisticalOperations',
    component: MonthlyorderstatisticalOperations,
  },

]

const generateMenu = () => {
  const menuUrls = JSON.parse(localStorage.getItem('auth') || '{}').menuUrls || []
  return MERCHANT_ROUTES.filter(ar => menuUrls.includes(ar.path))
}

interface IProps {
}

const Routes: React.FC<IProps> = ({ }) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/merchant/login" component={MerchantLogin} />
        <Route exact path="/login" component={MerchantLogin} />
        <Route exact path="/wcn" component={WechatConfirmNum} />
        <Route exact path="/wcn_new" component={WechatConfirmNum} />
        <Route path="/404" exact render={() => <Error status={404} message="您访问的页面不存在!" />} />
        <Route path="/401" exact render={() => <Error status={401} message="未授权访问, 请联系管理员获取权限!" />} />
        <Route path="/500" exact render={() => <Error status={500} message="程序出错了!" />} />
        <AdminLayout>
          {generateMenu().map(ar => (
            <PrivateRoute key={ar.path} exact path={ar.path} component={ar.component} />
          ))}
        </AdminLayout>
      </Switch>
    </Router>
  )
}

export default Routes
