//冒泡排序
export function sort_bubble(data:{list:any,key:string}) {
  let new_list = []
  for(let i=0;i<data.list.length;i++){
      new_list.push(data.list[i])
  }
  for(var i=0;i<new_list.length-1;i++){//确定轮数
      for(var j=0;j<new_list.length-i-1;j++){//确定每次比较的次数
          if(new_list[j][data.key]>new_list[j+1][data.key]){
              let tem:any = new_list[j];
              new_list[j] = new_list[j+1];
              new_list[j+1] = tem;
          }
      }
  }
  return new_list
}

export function getDays(start_day:string, end_day:string) {
  let start_date:any[] = start_day.split("-");
  let end_date:any[] = end_day.split("-");

  let s_date = new Date(start_date[0], start_date[1] - 1, start_date[2]);
  let e_date = new Date(end_date[0], end_date[1] - 1, end_date[2]);
  return parseInt((Math.abs(s_date.getTime() - e_date.getTime()) / 1000 / 60 / 60 / 24)+'')
}
