import React from 'react'
import { Message, Label } from 'semantic-ui-react'
import { colors } from '../../constant/CSS'
import { IUser } from '../../constant/interface'
import { usePostCancel } from '../../utils/hooks'

const ACTIVE_STATUS_COLORS: any = {
  1: 'green',
  2: 'yellow',
  3: 'red',
  0: 'purple',
}

interface IProps {
  roleId: number
}

const RoleDelModal: React.FC<IProps> = ({ roleId }) => {
  const users = usePostCancel<IUser[]>('his/Role/roleForUserList', { roleId })

  if (users.loading) return <span style={{ color: colors.grey }}>加载中...</span>
  if (users.error)
    return (
      <Message negative>
        <Message.Header>获取用户列表失败</Message.Header>
        {users.error && <p>{users.error.message || JSON.stringify(users.error)}</p>}
      </Message>
    )

  return (
    <>
      {(users.data || []).map(user => (
        <Label
          key={user.id}
          style={{ marginBottom: '.5em', marginRight: '.5em' }}
          color={ACTIVE_STATUS_COLORS[user.activeStatus]}
        >
          {user.lastName}
          {user.firstName}
          <Label.Detail>{user.userName}</Label.Detail>
        </Label>
      ))}
    </>
  )
}

export default RoleDelModal
