import React, { useState } from 'react'

interface IProps {
  tabs: string[]
}

const MyTabs: React.FC<IProps> = ({ tabs, children }) => {
  const [tabIdx, setTabIdx] = useState<number>(0)
  return (
    <>
      <div className="ui secondary menu blue">
        {tabs.map((name, idx) => (
          <a key={idx} className={`item ${tabIdx === idx ? 'active' : ''}`} onClick={() => setTabIdx(idx)}>
            {name}
          </a>
        ))}
      </div>
      {React.Children.map(children, (child, idx) => (
        <div key={idx} className={`ui tab ${tabIdx === idx ? 'active' : ''}`}>
          {child}
        </div>
      ))}
    </>
  )
}
export default MyTabs
