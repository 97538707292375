import React, { SyntheticEvent, useState } from 'react'
import { TextArea } from 'semantic-ui-react'
import { colors } from '../constant/CSS'

interface IProps {
  onChange?: (event: SyntheticEvent, data: object) => void
  onInput?: (event: SyntheticEvent, data: object) => void
  row?: number
  value?: string
  defaultValue?: string
  maxLength?: number
  placeholder?: string
}

const MyTextArea: React.FC<IProps> = ({ onChange, onInput, row, value, maxLength, placeholder, defaultValue }) => {
  const [count, setCount] = useState<number>(value ? value.length : 0)
  const [content, setContent] = useState<string>(value || '')
  return (
    <div>
      <TextArea
        defaultValue={defaultValue}
        placeholder={placeholder || ''}
        onChange={(e, d) => {
          const newCount = `${d.value || ''}`.length
          if (maxLength) {
            if (newCount <= maxLength) {
              setCount(newCount)
              setContent(`${d.value || ''}`)
              if (onChange) onChange(e, d)
            }
          } else {
            if (onChange) onChange(e, d)
          }
        }}
        onInput={onInput}
        row={row}
        value={content}
      />
      {maxLength && (
        <span style={{ float: 'right', color: count === maxLength ? colors.red : colors.black }}>
          {count}/{maxLength}
        </span>
      )}
    </div>
  )
}

export default MyTextArea
