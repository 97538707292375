import React from 'react'
import { colors } from '../constant/CSS'

interface IProps { }

const Footer: React.FC<IProps> = () => {
  return <footer style={{ margin: '1em', color: colors.grey }}>©{new Date().getFullYear()} 盛吾游</footer>
}

export default Footer
