import styled from 'styled-components'
import { colors } from '../constant/CSS'

export const A = styled.a<{ color?: string }>`
  cursor: pointer;
  color: ${props => props.color || colors.blue};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
